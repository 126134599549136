import { parseISO, differenceInSeconds } from 'date-fns'
import i18n from '@/lib/i18n/config'

export const formatInstallationDuration = (startedAt: string, finishedAt: string) => {
  const startDate = parseISO(finishedAt)
  const endDate = parseISO(startedAt)
  const diffInSeconds = differenceInSeconds(startDate, endDate)
  const diffMinutes = Math.floor(diffInSeconds / 60)
  const diffSeconds = diffInSeconds % 60

  const formattedMinutes = diffMinutes > 0 ? `${diffMinutes} ${i18n.t('units.time.min')}` : ''
  const formattedSeconds = diffSeconds > 0 ? `${diffSeconds} ${i18n.t('units.time.sec')}` : ''

  return [formattedMinutes, formattedSeconds].filter(Boolean).join(' ')
}

import { Installations } from '@/components/sync/installations'
import { useCloudSyncInstallations } from '@/lib/cloud/use-cloud-sync-installations'
import { useCloudSyncVideos } from '@/lib/cloud/use-cloud-sync-videos'
import { useDataStore } from '@/lib/machine/use-data-store'
import { Button } from '@ui/components/ui/button'

function SyncPage() {
  const sendMachineSyncFilesAction = useDataStore(s => s.sendMachineSyncFilesAction)
  const sendMachineAction = useDataStore(s => s.sendMachineAction)
  const cloudSyncInstallations = useCloudSyncInstallations()
  const cloudSyncVideos = useCloudSyncVideos()

  return (
    <section className="flex justify-center mt-40 gap-4 w-full h-full">
      <div className="flex flex-col gap-4">
        {/* <div>Machine Id: {machineId}</div> */}
        <Button
          onClick={() => {
            // if (!machineId) return
            sendMachineSyncFilesAction({ in: 'file_list' })
          }}
        >
          Sync
        </Button>
        <Button
          onClick={async () => {
            // if (!machineId) return
            await cloudSyncInstallations()
            await cloudSyncVideos()
          }}
        >
          Cloud Sync
        </Button>
        <Button
          onClick={() => {
            sendMachineAction({
              in: 'file_write',
              file: 'hello.txt',
              payload: btoa('Hello!')
            })
          }}
        >
          Write File
        </Button>
        <Installations />
      </div>
    </section>
  )
}

export default SyncPage

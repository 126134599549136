import { useEffect, useRef } from 'react'
import { cn } from '@ui/utils'
import { Button } from '@ui/components/ui/button'
import { useTranslation } from 'react-i18next'

type Props = React.ComponentPropsWithRef<'div'> & {
  reconnect: () => void
  remoteStream: MediaStream | null
}

export const VideoWebRTC = ({ reconnect, remoteStream, className, ...props }: Props) => {
  const videoRef = useRef<HTMLVideoElement>(null)
  const { t } = useTranslation()

  // Prevent video flickering due to the rerendering
  useEffect(() => {
    if (videoRef.current && remoteStream) videoRef.current.srcObject = remoteStream
  }, [remoteStream])

  return (
    <>
      <div className={cn('aspect-[4/3] bg-zinc-900', className)} {...props}>
        {remoteStream?.active ? (
          <video autoPlay playsInline muted ref={videoRef} className="w-full h-full rounded-2xl" />
        ) : (
          <>
            <Button
              variant="ghost"
              onClick={reconnect}
              className="w-full h-full flex flex-col items-center justify-center hover:bg-transparent text-3xl"
            >
              {/* <span> Video is not available</span> */}
              {t('installation.reconnnect-video')}
            </Button>
            {/* {websocketConnectionStatus === 'DISCONNECTED' && ( */}
            {/*   <div className="w-full h-full flex flex-col items-center justify-center"> */}
            {/*     <span> Video is not available</span> */}
            {/*     <button onClick={() => setShouldConnect(true)}>Reconnect Video</button> */}
            {/*   </div> */}
            {/* )} */}
          </>
        )}
      </div>
    </>
  )
}

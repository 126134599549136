import { Button } from '@ui/components/ui/button'
import { cn } from '@ui/utils'

type Props = React.ComponentProps<typeof Button>

export const InstallationButton = ({ className, ...props }: Props) => {
  return (
    <Button
      className={cn(
        'p-6 rounded-xl h-auto w-auto text-muted-foreground bg-zinc-700/20 data-[state=on]:bg-accent-background data-[state=on]:text-accent-foreground hover:bg-zinc-700/30 hover:text-muted-foreground',
        className
      )}
      {...props}
    />
  )
}

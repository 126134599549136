import { PowerButton } from './power-button'
import { useTranslation } from 'react-i18next'
import { ServerCrashIcon } from 'lucide-react'
import { useDataStore } from '@/lib/machine/use-data-store'
import { db } from '@/db/db'
import { useLiveQuery } from 'dexie-react-hooks'

export const SettingsMachine = () => {
  const { t } = useTranslation()
  const sendMachineSyncFilesAction = useDataStore(s => s.sendMachineSyncFilesAction)

  const installationsInDb = useLiveQuery(async () => {
    return await db.installation.toArray()
  })

  const videosInDb = useLiveQuery(async () => {
    return await db.video.toArray()
  })

  const installationsWithoutDuplicates = installationsInDb
    ? Array.from(
        new Map(
          installationsInDb.map(item => [item.machineId + item.installationTimestamp, item])
        ).values()
      )
    : []

  const videosWithoutDuplicates = videosInDb
    ? Array.from(new Map(videosInDb.map(item => [item.machineId + item.timestamp, item])).values())
    : []

  return (
    <section>
      <section className="flex gap-12 mb-12">
        <div className="grid grid-cols-3 gap-4 justify-between">
          <PowerButton
            label={t('settings.machine.delete-machine-data')}
            aria-label={t('settings.machine.delete-machine-data')}
            onClick={() => {
              installationsWithoutDuplicates.forEach(installation => {
                sendMachineSyncFilesAction({
                  in: 'file_remove',
                  file: installation.fileName
                })
              })

              videosWithoutDuplicates.forEach(video => {
                sendMachineSyncFilesAction({
                  in: 'file_remove',
                  file: video.fileName
                })
              })
            }}
            className="w-full"
          >
            <ServerCrashIcon className="w-8" />
          </PowerButton>

          {/* <PowerButton */}
          {/*   label={t('settings.machine.firmware-update-button')} */}
          {/*   aria-label="Firmware Update" */}
          {/*   onClick={async () => { */}
          {/*     const file = await fetchFirmwareUpdate() */}
          {/*     if (!file) return */}
          {/**/}
          {/*     sendMachineAction({ */}
          {/*       in: 'file_write', */}
          {/*       file: 'fwupdate.tar.gz', */}
          {/*       payload: file */}
          {/*     }) */}
          {/*   }} */}
          {/*   className="w-full" */}
          {/* > */}
          {/*   <HardDriveDownloadIcon className="w-8" /> */}
          {/* </PowerButton> */}
        </div>
      </section>
    </section>
  )
}

import { cn } from '@ui/utils'
import { CircleAlertIcon, Loader2Icon } from 'lucide-react'
import { Card, CardHeader, CardTitle, CardContent } from '@ui/components/ui/card'
import { useDataStore } from '@/lib/machine/use-data-store'
import { useTranslation } from 'react-i18next'

const StatusPing = ({ className, ...props }: React.ComponentProps<'span'>) => {
  return (
    <span className={cn('relative flex h-4 w-4 justify-center items-center', className)} {...props}>
      <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-accent-background opacity-75"></span>
      <span className="relative inline-flex rounded-full h-2.5 w-2.5 bg-accent"></span>
    </span>
  )
}

type Props = {
  strict?: boolean
}

export const MachineStatus = ({ strict = false }: Props) => {
  const isConnected = useDataStore(s => s.isConnected)
  const refreshData = useDataStore(s => s.refreshData)
  const machineId = useDataStore(s => s.machineId)
  const { t } = useTranslation()

  const showEstopWarning = isConnected && !refreshData?.ESTOP_OK

  return (
    <>
      <Card
        className={cn(
          'p-2 bg-zinc-900/70 rounded-2xl pointer-events-none select-none',
          strict && !isConnected && 'border-red-900 bg-[#241717b3]',
          strict && showEstopWarning && 'border-yellow-800 bg-[#2a1f04c4]'
        )}
      >
        <CardHeader className="pb-0">
          <CardTitle className="flex items-center gap-4">{machineId ?? 'UV XAIR'}</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="flex items-center gap-2">
            {isConnected ? (
              <>
                {strict && showEstopWarning ? (
                  <>
                    <CircleAlertIcon className={cn('w-5')} />
                    <span>{t('home.connection.emergency-stop')}</span>
                  </>
                ) : (
                  <>
                    <StatusPing />
                    <span>{t('home.connection.connected')}</span>
                  </>
                )}
              </>
            ) : (
              <>
                <Loader2Icon className="mr-1 h-5 w-5 animate-spin" />
                <span>{t('home.connection.connecting')}</span>
              </>
            )}
          </div>
        </CardContent>
      </Card>
    </>
  )
}

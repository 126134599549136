import { toast } from '@ui/components/ui/sonner'
import { useInterval } from 'usehooks-ts'
import { useDataStore } from '../use-data-store'

const CLOUD_SYNC_API_URL = import.meta.env.VITE_CLOUD_SYNC_API_URL
if (!CLOUD_SYNC_API_URL) throw new Error('CLOUD_SYNC_API_URL is not defined')

export const useMachineSyncFilesInterval = (intervalMs = 60_000) => {
  const sendMachineSyncFilesAction = useDataStore(s => s.sendMachineSyncFilesAction)

  useInterval(() => {
    sendMachineSyncFilesAction({ in: 'file_list' })

    // TODO: improve toast styles
    toast.success('Synced Machine Files', {
      position: 'top-center',
      duration: 2000
    })
  }, intervalMs)
}

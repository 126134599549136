import { Card, CardTitle } from '@uv/ui/components/ui/card'
import { cn } from '@uv/ui/utils'

type CardProps = React.ComponentProps<typeof Card>

export const DashboardCard = ({ className, children, ...props }: CardProps) => {
  return (
    <Card className={cn('bg-zinc-900/90 border-none rounded-xl p-8', className)} {...props}>
      {children}
    </Card>
  )
}

type CardTitleProps = React.ComponentProps<typeof CardTitle> & {
  size?: 'small' | 'big'
}

export const DashboardCardTitle = ({
  size = 'big',
  className,
  children,
  ...props
}: CardTitleProps) => {
  return (
    <CardTitle className={cn('mb-8', size === 'small' && 'text-lg', className)} {...props}>
      {children}
    </CardTitle>
  )
}

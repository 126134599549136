import { InstallationInfo, InstallationRecipe, RecipeOption, getDefaultRecipe } from '@uv/machine'

type InstallationInfoForRecipe = Pick<
  InstallationInfo,
  'linerId' | 'chemistryId' | 'centered' | 'opened'
>

export const hasRecipeForInstallation = (
  installationInfo: InstallationInfoForRecipe,
  recipes: RecipeOption[]
): boolean => {
  const { linerId, chemistryId } = installationInfo
  const recipe = recipes.find(r => r.linerId === linerId && r.chemistryId === chemistryId)
  return Boolean(recipe)
}

export const findRecipeForInstallation = (
  installationInfo: InstallationInfoForRecipe,
  recipes: RecipeOption[]
): InstallationRecipe => {
  const { linerId, chemistryId, centered, opened } = installationInfo

  const recipe = recipes.find(r => r.linerId === linerId && r.chemistryId === chemistryId)

  if (!recipe) return getDefaultRecipe()

  const fallbackVariant = recipe.notCenteredOpened

  const recipeVariant =
    centered && opened
      ? (recipe.centeredOpened ?? fallbackVariant)
      : centered && !opened
        ? (recipe.centeredClosed ?? fallbackVariant)
        : !centered && !opened
          ? (recipe.notCenteredClosed ?? fallbackVariant)
          : fallbackVariant

  return {
    id: recipe.id,
    maxSpeed: recipeVariant.maxSpeed,
    speeds: recipeVariant.speeds,
    maxPower: recipeVariant.maxPower,
    powers: recipeVariant.powers,
    delay: recipeVariant.delay
  }
}

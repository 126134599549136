import { fetchWithAuth } from '@/lib/auth/fetch-with-auth'
import { CloudStartInstallationInput } from '@uv/machine'

const CLOUD_SYNC_API_URL = import.meta.env.VITE_CLOUD_SYNC_API_URL
if (!CLOUD_SYNC_API_URL) throw new Error('CLOUD_SYNC_API_URL is not defined')

export const useCloudStartInstallation = () => {
  const cloudStartInstallation = async (installationData: CloudStartInstallationInput) => {
    const { result: response, error } = await fetchWithAuth(
      `${CLOUD_SYNC_API_URL}/installation/start`,
      {
        method: 'POST',
        body: JSON.stringify(installationData satisfies CloudStartInstallationInput),
        timeout: 2000
      }
    )

    if (error || !response || !response.ok) {
      console.error('Cloud start installation error:', error?.message)
      return
    }
  }

  return cloudStartInstallation
}

import { dbDeleteInstallation } from '@/db/actions'
import { getInstallationIdFromFileName } from '@uv/machine'

export const handleFileRemoveInstallation = async (file: string) => {
  if (!file.endsWith('.json') || file === 'active.json') return

  const installationId = getInstallationIdFromFileName(file)
  if (!installationId) return

  await dbDeleteInstallation({ id: installationId })
}

import { SettingsIcon } from 'lucide-react'
import ClockStatus from '@/components/status-bar/clock-status'
import { Drawer, DrawerContent, DrawerTrigger } from '@ui/components/ui/drawer'
import { Settings } from './settings'
import { ExclamationTriangleIcon } from '@heroicons/react/24/solid'
import { useState } from 'react'
import { Warnings } from './warnings'
import { BatteryStatus } from '../../status-bar/battery-status'
import { MachineConnectionStatus } from '../../status-bar/machine-connection-status'
import { useDataStore } from '@/lib/machine/use-data-store'
import { formatAlarmName } from '../utils/format-alarm-name'
import { useTranslation } from 'react-i18next'

export const TopBarInfoIcons = () => {
  const [showSettings, setShowSettings] = useState(false)
  const closeSettings = () => setShowSettings(false)

  return (
    <div className="flex items-center gap-4 text-xl">
      <MachineConnectionStatus />
      <BatteryStatus />
      <Drawer open={showSettings} onOpenChange={setShowSettings}>
        <DrawerTrigger>
          <SettingsIcon className="w-6" />
        </DrawerTrigger>

        <DrawerContent className="p-8">
          <Settings closeSettings={closeSettings} />
        </DrawerContent>
      </Drawer>

      <ClockStatus />
    </div>
  )
}

export const TopBar = () => {
  const { t } = useTranslation()
  const dismissedAlarms = useDataStore(s => s.dismissedAlarms)
  const setDismissedAlarms = useDataStore(s => s.setDismissedAlarms)

  return (
    <div className="col-span-2 flex justify-between items-baseline">
      <div>
        {dismissedAlarms.length > 0 && (
          <div
            className="flex items-center gap-2 text-yellow-500"
            onClick={() => setDismissedAlarms([])}
          >
            <ExclamationTriangleIcon className="w-5" />
            <span>
              {t('installation.top-bar-alarms', {
                alarms: dismissedAlarms.map(formatAlarmName).join(', ')
              })}
            </span>
          </div>
        )}
      </div>

      <Warnings />
      <TopBarInfoIcons />
    </div>
  )
}

import { useEffect, useRef, useState } from 'react'
import * as pdfjsLib from 'pdfjs-dist'

type Props = {
  filePath: string
}

export const PDFViewer = ({ filePath }: Props) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const [pdf, setPdf] = useState<pdfjsLib.PDFDocumentProxy | null>(null)
  const [numPages, setNumPages] = useState<number>(0)

  useEffect(() => {
    const loadPDF = async () => {
      try {
        const loadedPdf = await pdfjsLib.getDocument(filePath).promise
        setPdf(loadedPdf)
        setNumPages(loadedPdf.numPages)
      } catch (error) {
        console.error('Error loading PDF:', error)
      }
    }

    loadPDF()
  }, [])

  useEffect(() => {
    if (pdf && containerRef.current) {
      const renderPages = async () => {
        containerRef.current!.innerHTML = '' // Clear previous renders
        for (let pageNum = 1; pageNum <= numPages; pageNum++) {
          try {
            const page = await pdf.getPage(pageNum)
            const scale = 1
            const viewport = page.getViewport({ scale })

            const pageContainer = document.createElement('div')
            pageContainer.style.marginBottom = '20px' // Add margin between pages
            pageContainer.style.boxShadow = '0 4px 8px rgba(0,0,0,0.1)' // Optional: add shadow for better separation

            const canvas = document.createElement('canvas')
            const context = canvas.getContext('2d')

            if (context) {
              canvas.height = viewport.height
              canvas.width = viewport.width

              const renderContext = {
                canvasContext: context,
                viewport: viewport
              }

              await page.render(renderContext).promise

              pageContainer.appendChild(canvas)
              containerRef.current?.appendChild(pageContainer)
            }
          } catch (error) {
            console.error(`Error rendering page ${pageNum}:`, error)
          }
        }
      }

      renderPages()
    }
  }, [pdf, numPages])

  return (
    <div className="flex flex-col gap-4">
      <div ref={containerRef} className="flex flex-col items-center"></div>
    </div>
  )
}

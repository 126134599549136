import { Label } from '@ui/components/ui/label'
import { Toggle } from '@ui/components/ui/toggle'
import { cn } from '@ui/utils'

type Props = React.ComponentProps<typeof Toggle> & {
  label: string
}

export const CuringToggle = ({ label, className, children, ...props }: Props) => {
  return (
    <Toggle
      className={cn(
        'flex flex-col items-center gap-4 p-6 w-1/3 h-auto aspect-square rounded-2xl text-foreground bg-zinc-700 hover:bg-zinc-700 hover:text-foreground select-none',
        'disabled:opacity-1 disabled:bg-zinc-900/50 disabled:text-foreground/30',
        'data-[state=on]:bg-accent-background data-[state=on]:text-accent-foreground',
        className
      )}
      {...props}
    >
      {children}
      <Label className="text-center text-md">{label}</Label>
    </Toggle>
  )
}

import { cn } from '@ui/utils'
import { useLongPress } from '@uidotdev/usehooks'

type Props = React.ComponentProps<'h2'> & {
  onLongPress: () => void
}

export const HiddenSystemOverrideButton = ({ onLongPress, className, ...props }: Props) => {
  const longPressProps = useLongPress(onLongPress, {
    threshold: 3000
  })

  return <h2 className={cn('select-none', className)} {...props} {...longPressProps} />
}

import { dbGetInstallation } from '@/db/actions'
import { Button } from '@ui/components/ui/button'

type Props = {
  installationId: string
}

export const DownloadInstallationFileButton = ({ installationId }: Props) => {
  const handleDownload = async () => {
    const installation = await dbGetInstallation(installationId)
    if (!installation?.file) return

    const blob = new Blob([installation.file], { type: 'application/octet-stream' })
    const url = URL.createObjectURL(blob)

    const link = document.createElement('a')
    link.href = url
    link.download = `installation_${installationId}.json` // Adjust filename as needed
    link.click()

    // Clean up the URL object
    URL.revokeObjectURL(url)
  }

  return <Button onClick={handleDownload}>Download Installation File</Button>
}

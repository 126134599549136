import { Button } from '@ui/components/ui/button'
import { Label } from '@ui/components/ui/label'
import { cn } from '@ui/utils'

type PropsPowerButton = React.ComponentProps<typeof Button> & {
  label: string
}

export const PowerButton = ({ label, className, children, ...props }: PropsPowerButton) => {
  return (
    <Button
      className={cn(
        'flex flex-col items-center gap-4 p-6 w-1/3 h-auto aspect-square rounded-2xl text-foreground bg-zinc-800/70 hover:bg-zinc-800/70 active:bg-zinc-800/70 select-none',
        'data-[state=on]:bg-accent-background data-[state=on]:text-accent-foreground',
        className
      )}
      {...props}
    >
      {children}
      <Label className="text-center text-md pointer-events-none">{label}</Label>
    </Button>
  )
}

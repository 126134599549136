import { syncFilesWs, syncFilesWsMachineId } from '@/lib/websocket/sync-files/sync-files-websocket'
import { MachineInMessage } from '@uv/machine'

const WEBSOCKET_URL = import.meta.env.VITE_WEBSOCKET_URL
if (!WEBSOCKET_URL) throw new Error('WEBSOCKET_URL is not defined')

type WithoutId<T> = T extends { id: string } ? Omit<T, 'id'> : T
type MachineInMessageExcludingHello = Exclude<MachineInMessage, { in: 'hello' }>
type MachineInMessageWithoutId = WithoutId<MachineInMessageExcludingHello>

export type MachineSyncFilesAction = Extract<
  MachineInMessageWithoutId,
  { in: 'file_list' } | { in: 'file_meta' } | { in: 'file_read' } | { in: 'file_remove' }
>

export const handleSendMachineSyncFilesAction = (params: MachineSyncFilesAction) => {
  const sendMessage = (message: MachineInMessageWithoutId) => {
    if (!syncFilesWs || syncFilesWs.readyState !== WebSocket.OPEN || !syncFilesWsMachineId) {
      console.error('No ws connection while handleSendMachineSyncFilesAction')
      return
    }

    syncFilesWs.send(
      JSON.stringify({
        ...message,
        id: syncFilesWsMachineId
      } satisfies MachineInMessageExcludingHello)
    )
  }

  switch (params.in) {
    case 'file_list': {
      return sendMessage({
        in: 'file_list'
      })
    }

    case 'file_meta': {
      return sendMessage({
        in: 'file_meta',
        file: params.file
      })
    }

    case 'file_read': {
      return sendMessage({
        in: 'file_read',
        file: params.file,
        offset: params.offset,
        count: params.count
      })
    }

    case 'file_remove': {
      return sendMessage({
        in: 'file_remove',
        file: params.file
      })
    }

    default: {
      return
    }
  }
}

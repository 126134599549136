import { useDataStore } from '@/lib/machine/use-data-store'
import { parseMachineOutMessage } from '../parse-machine-out-message'
import { handleIncomingMachineSyncFilesMessage } from './handle-incoming-machine-sync-files-message'

const WEBSOCKET_URL = import.meta.env.VITE_WEBSOCKET_URL
if (!WEBSOCKET_URL) throw new Error('WEBSOCKET_URL is not defined')

const RETRY_DELAY_MS = 5000

/////////////////////////////////////////////
// SYNC FILES WEBSOCKET
// We want a separate connection for syncing so it doesn't interfere with the main connection.
/////////////////////////////////////////////

export let syncFilesWs: WebSocket | null = null
export let syncFilesWsMachineId: string | null = null

const onOpen = () => {
  if (!syncFilesWs) return
  syncFilesWs.send(JSON.stringify({ in: 'hello' }))
  return
}

const onMessage = (e: MessageEvent) => {
  const message = parseMachineOutMessage(e.data)
  if (!message) return
  syncFilesWsMachineId = message.id

  handleIncomingMachineSyncFilesMessage(message)
}

const onClose = () => {
  syncFilesWsMachineId = null
  setTimeout(() => initSyncFilesWebSocket(), RETRY_DELAY_MS)
}

const connect = () => {
  const mockMode = useDataStore.getState().mockMode
  if (mockMode) return null

  const connection = new WebSocket(WEBSOCKET_URL)
  connection.addEventListener('open', onOpen)
  connection.addEventListener('message', onMessage)
  connection.addEventListener('close', onClose)
  return connection
}

export const initSyncFilesWebSocket = () => {
  if (syncFilesWs) syncFilesWs.close()
  syncFilesWs = connect()
}

import { Card, CardContent } from '@ui/components/ui/card'
import { convertDistance, getDistanceSymbol } from '@/lib/units'
import { useDataStoreLocalStorage } from '@/lib/machine/use-data-store-local-storage'
import { useTranslation } from 'react-i18next'
import { formatInstallationDuration } from '../installation/utils/format-installation-duration'
import { timeAgoLocale } from '../installation/utils/timeAgoLocale'

export const LocalInstallations = () => {
  const localInstallationRecords = useDataStoreLocalStorage(s => s.localInstallationRecords)
  const settings = useDataStoreLocalStorage(s => s.settings)
  const locale = useDataStoreLocalStorage(s => s.locale)
  const { t } = useTranslation()

  return (
    <div className="flex flex-col gap-12 p-10">
      <p className="font-semibold tracking-tight text-3xl">
        {t('home.installations.recent-installations')}
      </p>

      <section className="flex flex-col gap-4">
        {localInstallationRecords.length > 0 ? (
          <>
            {[...localInstallationRecords]
              .sort((a, b) => new Date(b.finishedAt).getTime() - new Date(a.finishedAt).getTime())
              .slice(0, 10)
              .map(installation => {
                const { startedAt, finishedAt, machineId, name, projectName, distance } =
                  installation
                const id = machineId + startedAt

                const pastDate = new Date(finishedAt)
                const timeAgo = timeAgoLocale({ pastDate, locale })
                const duration = formatInstallationDuration(startedAt, finishedAt)

                const distanceInPreferredUnit = convertDistance({
                  value: distance,
                  from: 'm',
                  to: settings.unitDistance
                })
                const distanceFormatted = isNaN(distanceInPreferredUnit)
                  ? '-'
                  : distanceInPreferredUnit === 0
                    ? '0'
                    : distanceInPreferredUnit.toFixed(2)

                return (
                  <Card key={id} className="bg-zinc-800/40">
                    <CardContent className="flex justify-between items-center gap-8">
                      <div className="flex flex-col w-1/3 mr-auto">
                        <span className="text-sm text-muted-foreground">{projectName}</span>
                        <span className="text-lg">{name}</span>
                      </div>
                      <div className="flex flex-col">
                        <span className="text-sm text-muted-foreground">
                          {t('home.installations.installation.distance')}
                        </span>
                        <span className="text-lg">
                          {distanceFormatted}
                          {getDistanceSymbol(settings.unitDistance)}
                        </span>
                      </div>

                      <div className="flex flex-col">
                        <span className="text-sm text-muted-foreground">
                          {t('home.installations.installation.duration')}
                        </span>
                        <span className="text-lg">{duration}</span>
                      </div>

                      <div className="flex flex-col w-1/4">
                        <span className="text-sm text-muted-foreground">
                          {t('home.installations.installation.date')}
                        </span>
                        <span className="text-lg">{timeAgo}</span>
                      </div>
                    </CardContent>
                  </Card>
                )
              })}
          </>
        ) : (
          <span>{t('home.installations.no-installations')}</span>
        )}
      </section>
    </div>
  )
}

import { useDataStoreLocalStorage } from '@/lib/machine/use-data-store-local-storage'
import { Dialog, DialogContent, DialogDescription, DialogTitle } from '@ui/components/ui/dialog'
import { useSearchParams } from 'react-router-dom'
import { Login } from '../auth/login'
import { isOnline } from '@/lib/utils/is-online'
import { useState, useEffect } from 'react'

type Props = {
  open: boolean
  onOpenChange: (isOpen: boolean) => void
}
const LoginDialogContent = ({ open, onOpenChange }: Props) => {
  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent
        className="max-h-1/2 p-12 border-none"
        onInteractOutside={e => {
          e.preventDefault()
        }}
      >
        <DialogTitle className="hidden">Login</DialogTitle>
        <DialogDescription className="hidden">Login</DialogDescription>
        <Login />
      </DialogContent>
    </Dialog>
  )
}

export const LoginDialog = () => {
  const { isAuthenticated } = useDataStoreLocalStorage.getState().user
  const [searchParams, setSearchParams] = useSearchParams()
  const isLogin = searchParams.has('login')

  const setLogin = () => {
    const isLogin = searchParams.has('login')
    if (isLogin) {
      searchParams.delete('login')
      setSearchParams(searchParams)
    } else {
      setSearchParams({ login: 'true' })
    }
  }

  if (isAuthenticated) return null

  return <LoginDialogContent open={isLogin} onOpenChange={() => setLogin()} />
}

export const LoginDialogForced = () => {
  const user = useDataStoreLocalStorage(s => s.user)
  const [showLogin, setShowLogin] = useState(false)
  const [dismissed, setDismissed] = useState(false)

  useEffect(() => {
    const checkShouldShowAuth = async () => {
      if (user.isAuthenticated || dismissed) return setShowLogin(false)
      if (await isOnline()) return setShowLogin(true)
    }

    checkShouldShowAuth()
  }, [user.isAuthenticated, dismissed])

  if (!showLogin) return null

  return (
    <LoginDialogContent
      open={showLogin}
      onOpenChange={v => {
        if (!v) setDismissed(true)
      }}
    />
  )
}

import { dbGetVideo } from '@/db/actions'
import { Button } from '@ui/components/ui/button'

type Props = {
  fileName: string
}

export const DownloadVideoFileButton = ({ fileName }: Props) => {
  const handleDownload = async () => {
    const installation = await dbGetVideo(fileName)
    if (!installation?.file) return

    const blob = new Blob([installation.file], { type: 'application/octet-stream' })
    const url = URL.createObjectURL(blob)

    const link = document.createElement('a')
    link.href = url
    link.download = fileName 
    link.click()

    // Clean up the URL object
    URL.revokeObjectURL(url)
  }

  return <Button onClick={handleDownload}>Download Video File</Button>
}

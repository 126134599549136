import { useInterval } from 'usehooks-ts'
import { useCloudSyncVideos } from './use-cloud-sync-videos'

export const useCloudSyncVideosInterval = (intervalMs = 150_000) => {
  const cloudSyncVideos = useCloudSyncVideos()

  useInterval(() => {
    cloudSyncVideos()
  }, intervalMs)
}

import { MachineStatus } from '@/components/dashboard/machine-status'
import { LocalInstallations } from '@/components/installations/local-installations'
import { SyncStatus } from '@/components/dashboard/sync-status'
import { LoginDialog, LoginDialogForced } from '@/components/dashboard/login-dialog'
import { ScrollArea } from '@ui/components/ui/scroll-area'

function DashboardPage() {
  return (
    <>
      <LoginDialog />
      <LoginDialogForced />

      <div className="flex flex-1 gap-8 overflow-y-hidden ">
        <ScrollArea className="h-full w-2/3 border text-card-foreground shadow-sm bg-zinc-900/90 rounded-2xl select-none">
          <LocalInstallations />
        </ScrollArea>

        <section className="w-1/3 flex flex-col gap-6">
          <MachineStatus strict />
          <SyncStatus />
        </section>
      </div>
    </>
  )
}

export default DashboardPage

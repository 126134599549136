import { CloudSyncUploadedInstallationFileInput } from '@uv/machine'
import { fetchWithAuth } from '../auth/fetch-with-auth'

const CLOUD_SYNC_API_URL = import.meta.env.VITE_CLOUD_SYNC_API_URL
if (!CLOUD_SYNC_API_URL) throw new Error('CLOUD_SYNC_API_URL is not defined')

export const cloudSyncUploadedInstallationFile = async (
  input: CloudSyncUploadedInstallationFileInput
) => {
  const { result: response, error } = await fetchWithAuth(
    `${CLOUD_SYNC_API_URL}/installation/uploaded`,
    {
      method: 'POST',
      body: JSON.stringify(input),
      timeout: 5000
    }
  )

  if (error || !response || !response.ok) {
    console.error('Cloud sync uploaded installation error:', error?.message)
    return false
  }

  return true
}

import { LoaderIcon, RefreshCwIcon, PowerIcon } from 'lucide-react'
import { PowerButton } from './power-button'
import { useTranslation } from 'react-i18next'
import { useDataStore } from '@/lib/machine/use-data-store'

export const SettingsPower = () => {
  const { t } = useTranslation()
  const sendMachineAction = useDataStore(s => s.sendMachineAction)

  return (
    <section>
      <section className="flex gap-12 mb-12">
        <div className="grid grid-cols-3 gap-4 justify-between">
          <PowerButton
            label={t('settings.power.refresh-button')}
            aria-label="Refresh App"
            onClick={() => {
              window.location.reload()
            }}
            className="w-full"
          >
            <LoaderIcon className="w-8" />
          </PowerButton>

          <PowerButton
            label={t('settings.power.restart-button')}
            aria-label="Restart Tablet"
            onClick={() => sendMachineAction({ in: 'tablet_control', command: 'REBOOT' })}
            className="w-full"
          >
            <RefreshCwIcon className="w-8" />
          </PowerButton>

          <PowerButton
            label={t('settings.power.power-off-button')}
            aria-label="Power Off Tablet"
            onClick={() => sendMachineAction({ in: 'tablet_control', command: 'POWEROFF' })}
            className="w-full"
          >
            <PowerIcon className="w-8" />
          </PowerButton>
        </div>
      </section>
    </section>
  )
}

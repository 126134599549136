import { useDataStoreLocalStorage } from '@/lib/machine/use-data-store-local-storage'
import { convertSpeed, getSpeedSymbol } from '@/lib/units'
import { formatFloat } from '@/lib/utils'
import { Card, CardHeader, CardTitle, CardContent } from '@ui/components/ui/card'
import { cn } from '@ui/utils'
import { getDefaultRecipe } from '@uv/machine'
import { t } from 'i18next'

type Props = {
  isOther: boolean
} & React.ComponentProps<typeof Card>

const { maxSpeed } = getDefaultRecipe()

export const RecipeMissingCard = ({ isOther, className, ...props }: Props) => {
  const settings = useDataStoreLocalStorage(s => s.settings)

  const speedValue = convertSpeed({
    value: maxSpeed,
    from: 'm/h',
    to: settings.unitSpeed
  })
  const speedValueFormatted = `${formatFloat(speedValue)} ${getSpeedSymbol(settings.unitSpeed)}`

  return (
    <Card
      className={cn('bg-zinc-900/70 rounded-2xl pointer-events-none select-none', className)}
      {...props}
    >
      <CardHeader className="pb-4 sr-only">
        <CardTitle className="flex text-lg items-center">
          {isOther
            ? t('installation.setup.missing-recipe-card.title-other')
            : t('installation.setup.missing-recipe-card.title')}
        </CardTitle>
      </CardHeader>
      <CardContent>
        <div className="flex items-center">
          <p>
            {isOther
              ? t('installation.setup.missing-recipe-card.title-other')
              : t('installation.setup.missing-recipe-card.title')}
            .{' '}
            {t('installation.setup.missing-recipe-card.message', {
              speed: speedValueFormatted,
              interpolation: { escapeValue: false } // Otherwise m/h is escaped
            })}
          </p>
        </div>
      </CardContent>
    </Card>
  )
}

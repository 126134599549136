import { calculateWatchdogNextValue } from '@/lib/installation/calculate-watchdog-next-value'
import { calculateRefreshMetrics } from '@/lib/installation/refresh-metrics'
import { useDataStore } from '@/lib/machine/use-data-store'
import { useDataStoreLocalStorage } from '@/lib/machine/use-data-store-local-storage'
import {
  MachineLocalStoreSchema,
  MachineOutMessage,
  isInstallationFileMeta,
  powerRecipeParametersToUvCycle,
  speedRecipeParametersToRpmList
} from '@uv/machine'

const CONNECTION_TIMEOUT_MS = 2500
const REFRESH_DATA_DELAY_MS = 20

/////////////////////////////////
// Connection Timeout
/////////////////////////////////
// There is no way to know if the connection is still alive.
// So we use a timeout to close the connection if no refresh data message is received in a certain time.

let closeConnectionTimeoutId: NodeJS.Timeout | null = null
const clearCloseConnectionTimeout = () => {
  if (closeConnectionTimeoutId) clearTimeout(closeConnectionTimeoutId)
}
const closeConnectionTimeout = (ws: WebSocket | null) => {
  if (ws?.readyState !== WebSocket.OPEN) return

  clearCloseConnectionTimeout()

  closeConnectionTimeoutId = setTimeout(() => {
    console.error(
      `No refresh data message received in the last ${CONNECTION_TIMEOUT_MS}. Closing connection.`
    )
    useDataStore.setState({ isConnected: false })
    useDataStore.getState().disconnect()
  }, CONNECTION_TIMEOUT_MS)
}

const setActiveInstallationStage = useDataStoreLocalStorage.getState().setActiveInstallationStage
const setActiveInstallationFileMeta =
  useDataStoreLocalStorage.getState().setActiveInstallationFileMeta
const addActiveInstallationSample = useDataStoreLocalStorage.getState().addActiveInstallationSample
const updateMachineLastSeen = useDataStoreLocalStorage.getState().updateMachineLastSeen

export const handleIncomingMessage = (ws: WebSocket | null, message: MachineOutMessage) => {
  const get = useDataStore.getState
  const set = useDataStore.setState

  const watchdog = get().refreshData?.WATCHDOG ?? 0

  const refreshDataImmediately = (id: string | null) => {
    if (!id) {
      console.error('Machine ID is null when trying to refresh data')
      return
    }

    if (!ws || ws.readyState !== WebSocket.OPEN) return

    ws.send(
      JSON.stringify({
        id,
        in: 'refresh',
        watchdog: calculateWatchdogNextValue(watchdog)
      })
    )
  }

  switch (message.out) {
    case 'id': {
      refreshDataImmediately(message.id)
      set(() => ({ machineId: message.id }))

      get().sendMachineAction({
        in: 'datetime_set',
        ts: new Date().toISOString()
      })

      get().sendMachineAction({
        in: 'configuration_get'
      })

      get().sendMachineAction({
        in: 'file_meta',
        file: 'active.json'
      })

      get().sendMachineAction({
        in: 'localstore_get'
      })

      return
    }

    case 'refresh': {
      closeConnectionTimeout(ws)
      const machineId = message.id

      if (REFRESH_DATA_DELAY_MS > 0) {
        setTimeout(() => {
          refreshDataImmediately(machineId)
        }, REFRESH_DATA_DELAY_MS)
      } else {
        refreshDataImmediately(machineId)
      }

      set({ refreshData: message })

      set(({ refreshMetrics }) => ({
        refreshMetrics: calculateRefreshMetrics(refreshMetrics)
      }))

      // Update Active Installation Samples
      if (message.RECORDING === true) {
        addActiveInstallationSample({
          timestamp: Date.now(),
          speed: message.RPM ?? 0,
          pressure: message.AIR_PRESSURE ?? 0,
          temperature: message.AIR_TEMP ?? 0
        })
      }

      updateMachineLastSeen(machineId)

      return
    }

    case 'file_meta': {
      // Sync active installation
      if (message.file === 'active.json' && isInstallationFileMeta(message.payload)) {
        setActiveInstallationFileMeta(message.payload)

        // This is in case the machine recovers.
        // We need to manually set the recipe and UV cycle because the machine doesn't remember.
        const recipe = message.payload.installationInfo.recipe
        const rpmList = speedRecipeParametersToRpmList(recipe)
        const uvCycle = powerRecipeParametersToUvCycle(recipe)

        get().sendMachineAction({
          in: 'rpm_configure',
          list: rpmList
        })

        get().sendMachineAction({
          in: 'uv_configure',
          cycle: uvCycle
        })
      }

      return
    }

    case 'localstore_get': {
      const parsedLocalstore = MachineLocalStoreSchema.safeParse(message.meta)
      if (!parsedLocalstore.success) {
        console.error(`Error parsing localstore: ${parsedLocalstore.error.message}`)
        console.dir(parsedLocalstore.error, { depth: null })
        console.dir(message.meta, { depth: null })
        return
      }

      if (parsedLocalstore.data.installationStage) {
        setActiveInstallationStage(parsedLocalstore.data.installationStage)
        return
      }

      return
    }

    // Machine Configuration
    case 'configuration_get': {
      get().setMachineConfiguration(message.payload)
      return
    }

    case 'overrides_configure': {
      set(s => {
        return {
          machineConfiguration: {
            ...s.machineConfiguration,
            overrides: message.overrides
          }
        }
      })

      return
    }

    default: {
      return
    }
  }
}

import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogFooter,
  AlertDialogCancel,
  AlertDialogDescription
} from '@ui/components/ui/alert-dialog'
import { InstallationQrCodeSchema, InstallationQrCode } from '@uv/machine'
import { IDetectedBarcode, Scanner } from '@yudiel/react-qr-scanner'
import { mightFail } from 'might-fail'
import { useTranslation } from 'react-i18next'

type Props = {
  onScan: (result: InstallationQrCode) => void
  closeDialog: () => void
}

export const QrScanner = ({ onScan, closeDialog }: Props) => {
  const { t } = useTranslation()
  const processScan = async (codes: IDetectedBarcode[]) => {
    const code = codes.at(0)
    if (!code) return
    const { error: convertToJSONError, result: data } = await mightFail(JSON.parse(code.rawValue))

    if (convertToJSONError) {
      console.log('Invalid QR code')
      return
    }

    const parsedData = InstallationQrCodeSchema.safeParse(data)
    if (!parsedData.success) {
      console.dir(data, { depth: null })
      console.dir(parsedData.error, { depth: null })
      console.log('Invalid QR code')
      return
    }

    console.log('Valid QR code', parsedData.data)
    onScan(parsedData.data)
  }

  return (
    <AlertDialog
      open={true}
      onOpenChange={v => {
        if (!v) closeDialog()
      }}
    >
      <AlertDialogContent className="max-h-1/2 p-12 ">
        <AlertDialogHeader>
          <AlertDialogTitle className="text-3xl mb-4">
            {t('installation.pre-setup.qr-dialog.title')}
          </AlertDialogTitle>
          <AlertDialogDescription className="text-lg leading-tight sr-only">
            {t('installation.pre-setup.qr-dialog.title')}
          </AlertDialogDescription>
        </AlertDialogHeader>

        <div className="h-[414px] text-foreground [&_path]:stroke-accent [&_path]:stroke-[5px] [&_text]:fill-foreground">
          <Scanner
            styles={{
              finderBorder: 0
            }}
            // components={{
            //   count: false,
            //   audio: false
            // }}
            onScan={processScan}
            // onError={error => console.log(error?.message)}
          />
        </div>

        <AlertDialogFooter className="self-end gap-4 mt-6">
          <AlertDialogCancel className="w-full p-8 text-md font-bold rounded-xl">
            {t('installation.pre-setup.qr-dialog.cancel-button')}
          </AlertDialogCancel>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}

import { useDataStore } from '@/lib/machine/use-data-store'
import { InstallationRecipe } from '@uv/machine'

type Props = {
  recipe: InstallationRecipe
}

export const InstallationDevFields = ({ recipe }: Props) => {
  const devMode = useDataStore(s => s.devMode)
  if (!devMode) return null

  return (
    <>
      <div className="fixed bottom-12 right-4 text-sm text-right ">
        <p>Max Speed: {recipe.maxSpeed} m/h</p>
        {recipe.speeds.map((speed, i) => {
          return <Speed key={`speed-${i}`} speed={speed} />
        })}
        <p>Delay: {recipe.delay}s</p>

        <br />

        <p>Max Power: {recipe.maxPower}</p>
        {recipe.powers.map((power, i) => {
          return <Power key={`power-${i}`} power={power} />
        })}
      </div>
    </>
  )
}

const Speed = ({ speed }: { speed: InstallationRecipe['speeds'][0] }) => {
  return (
    <p>
      Acc: {speed.distance}m {speed.factor}%
    </p>
  )
}

const Power = ({ power }: { power: InstallationRecipe['powers'][0] }) => {
  return (
    <p>
      Cycle: {power.duration}s {power.factor}%
    </p>
  )
}

import { dbGetVideo, dbMarkVideoForDeletion } from '@/db/actions'
import { cloudSyncUploadedVideoFile } from '@/lib/cloud/cloud-sync-uploaded-video-file'
import { s3UploadVideoFile } from '@/lib/s3/upload-video-file'
import { CloudSyncFilesOutput } from '@uv/machine'
import { useDataStore } from '../../use-data-store'

export const handleUploadAndDeleteVideoFile = async ({
  fileToUpload
}: {
  fileToUpload: Extract<CloudSyncFilesOutput[0], { action: 'UPLOAD' }>
}) => {
  const sendMachineSyncFilesAction = useDataStore.getState().sendMachineSyncFilesAction

  const fileName = fileToUpload.fileName

  const video = await dbGetVideo(fileName)
  if (!video) return

  if (!video.file) {
    console.error(`Video file ${fileName} not found in IndexedDB`)
    return
  }

  const url = await s3UploadVideoFile({
    presignedUrl: fileToUpload.presignedUrl,
    file: video.file
  })
  if (!url) return

  await dbMarkVideoForDeletion(fileName)

  const hasUpdatedCloud = await cloudSyncUploadedVideoFile({
    fileName: fileName,
    fileUrl: url
  })

  if (hasUpdatedCloud) {
    return sendMachineSyncFilesAction({
      in: 'file_remove',
      file: video.fileName
    })
  }
}

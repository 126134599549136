import { ButtonPressAndHold } from '@/components/ui/button-press-and-hold'
import { DashboardCard } from '@/components/ui/card-dashboard'
import { useDataStore } from '@/lib/machine/use-data-store'
import { clampFactor } from '@/lib/utils'
import { MinusIcon, PlusIcon } from 'lucide-react'
import { useTranslation } from 'react-i18next'

export const LampControls = () => {
  const { t } = useTranslation()
  const refreshData = useDataStore(s => s.refreshData)
  const sendMachineAction = useDataStore(s => s.sendMachineAction)

  const LAMP = refreshData?.LAMP ?? 100

  return (
    <DashboardCard className="w-full flex gap-6 justify-between bg-zinc-900/70 select-none">
      <ButtonPressAndHold
        className="w-16 h-16 p-6 bg-zinc-700 hover:bg-zinc-800 text-foreground rounded-2xl"
        holdActivationDelay={0}
        holdActionRepeatInterval={200}
        onAction={() => {
          if (LAMP === 0) return
          sendMachineAction({
            in: 'lamp_set',
            factor: clampFactor(LAMP - 10)
          })
        }}
      >
        <MinusIcon className="w-4" />
      </ButtonPressAndHold>

      <div className="flex flex-col text-center">
        <span className="text-xl font-bold">{t('installation.camera-light')}</span>
        <span className="text-accent text-2xl font-bold">{LAMP}%</span>
      </div>

      <ButtonPressAndHold
        className="w-16 h-16 p-6 bg-zinc-700 hover:bg-zinc-800 text-foreground rounded-2xl"
        holdActivationDelay={0}
        holdActionRepeatInterval={200}
        onAction={() => {
          if (LAMP === 100) return
          sendMachineAction({
            in: 'lamp_set',
            factor: clampFactor(LAMP + 10)
          })
        }}
      >
        <PlusIcon className="w-4" />
      </ButtonPressAndHold>
    </DashboardCard>
  )
}

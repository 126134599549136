import { fetchWithAuth } from '@/lib/auth/fetch-with-auth'
import { CloudFinishInstallationInput } from '@uv/machine'

const CLOUD_SYNC_API_URL = import.meta.env.VITE_CLOUD_SYNC_API_URL
if (!CLOUD_SYNC_API_URL) throw new Error('CLOUD_SYNC_API_URL is not defined')

export const useCloudFinishInstallation = () => {
  const cloudFinishInstallation = async (installationData: CloudFinishInstallationInput) => {
    const { result: response, error } = await fetchWithAuth(
      `${CLOUD_SYNC_API_URL}/installation/finish`,
      {
        method: 'POST',
        body: JSON.stringify(installationData satisfies CloudFinishInstallationInput),
        timeout: 2000
      }
    )

    if (error || !response || !response.ok) {
      console.error('Cloud finish installation error:', error?.message)
      return
    }
  }

  return cloudFinishInstallation
}

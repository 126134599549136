import { createId } from '@paralleldrive/cuid2'
import { format, parseISO } from 'date-fns'
import { UTCDate } from '@date-fns/utc'
import { Installation, MachineSystemData, MachineSystemDataSchema, RecipeParameters } from './types'
import { getDefaultRPMList, getDefaultUVCycle } from './defaults'

export const generateId = (
  type:
    | 'chemistry'
    | 'client'
    | 'organization'
    | 'user'
    | 'installation'
    | 'installer'
    | 'video'
    | 'liner'
    | 'project'
    | 'recipe'
) => {
  const prefixes: Record<typeof type, string> = {
    chemistry: 'chem',
    client: 'clnt',
    organization: 'org',
    user: 'usr',
    installation: 'inst',
    installer: 'insr',
    video: 'vid',
    liner: 'linr',
    project: 'proj',
    recipe: 'recp'
  }
  const prefix = prefixes[type]
  return `${prefix}_${createId()}`
}

export const getInstallationIdFromFileName = (fileName: Installation['fileName']) => {
  const fileNameWithoutExtension = fileName.split('.').at(0)
  if (!fileNameWithoutExtension) {
    console.error(
      'Invalid fileName. Cannot extract file name without extension. FileName: ',
      fileName
    )
    return null
  }

  const id = fileNameWithoutExtension.split(' ').at(1)
  if (!id) {
    console.error('Invalid fileName. Cannot extract id. Filetame: ', fileName)
    return null
  }

  return id
}

export const getInstallationTimestampFromFileName = (fileName: Installation['fileName']) => {
  const timestamp = fileName.split(' ').at(0)
  if (!timestamp) {
    console.error('Invalid filename. Cannot extract timestamp. Filename: ', fileName)
    return null
  }
  return timestamp
}

export const machineTimestampToDate = (machineTimestamp: string) => {
  return new UTCDate(parseISO(machineTimestamp))
}

export const dateStringToMachineTimestamp = (dateString: string) => {
  return format(new UTCDate(parseISO(dateString)), "yyyyMMdd'T'HHmmss'Z'")
}

export const dateToMachineTimestamp = (date: Date) => {
  return format(new UTCDate(date), "yyyyMMdd'T'HHmmss'Z'")
}

export const rpmListToRecipeParameters = (
  rpmList: MachineSystemData['RPM_LIST']
): Pick<RecipeParameters, 'maxSpeed' | 'speeds'> => {
  const parsed = MachineSystemDataSchema.shape.RPM_LIST.safeParse(rpmList)

  if (!parsed.success) {
    console.error('Invalid RPM list:', rpmList)
    return rpmListToRecipeParameters(getDefaultRPMList())
  }

  const maxSpeed = parsed.data[0]
  const speeds = parsed.data[1]

  return {
    maxSpeed,
    speeds: speeds.map(([distance, factor]) => ({ distance, factor }))
  }
}

export const uvCycleToRecipeParameters = (
  uvCycle: MachineSystemData['UV_CYCLE']
): Pick<RecipeParameters, 'maxPower' | 'powers'> => {
  const parsed = MachineSystemDataSchema.shape.UV_CYCLE.safeParse(uvCycle)

  if (!parsed.success) {
    console.error('Invalid UV Cycle:', uvCycle)
    return uvCycleToRecipeParameters(getDefaultUVCycle())
  }

  const maxPower = parsed.data[0]
  const powers = parsed.data[1]

  return {
    maxPower,
    powers: powers.map(([duration, factor]) => ({ duration, factor }))
  }
}

export const speedRecipeParametersToRpmList = (
  speedParameters: Pick<RecipeParameters, 'maxSpeed' | 'speeds'>
): MachineSystemData['RPM_LIST'] => {
  return [
    speedParameters.maxSpeed,
    speedParameters.speeds.map(({ distance, factor }) => [distance, factor])
  ]
}

export const powerRecipeParametersToUvCycle = (
  powerParameters: Pick<RecipeParameters, 'maxPower' | 'powers'>
): MachineSystemData['UV_CYCLE'] => {
  return [
    powerParameters.maxPower,
    powerParameters.powers.map(({ duration, factor }) => [duration, factor])
  ]
}

import { useInterval } from 'usehooks-ts'
import { useCloudSyncInstallations } from './use-cloud-sync-installations'

export const useCloudSyncInstallationsInterval = (intervalMs = 150_000) => {
  const syncInstallations = useCloudSyncInstallations()

  useInterval(() => {
    syncInstallations()
  }, intervalMs)
}

import { ComponentPropsWithoutRef } from 'react'

type Props = ComponentPropsWithoutRef<'svg'>

export const Logo = ({ children, ...props }: Props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 232.36 51.99" {...props}>
      <defs>
        <clipPath>
          <path
            d="M0 0h232.36v51.99H0z"
            style={{
              strokeWidth: 0,
              fill: 'none'
            }}
          />
        </clipPath>
        <style>{'.cls-3,.cls-4{stroke-width:0}.cls-3{fill:#6f2277}.cls-4{fill:#231f20}'}</style>
      </defs>
      <g
        style={{
          clipPath: 'url(#clippath)'
        }}
      >
        <path
          d="M45.22 33.26c0 2.82-1.69 4.51-4.06 4.51s-4.3-1.72-4.3-4.51V18.92c0-6.41-4.51-10.5-11.43-10.5s-10.57 4.1-10.57 10.5v22.59c0 2.82-1.69 4.51-4.06 4.51s-4.3-1.72-4.3-4.51V0H0v41.49c0 6.41 3.82 10.5 10.74 10.5s10.57-4.1 10.57-10.5V18.9c0-2.82 1.69-4.51 4.06-4.51s4.3 1.72 4.3 4.51v14.35c0 6.4 4.51 10.5 11.43 10.5s11.26-4.1 11.26-10.5V19.31H45.2v13.94ZM67.08 36.01l-5.51-16.7h-7.65l9.23 24.14h7.44l9.33-24.14h-7.33l-5.51 16.7z"
          className="cls-3"
        />
        <path
          d="m96.49 43.44-3.81-6.87h-5.53v6.87h-5.42V19.41h10.44c6.28 0 9.85 3.02 9.85 8.34 0 3.71-1.58 6.39-4.5 7.76l5.12 7.93h-6.15Zm-9.34-11.4h5.01c2.99 0 4.7-1.37 4.7-4.12s-1.72-4.02-4.7-4.02h-5.01v8.14ZM123.68 19.41v4.5h-12.87v5.22h11.57v4.5h-11.57v5.32h13.25v4.5h-18.68V19.41h18.3zM132.54 19.41v19.3h10.3v4.74h-15.73V19.41h5.43zM145.41 19.41h4.6v24.03h-4.6zM157.58 19.41l12.39 16.45V19.41h4.46v24.04h-4.36l-12.35-16.41v16.41h-4.5V19.41h4.36zM178.54 19.41h4.6v24.03h-4.6zM190.83 19.41l12.88 17.27V19.41h3.98v24.04h-3.98L190.9 26.21v17.24h-4.05V19.41h3.98zM225.94 33.8h2.74v4.53c-1.72 1.03-3.74 1.65-5.53 1.65-4.91 0-8.79-3.74-8.79-8.55s3.85-8.51 8.76-8.51c2.51 0 5.08 1.03 6.93 2.75l2.3-2.99c-2.37-2.06-5.8-3.36-9.23-3.36-7.28 0-12.87 5.25-12.87 12.12s5.53 12.19 12.63 12.19c3.29 0 6.9-1.27 9.34-3.12V30.28h-6.28v3.53Z"
          className="cls-4"
        />
      </g>
    </svg>
  )
}

export const LogoSimple = ({ children, ...props }: Props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 105 104" fill="none" {...props}>
      <path
        d="M90.6384 66.519C90.6384 72.1657 87.2584 75.5382 82.4953 75.5382C77.7321 75.5382 73.8651 72.0937 73.8651 66.519V38.6272V37.8231C73.8651 25.0114 64.8199 16.8143 50.9454 16.8143C37.0709 16.8143 29.7517 25.0114 29.7517 37.8231V55.0994V82.9912C29.7517 88.6379 26.3718 92.0164 21.6086 92.0164C16.8455 92.0164 12.9784 88.5719 12.9784 82.9912V0H0V82.9912C0 95.8029 7.66195 104 21.5365 104C35.411 104 42.7301 95.8029 42.7301 82.9912V65.7149V37.8231C42.7301 32.1763 46.11 28.7979 50.8732 28.7979C55.6363 28.7979 59.5034 32.2423 59.5034 37.8231V65.7149V66.519C59.5034 79.3307 68.5486 87.5278 82.4231 87.5278C96.2976 87.5278 104.994 79.3307 104.994 66.519V38.6272H90.6384V66.519Z"
        fill="#7C2792"
      />
    </svg>
  )
}

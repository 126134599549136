import { useDataStore } from '@/lib/machine/use-data-store'
import { useDataStoreLocalStorage } from '@/lib/machine/use-data-store-local-storage'
import { Button } from '@ui/components/ui/button'

const WSManager = () => {
  const connect = useDataStore(s => s.connect)
  const disconnect = useDataStore(s => s.disconnect)
  const isConnected = useDataStore(s => s.isConnected)
  const refreshMetrics = useDataStore(s => s.refreshMetrics)
  const shouldReconnect = useDataStore(s => s.shouldReconnect)
  const setShouldReconnect = useDataStore(s => s.setShouldReconnect)
  const sendMachineAction = useDataStore(s => s.sendMachineAction)
  const refreshData = useDataStore(s => s.refreshData)
  const mockMode = useDataStore(s => s.mockMode)
  const setMockMode = useDataStore(s => s.setMockMode)

  const clearActiveInstallationMetrics = useDataStoreLocalStorage(
    state => state.clearActiveInstallationSamples
  )

  return (
    <div className="flex flex-col gap-4">
      <Button onClick={() => connect()}>Connect</Button>
      <Button onClick={() => disconnect()}>Disconnect</Button>
      <Button onClick={() => setShouldReconnect(!shouldReconnect)}>Toggle Reconnect</Button>
      <Button onClick={() => setMockMode(!mockMode)}>Toggle Mock Mode</Button>
      <Button onClick={() => clearActiveInstallationMetrics()}>
        Clear Active Installation Samples
      </Button>

      <div>Connection: {isConnected ? 'Connected' : 'Disconnected'}</div>
      <div>Reconnect? {shouldReconnect ? 'yes' : 'no'}</div>
      <div>Mock Mode {mockMode ? 'ON' : 'OFF'}</div>
      <div>NETIDENT: {refreshData?.NETIDENT}</div>

      <pre>{JSON.stringify(refreshMetrics, null, 2)}</pre>
      {/* {lastMessage && <pre>{JSON.stringify(lastMessage, null, 2)}</pre>} */}

      <Button
        onClick={() =>
          sendMachineAction({ in: 'lamp_set', factor: refreshData?.LAMP === 100 ? 0 : 100 })
        }
      >
        Lamp Factor: {refreshData?.LAMP}
      </Button>
    </div>
  )
}

function WebsocketsTestPage() {
  return (
    <section className="flex justify-center items-center gap-4 w-full h-full">
      <WSManager />
    </section>
  )
}

export default WebsocketsTestPage

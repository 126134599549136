import { useBattery } from '@uidotdev/usehooks'
import {
  BatteryChargingIcon,
  BatteryFullIcon,
  BatteryLowIcon,
  BatteryMediumIcon
} from 'lucide-react'

export const BatteryStatus = () => {
  const { loading, level, charging } = useBattery()
  if (loading) return null
  return (
    <div className="flex items-center gap-2 select-none">
      {charging ? (
        <BatteryChargingIcon />
      ) : level === null ? (
        <BatteryFullIcon />
      ) : level < 0.33 ? (
        <BatteryLowIcon />
      ) : level < 0.66 ? (
        <BatteryMediumIcon />
      ) : (
        <BatteryFullIcon />
      )}
      {level !== null && <span className="text-lg">{(level * 100).toFixed(0)}%</span>}
    </div>
  )
}

export const waitFor = (ms: number) => new Promise(resolve => setTimeout(resolve, ms))

export const clamp = (value: number, min: number, max: number) => {
  return Math.min(Math.max(value, min), max)
}

export const clampFactor = (
  value: number,
  { min, max }: { min: number; max: number } = { min: 0, max: 100 }
) => clamp(value, min, max)

export const scale = (value: number, from: [number, number], to: [number, number]) => {
  return ((value - from[0]) / (from[1] - from[0])) * (to[1] - to[0]) + to[0]
}

export const scaleClamp = (value: number, from: [number, number], to: [number, number]) => {
  const scaledValue = scale(value, from, to)
  return clamp(scaledValue, to[0], to[1])
}

export const adjustValueRandomly = ({
  value,
  minVariation: minIncrement,
  maxVariation: maxIncrement
}: {
  value: number
  minVariation: number
  maxVariation: number
}): number => {
  const increment = Math.random() * (maxIncrement - minIncrement) + minIncrement
  return value + increment
}

export const formatFloat = (num: number, precision?: number) => {
  return Number(num.toFixed(precision)).toString()
}

export const trimCommas = (str: string) => {
  return str.replace(/^,+|,+$/g, '')
}

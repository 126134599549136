import Dexie, { Table } from 'dexie'
import { Installation, InstallationFileMeta } from '@uv/machine'

export type InstallationInDb = Pick<
  Installation,
  'id' | 'machineId' | 'installationTimestamp' | 'fileName'
> & {
  meta: InstallationFileMeta
  file?: ArrayBuffer
  hasFile: 0 | 1 // Dexie doesn't support boolean as a key
  shouldDelete: 0 | 1 // Dexie doesn't support boolean as a key
}

export type VideoInDb = {
  fileName: string
  installationId: string
  timestamp: string
  machineId: string
  duration: number
  file?: ArrayBuffer
  hasFile: 0 | 1 // Dexie doesn't support boolean as a key
  shouldDelete: 0 | 1 // Dexie doesn't support boolean as a key
}

export class MySubClassedDexie extends Dexie {
  installation!: Table<InstallationInDb>
  video!: Table<VideoInDb>

  constructor() {
    super('uv-relining')
    this.version(1).stores({
      installation: 'id, machineId, installationTimestamp, fileName, hasFile, shouldDelete',
      video: 'fileName, installationId, timestamp, machineId, duration, hasFile, url'
    })
  }
}

export const db = new MySubClassedDexie()

import { dbGetAllInstallations } from '@/db/actions'
import { toast } from '@ui/components/ui/sonner'
import { cloudSyncInstallations } from './cloud-sync-installations'
import { handleFileSyncActions } from '../machine/sync/handle-file-sync-actions'
import { useDataStoreLocalStorage } from '../machine/use-data-store-local-storage'

const CLOUD_SYNC_API_URL = import.meta.env.VITE_CLOUD_SYNC_API_URL
if (!CLOUD_SYNC_API_URL) throw new Error('CLOUD_SYNC_API_URL is not defined')

export const useCloudSyncInstallations = () => {
  const user = useDataStoreLocalStorage(s => s.user)

  const syncInstallations = async () => {
    if (!user.isAuthenticated) return

    const localInstallations = await dbGetAllInstallations()

    const installationsWithActions = await cloudSyncInstallations(
      localInstallations.map(i => {
        return {
          fileName: i.fileName,
          fileMeta: i.meta,
          hasFile: Boolean(i.hasFile)
        }
      })
    )
    if (!installationsWithActions) return

    await handleFileSyncActions({
      type: 'installation',
      filesWithSyncActions: installationsWithActions
    })

    toast.success('Synced Installations with Cloud', {
      position: 'top-center',
      duration: 2000
    })
  }

  return syncInstallations
}

import { useRef, useEffect, ComponentPropsWithoutRef } from 'react'
import * as d3 from 'd3'

interface DataPoint {
  timestamp: Date
  value: number
}

type Props = ComponentPropsWithoutRef<'svg'> & {
  data: DataPoint[]
  width?: number
  height?: number
  marginTop?: number
  marginRight?: number
  marginBottom?: number
  marginLeft?: number
  minValueExtra?: number
  maxValueExtra?: number
}

const LineChart = ({
  data,
  width = 600,
  height = 300,
  marginTop = 0,
  marginRight = 0,
  marginBottom = 0,
  marginLeft = 0,
  minValueExtra = 0,
  maxValueExtra = 0,
  className,
  ...props
}: Props) => {
  const svgRef = useRef<SVGSVGElement | null>(null)

  useEffect(() => {
    const svg = d3.select(svgRef.current)

    // Set margins and dimensions
    const margin = { top: marginTop, right: marginRight, bottom: marginBottom, left: marginLeft }
    const innerWidth = width - margin.left - margin.right
    const innerHeight = height - margin.top - margin.bottom

    // Determine the min and max values for the y-axis
    // const minValue = d3.min(data, d => d.value) ?? 0
    const minValue = (d3.min(data, d => d.value) ?? 0) + minValueExtra
    const maxValue = (d3.max(data, d => d.value) ?? 100) + maxValueExtra
    const yPadding = (maxValue - minValue) * 0.1 // Add 10% padding

    // Set up the x and y scales
    const xScale = d3
      .scaleTime()
      .domain(d3.extent(data, d => d.timestamp) as [Date, Date])
      .range([0, innerWidth])

    const yScale = d3
      .scaleLinear()
      .domain([minValue - yPadding, maxValue + yPadding])
      .range([innerHeight, 0])

    // Clear any previous elements
    svg.selectAll('*').remove()

    // Append a gradient definition
    const defs = svg.append('defs')
    const gradient = defs
      .append('linearGradient')
      .attr('id', 'line-gradient')
      .attr('x1', '0%')
      .attr('x2', '0%')
      .attr('y1', '0%')
      .attr('y2', '100%')

    gradient
      .append('stop')
      .attr('offset', '0%')
      .attr('stop-color', '#6200B8')
      .attr('stop-opacity', 0.3)

    gradient
      .append('stop')
      .attr('offset', '100%')
      .attr('stop-color', '#6200B8')
      .attr('stop-opacity', 0)

    // Append the chart group
    const g = svg.append('g').attr('transform', `translate(${margin.left},${margin.top})`)

    // Create the line generator
    const line = d3
      .line<DataPoint>()
      .x(d => xScale(d.timestamp))
      .y(d => yScale(d.value))
      .curve(d3.curveMonotoneX)

    // Create the area generator
    const area = d3
      .area<DataPoint>()
      .x(d => xScale(d.timestamp))
      .y0(innerHeight)
      .y1(d => yScale(d.value))
      .curve(d3.curveMonotoneX)

    // Append the area path
    g.append('path').datum(data).attr('fill', 'url(#line-gradient)').attr('d', area)

    // Append the line path
    g.append('path')
      .datum(data)
      .attr('fill', 'none')
      .attr('stroke', '#CE0AFF')
      .attr('stroke-width', 2)
      .attr('d', line)

    // Remove the horizontal grid lines
    g.append('g').attr('class', 'grid').remove()

    // // Draw y-axis labels without a border
    // const yAxis = g.append('g').call(d3.axisLeft(yScale).ticks(5).tickSize(0).tickPadding(5))
    //
    // yAxis
    //   .selectAll('text') // select all the text elements for the yaxis
    //   .style('opacity', 0.5) // change the text opacity to 0.5 (half-transparent)
    //
    // yAxis.select('.domain').remove() // Remove y-axis domain line

    // Draw x-axis labels without a border
    const xAxis = g
      .append('g')
      .attr('transform', `translate(0, ${innerHeight})`)
      .call(d3.axisBottom(xScale).ticks(5).tickSize(0).tickPadding(5))

    xAxis.select('.domain').remove() // Remove x-axis domain line
  }, [data, width, height])

  return <svg ref={svgRef} width={width} height={height} className={className} {...props} />
}

export default LineChart

import { Button } from '@ui/components/ui/button'
import { PlusCircleIcon, XCircleIcon } from 'lucide-react'
import {
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem,
  Input
} from './setup-form-components'
import { useState } from 'react'
import { generateId } from '@uv/machine'
import { Label } from '@ui/components/ui/label'
import { t } from 'i18next'

type Props = {
  label: string
  showValidationError: boolean
  generateIdType: Parameters<typeof generateId>[0]
  valueId: string
  value: string
  updateForm: ({ valueId, value }: { valueId: string; value: string }) => void
  onSelect: (id: string) => void
  selectOptions: { id: string; name: string }[]
  emptyOptionText: string
  selectPlaceholder: string
  inputPlaceholder: string
}

export const SelectOrAdd = ({
  label,
  showValidationError,
  generateIdType,
  valueId,
  value,
  onSelect,
  selectOptions,
  emptyOptionText,
  updateForm,
  selectPlaceholder,
  inputPlaceholder
}: Props) => {
  const [isInputMode, setIsInputMode] = useState(false)

  const handleChangeMode = () => {
    const isNextInputMode = !isInputMode
    setIsInputMode(isNextInputMode)

    updateForm({
      valueId: isNextInputMode ? generateId(generateIdType) : '',
      value: ''
    })
  }

  return (
    <div className="flex flex-col gap-2">
      <div className="flex justify-between items-center">
        <div className="flex gap-2 items-baseline">
          <Label className="text-lg select-none">{label}</Label>
          {showValidationError && (
            <span className="text-accent ml-2 text-md font-medium select-none">
              {t('installation.setup.required-fied')}
            </span>
          )}
        </div>

        <Button
          onClick={handleChangeMode}
          size="icon"
          variant="ghost"
          className="hover:bg-transparent"
        >
          {isInputMode ? (
            <XCircleIcon className="w-5 h-5 text-muted-foreground" />
          ) : (
            <PlusCircleIcon className="w-5 h-5 text-muted-foreground" />
          )}
        </Button>
      </div>

      {isInputMode ? (
        <Input
          value={value}
          onChange={e =>
            updateForm({
              valueId,
              value: e.target.value
            })
          }
          placeholder={inputPlaceholder}
          autoFocus
        />
      ) : (
        <Select onValueChange={onSelect} value={valueId}>
          <SelectTrigger>
            <SelectValue placeholder={selectPlaceholder} />
          </SelectTrigger>
          <SelectContent>
            {selectOptions.length > 0 ? (
              selectOptions.map(({ id, name }) => {
                return (
                  <SelectItem key={id} value={id}>
                    {name}
                  </SelectItem>
                )
              })
            ) : (
              <SelectItem value="no-projects" disabled>
                {emptyOptionText}
              </SelectItem>
            )}
          </SelectContent>
        </Select>
      )}
    </div>
  )
}

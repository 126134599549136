import { useEffect, useRef, useState } from 'react'
import useWebSocket, { ReadyState } from 'react-use-websocket'
import { MachineConnectionStatus, MachineVideoInMessage } from '@uv/machine'
import { waitFor } from '../utils'
import { useDataStore } from './use-data-store'
import { useDataStoreLocalStorage } from './use-data-store-local-storage'
import { MachineAction } from '../websocket/machine-websocket/handle-send-machine-action'

const VIDEO_URL = import.meta.env.VITE_VIDEO_URL
if (!VIDEO_URL) throw new Error('VIDEO_URL is not defined')

export const useMachineVideo = () => {
  const [remoteStream, setRemoteStream] = useState<MediaStream | null>(null)
  const peerConnection = useRef<RTCPeerConnection | null>(null)
  const [_hasVideoOwnership, setHasVideoOwnership] = useState(false)
  const [retry, setRetry] = useState(false)
  const [shouldConnect, setShouldConnect] = useState(true)
  const [isVideoRecording, setIsVideoRecording] = useState(false)
  const [isProcessingVideoStopRecording, setIsProcessingVideoStopRecording] = useState(false)
  const [isProcessingSnapshot, setIsProcessingSnapshot] = useState(false)
  const mockMode = useDataStore(s => s.mockMode)
  const activeInstallationFileMeta = useDataStoreLocalStorage(s => s.activeInstallationFileMeta)

  const { sendMessage, lastJsonMessage, readyState } = useWebSocket(
    VIDEO_URL,
    {
      share: true,
      reconnectAttempts: 0,
      shouldReconnect: () => false, // Prevent automatic reconnection
      onClose: () => {
        setShouldConnect(false)
        setHasVideoOwnership(false)
        peerConnection.current?.close()
      },
      onOpen: () => {
        createPeerConnection()
      }
    },
    // Connect on demand
    // https://github.com/robtaussig/react-use-websocket/issues/204#issuecomment-1757272583
    shouldConnect
  )

  const websocketConnectionStatus = {
    [ReadyState.CONNECTING]: 'CONNECTING' as const,
    [ReadyState.OPEN]: 'CONNECTED' as const,
    [ReadyState.CLOSING]: 'DISCONNECTING' as const,
    [ReadyState.CLOSED]: 'DISCONNECTED' as const,
    [ReadyState.UNINSTANTIATED]: 'UNINSTANTIATED' as const
  }[readyState] satisfies MachineConnectionStatus

  const createPeerConnection = () => {
    const peer = new RTCPeerConnection({
      iceServers: []
    })
    peerConnection.current = peer

    // Setup stream event to receive remote stream
    peer.ontrack = event => {
      setRemoteStream(event.streams[0])
    }

    peer.onicecandidate = event => {
      if (event.candidate) {
        sendMessage(JSON.stringify({ type: 'ice', data: event.candidate }))
      }
    }

    return peer
  }

  /////////////////////////
  // RETRY CONNECTION
  // Indicated by the websocket message:
  // { type: 'cmd', data: 'retry' }
  //
  // We need a 200ms delay by server specification
  /////////////////////////
  useEffect(() => {
    if (!retry) return

    const timer = setTimeout(() => {
      setShouldConnect(true)
      setRetry(false)
    }, 200)

    return () => clearTimeout(timer)
  }, [retry])

  /////////////////////////
  // ACTIONS
  /////////////////////////
  const sendStartRecordingMessage = () => {
    if (mockMode) {
      return setIsVideoRecording(true)
    }

    if (websocketConnectionStatus !== 'CONNECTED') return

    const installationName = activeInstallationFileMeta?.name ?? ''

    return sendMessage(
      JSON.stringify({
        type: 'rec_start',
        data: {
          meta_name: installationName
        }
      } satisfies MachineVideoInMessage)
    )
  }

  const sendStopRecordingMessage = () => {
    if (mockMode) {
      return setIsVideoRecording(false)
    }

    if (websocketConnectionStatus !== 'CONNECTED') return

    setIsProcessingVideoStopRecording(true)
    return sendMessage(
      JSON.stringify({
        type: 'rec_stop',
        data: null
      } satisfies MachineVideoInMessage)
    )
  }

  // This function bakes in all the necessary for the file event message which is sent to the /sysio websocket endpoint
  // We have to do it like this because video uses a different websocket endpoint
  const sendFileEvent = (
    takeScreenshot: (params: Extract<MachineAction, { in: 'file_event' }>) => void
  ) => {
    return takeScreenshot({
      in: 'file_event',
      meta: {
        type: 'screenshot',
        timestamp: new Date().toISOString()
      }
    })
  }

  const sendTakeScreenshotMessage = async (
    takeScreenshot: (params: Extract<MachineAction, { in: 'file_event' }>) => void
  ) => {
    if (websocketConnectionStatus !== 'CONNECTED' && !mockMode) return
    setIsProcessingSnapshot(true)

    if (isVideoRecording) {
      sendFileEvent(takeScreenshot)
      setIsProcessingSnapshot(false)
      return
    }

    sendStartRecordingMessage()
    // Sometimes the recording doesn't start immediately, so we need to wait a bit before taking the screenshot
    await waitFor(2000)

    sendFileEvent(takeScreenshot)
    sendStopRecordingMessage()

    //
    // We trigger setIsSnapshotProcessing(false) when we receive the 'rec' message from the machine
  }

  // // Since we don't have a way to ask the machine if it's recording, we need to stop the recording on mount.
  // // To make sure our local state is in sync with the machine state.
  // useEffect(() => {
  //   if (connectionStatus === 'CONNECTED') {
  //     sendStopRecordingMessage()
  //   }
  // }, [connectionStatus])

  /////////////////////////
  // WEBSOCKET MESSAGES
  /////////////////////////
  useEffect(() => {
    if (!lastJsonMessage) return
    handleMessage(lastJsonMessage)
  }, [lastJsonMessage])

  const handleMessage = async (lastJsonMessage: any) => {
    if (!peerConnection.current) return

    if (lastJsonMessage.type !== 'cmd') setHasVideoOwnership(true)

    // TODO: parse message using zod

    switch (lastJsonMessage.type) {
      case 'sdp':
        await peerConnection.current.setRemoteDescription(
          new RTCSessionDescription(lastJsonMessage.data)
        )
        if (lastJsonMessage.data.type === 'offer') {
          const answer = await peerConnection.current.createAnswer()
          await peerConnection.current.setLocalDescription(answer)
          sendMessage(
            JSON.stringify({ type: 'sdp', data: peerConnection.current.localDescription })
          )
        }
        break
      case 'ice':
        await peerConnection.current.addIceCandidate(new RTCIceCandidate(lastJsonMessage.data))
        break

      case 'cmd':
        if (lastJsonMessage.data === 'retry') {
          peerConnection.current?.close()
          peerConnection.current = null
          setShouldConnect(false)
          setRetry(true)
        }
        break

      case 'rec': {
        const isRecording = Boolean(lastJsonMessage.data)
        if (!isRecording) {
          setIsProcessingVideoStopRecording(false)
          setIsProcessingSnapshot(false)
        }
        setIsVideoRecording(isRecording)
        break
      }

      default:
        break
    }
  }

  const reconnect = () => setShouldConnect(true)

  return {
    reconnect,
    websocketConnectionStatus,
    remoteStream,
    isVideoRecording,
    isProcessingSnapshot,
    isProcessingVideoStopRecording,
    sendStartRecordingMessage,
    sendStopRecordingMessage,
    sendTakeScreenshotMessage
  }
}

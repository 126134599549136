import { Sidebar } from '@/components/sidebar/sidebar'
import { Outlet } from 'react-router-dom'
import { TopBarInfoIcons } from '@/components/installation/shared/top-bar'

export default function HomeLayout() {
  return (
    <main className="min-h-screen min-w-screen w-full flex">
      <Sidebar />

      <section className="w-full flex flex-col h-screen px-8 py-7">
        <section className="col-span-2 flex justify-between items-baseline mb-[36px] select-none">
          <div className="flex text-2xl gap-4 items-baseline"></div>

          <TopBarInfoIcons />
        </section>

        <Outlet />
      </section>
    </main>
  )
}

export type UnitDistance = 'm' | 'ft'
export type UnitSpeed = 'm/h' | 'm/m' | 'f/h' | 'f/m'
export type UnitTemperature = 'c' | 'f'
export type UnitPressure = 'bar' | 'psi'

/////////////////////
// DISTANCE
/////////////////////
export const distanceUnits = [
  {
    id: 'm',
    name: 'Meters',
    symbol: 'm'
  },
  {
    id: 'ft',
    name: 'Feet',
    symbol: 'ft'
  }
  // {
  //   id: 'å',
  //   name: 'Ångström',
  //   symbol: 'Å'
  // }
] satisfies { id: UnitDistance; name: string; symbol: string }[]

export const getDistanceSymbol = (unit: UnitDistance) =>
  distanceUnits.find(u => u.id === unit)?.symbol ?? ''

const toMeters = (value: number, from: UnitDistance) => {
  switch (from) {
    case 'm':
      return value
    case 'ft':
      return value * 0.3048
    // case 'å':
    //   return value / 10e10
    default:
      return value
  }
}

const fromMeters = (value: number, to: UnitDistance) => {
  switch (to) {
    case 'm':
      return value
    case 'ft':
      return value * 3.28084
    // case 'å':
    //   return value * 10e10
    default:
      return value
  }
}

export const convertDistance = ({
  value,
  from,
  to
}: {
  value: number
  from: UnitDistance
  to: UnitDistance
}) => {
  return fromMeters(toMeters(value, from), to)
}

/////////////////////
// SPEED
/////////////////////

export const speedUnits = [
  {
    id: 'm/h',
    name: 'Meters per hour',
    symbol: 'm/h'
  },
  {
    id: 'm/m',
    name: 'Meters per minute',
    symbol: 'm/m'
  },
  {
    id: 'f/h',
    name: 'Feet per hour',
    symbol: 'f/h'
  },
  {
    id: 'f/m',
    name: 'Feet per minute',
    symbol: 'f/m'
  }
  // {
  //   id: 'å/μs',
  //   name: 'Ångström per microsecond',
  //   symbol: 'Å/μs'
  // }
] satisfies { id: UnitSpeed; name: string; symbol: string }[]

export const getSpeedSymbol = (unit: UnitSpeed) => speedUnits.find(u => u.id === unit)?.symbol ?? ''

const toMetersPerHour = (value: number, from: UnitSpeed) => {
  switch (from) {
    case 'm/h':
      return value
    case 'm/m':
      return value * 60
    case 'f/h':
      return value * 0.3048 * 3600
    case 'f/m':
      return value * 0.3048 * 60
    // case 'å/μs':
    //   return (value * 36) / 100
    default:
      return value
  }
}

const fromMetersPerHour = (value: number, to: UnitSpeed) => {
  switch (to) {
    case 'm/h':
      return value
    case 'm/m':
      return value / 60
    case 'f/h':
      return value / 0.3048 / 3600
    case 'f/m':
      return value / 0.3048 / 60
    // case 'å/μs':
    //   return (value * 100) / 36
    default:
      return value
  }
}

export const convertSpeed = ({
  value,
  from,
  to
}: {
  value: number
  from: UnitSpeed
  to: UnitSpeed
}) => {
  return fromMetersPerHour(toMetersPerHour(value, from), to)
}

/////////////////////
// TEMPERATURE
/////////////////////

export const temperatureUnits = [
  {
    id: 'c',
    name: 'Celsius',
    symbol: '°C'
  },
  {
    id: 'f',
    name: 'Fahrenheit',
    symbol: '°F'
  }
  // {
  //   id: 'k',
  //   name: 'Kelvin',
  //   symbol: 'K'
  // }
] satisfies { id: UnitTemperature; name: string; symbol: string }[]

export const getTemperatureSymbol = (unit: UnitTemperature) =>
  temperatureUnits.find(u => u.id === unit)?.symbol ?? ''

const toCelsius = (value: number, from: UnitTemperature) => {
  switch (from) {
    case 'c':
      return value
    case 'f':
      return ((value - 32) * 5) / 9
    // case 'k':
    //   return value - 273.15
  }
}

const fromCelsius = (value: number, to: UnitTemperature) => {
  switch (to) {
    case 'c':
      return value
    case 'f':
      return (value * 9) / 5 + 32
    // case 'k':
    //   return value + 273.15
  }
}

export const convertTemperature = ({
  value,
  from,
  to
}: {
  value: number
  from: UnitTemperature
  to: UnitTemperature
}) => {
  return fromCelsius(toCelsius(value, from), to)
}

/////////////////////
// PRESSURE
/////////////////////

export const pressureUnits = [
  {
    id: 'bar',
    name: 'Bar',
    symbol: 'BAR'
  },
  {
    id: 'psi',
    name: 'PSI',
    symbol: 'PSI'
  }
] satisfies { id: UnitPressure; name: string; symbol: string }[]

export const getPressureSymbol = (unit: UnitPressure) =>
  pressureUnits.find(u => u.id === unit)?.symbol ?? ''

const toBar = (value: number, from: UnitPressure) => {
  switch (from) {
    case 'bar':
      return value
    case 'psi':
      return value / 14.503774389728312
    default:
      return value
  }
}

const fromBar = (value: number, to: UnitPressure) => {
  switch (to) {
    case 'bar':
      return value
    case 'psi':
      return value * 14.503774389728312
    default:
      return value
  }
}

export const convertPressure = ({
  value,
  from,
  to
}: {
  value: number
  from: UnitPressure
  to: UnitPressure
}) => {
  return fromBar(toBar(value, from), to)
}

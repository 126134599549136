import { MachineOutMessage, isVideoFileMeta } from '@uv/machine'
import { cloudSyncVideos } from '../../../cloud/cloud-sync-videos'
import { useDataStore } from '../../use-data-store'
import { dbGetOrCreateVideo } from '@/db/actions'
import { isOnline } from '@/lib/utils/is-online'
import { FILE_READ_CHUNK_SIZE } from '../process-file-chunk'
import { handleFileSyncActions } from '../handle-file-sync-actions'

export const handleFileMetaVideo = async ({
  machineId,
  file,
  meta
}: {
  machineId: string
  file: string
  meta: Extract<MachineOutMessage, { out: 'file_meta' }>['payload']
}) => {
  const sendMachineSyncFilesAction = useDataStore.getState().sendMachineSyncFilesAction

  if (!file.endsWith('.ts')) return

  if (!isVideoFileMeta(meta)) {
    // TODO: log it somewhere
    console.error(`handleFileMetaVideo error. Invalid meta format: ${meta}`)
    return
  }

  const videoInLocalDb = await dbGetOrCreateVideo({
    machineId,
    fileName: file,
    duration: meta.duration
  })
  if (!videoInLocalDb) return

  // -------------------------------------------------
  // 1. Schedule `file_remove` for already synced files
  // -------------------------------------------------
  if (videoInLocalDb?.shouldDelete === 1) {
    return sendMachineSyncFilesAction({
      in: 'file_remove',
      file
    })
  }

  // -------------------------------------------------
  // 2. Sync with Cloud
  // -------------------------------------------------
  const hasInternetConnection = await isOnline()
  if (hasInternetConnection) {
    const filesWithSyncActions = await cloudSyncVideos([
      {
        fileName: videoInLocalDb.fileName,
        duration: videoInLocalDb.duration,
        hasFile: Boolean(videoInLocalDb.hasFile)
      }
    ])

    if (filesWithSyncActions) {
      await handleFileSyncActions({
        type: 'video',
        filesWithSyncActions: filesWithSyncActions
      })
      return
    }
  }

  // -------------------------------------------------
  // 3. If no internet connection, send `file_read`
  // -------------------------------------------------
  return sendMachineSyncFilesAction({
    in: 'file_read',
    file,
    offset: 0,
    count: FILE_READ_CHUNK_SIZE
  })
}

import { useRegisterSW } from 'virtual:pwa-register/react'
// import { pwaInfo } from 'virtual:pwa-info'
import { Button } from '@uv/ui/components/ui/button'

// console.log(pwaInfo)

function ReloadPrompt() {
  // replaced dynamically
  const buildDate = '__DATE__'
  // replaced dyanmicaly
  const reloadSW = '__RELOAD_SW__'

  const {
    offlineReady: [offlineReady, setOfflineReady],
    needRefresh: [needRefresh, setNeedRefresh],
    updateServiceWorker
  } = useRegisterSW({
    onRegisteredSW(swUrl, r) {
      console.log(`Service Worker at: ${swUrl}`)
      // @ts-expect-error just ignore
      if (reloadSW === 'true') {
        r &&
          setInterval(() => {
            console.log('Checking for sw update')
            r.update()
          }, 20000 /* 20s for testing purposes */)
      } else {
        // eslint-disable-next-line prefer-template
        console.log('SW Registered: ' + r)
      }
    },
    onRegisterError(error) {
      console.log('SW registration error', error)
    }
  })

  const close = () => {
    setOfflineReady(false)
    setNeedRefresh(false)
  }

  return (
    <div className="absolute p-0 m-0 w-0 h-0">
      {(offlineReady || needRefresh) && (
        <div className="fixed right-0 bottom-0 m-4 p-3 rounded text-left bg-zinc-900">
          <div className="mb-2">
            {offlineReady ? (
              <span>App ready to work offline</span>
            ) : (
              <span>New content available, click on reload button to update.</span>
            )}
          </div>
          <div className="flex gap-4">
            {needRefresh && <Button onClick={() => updateServiceWorker(true)}>Reload</Button>}
            <Button onClick={() => close()}>Close</Button>
          </div>
        </div>
      )}
      <div className="invisible">{buildDate}</div>
    </div>
  )
}

export default ReloadPrompt

import { useDataStore } from '@/lib/machine/use-data-store'
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogCancel,
  AlertDialogAction
} from '@ui/components/ui/alert-dialog'
import { Button } from '@ui/components/ui/button'
import { Label } from '@ui/components/ui/label'
import { Switch } from '@ui/components/ui/switch'
import { MachineSystemData, machineAlarms } from '@uv/machine'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

type Props = {
  showDialog: boolean
  closeDialog: () => void
  closeSettings: () => void
}

export const SystemOverrideDialog = ({ showDialog, closeDialog, closeSettings }: Props) => {
  const { t } = useTranslation()
  const sendMachineAction = useDataStore(s => s.sendMachineAction)
  const machineConfiguration = useDataStore(s => s.machineConfiguration)

  const [overridesInputs, setOverridesInputs] = useState<MachineSystemData['OVERRIDES']>(
    machineConfiguration?.overrides || [
      false, // ALARM_UV_HOT
      false, // ALARM_AIR_HOT
      false, // ALARM_MOTOR
      false, // ALARM_AIRSUPPLY
      false, // ALARM_HEAD
      false, // ALARM_PRESSURE
      false, // ALARM_SLIP
      false // ALARM_SENSOR
    ]
  )

  const toggleOverride = (index: number) => {
    setOverridesInputs(prev => {
      const newOverrides = [...prev]
      newOverrides[index] = !newOverrides[index]
      return newOverrides as MachineSystemData['OVERRIDES']
    })
  }

  return (
    <AlertDialog open={showDialog}>
      <AlertDialogContent className="min-w-[40vw] p-16">
        <AlertDialogHeader>
          <AlertDialogTitle className="text-3xl mb-4 text-center">
            {t('settings.service-mode.title')}
          </AlertDialogTitle>

          <AlertDialogDescription className="text-lg text-center">
            {t('settings.service-mode.description')}
          </AlertDialogDescription>
        </AlertDialogHeader>

        <div className="grid grid-cols-2 gap-y-4 gap-x-16 mt-6 mx-8">
          {overridesInputs.map((override, index) => {
            const name = machineAlarms[index]

            return (
              <div className="flex items-center justify-between" key={`override-${name}`}>
                <Label htmlFor={name}>{name}</Label>
                <Switch
                  id={name}
                  checked={override}
                  onCheckedChange={() => toggleOverride(index)}
                />
              </div>
            )
          })}
        </div>

        <AlertDialogFooter className="self-end gap-4 mt-16">
          <AlertDialogCancel
            className="w-1/2 p-8 text-md font-bold rounded-xl"
            onClick={closeDialog}
          >
            {t('settings.service-mode.dismiss-button')}
          </AlertDialogCancel>
          <AlertDialogAction asChild>
            <Button
              className="w-1/2 p-8 text-md font-bold rounded-xl"
              variant="destructive"
              onClick={() => {
                sendMachineAction({ in: 'overrides_configure', overrides: overridesInputs })
                closeSettings()
                closeDialog()
              }}
            >
              {t('settings.service-mode.bypass-button')}
            </Button>
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}

import { Label } from '@ui/components/ui/label'
import { MIN_AIR_TEMP_C, MAX_AIR_TEMP_C } from '@/lib/constants'
import { useDataStoreLocalStorage } from '@/lib/machine/use-data-store-local-storage'
import { useTranslation } from 'react-i18next'
import { Card, CardHeader, CardTitle, CardContent } from '@ui/components/ui/card'
import { cn } from '@ui/utils'
import {
  Input,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Switch
} from '../installation/stages/1-setup/setup-form-components'
import { UnitPressure, UnitTemperature, pressureUnits, temperatureUnits } from '@/lib/units'
import {
  translatePressureUnitName,
  translateTemperatureUnitName
} from '../installation/utils/translate-unit-name'
import { trimCommas } from '@/lib/utils'

export const SettingsCustomWarnings = () => {
  const { t } = useTranslation()
  const settings = useDataStoreLocalStorage(s => s.settings)
  const setSettingsPartially = useDataStoreLocalStorage(s => s.setSettingsPartially)
  const { unitPressure, unitTemperature } = settings

  return (
    <section>
      <div className="flex flex-col gap-6">
        <Card className={cn('p-2 bg-zinc-900/70 rounded-2xl')}>
          <CardHeader className="flex items-center justify-between pb-0">
            <CardTitle className="sr-only">
              {t('settings.warnings.uv-light-temperature-label')}
            </CardTitle>
            <div className="w-full flex justify-between items-center">
              <Label
                htmlFor="custom-warning-temperature"
                className="text-2xl font-semibold leading-none tracking-tight"
              >
                {t('settings.warnings.uv-light-temperature-label')}
              </Label>

              <Switch
                id="custom-warning-temperature"
                checked={settings.customWarningUv?.enabled ?? false}
                onCheckedChange={() => {
                  setSettingsPartially(prev => {
                    return {
                      customWarningUv: {
                        min: prev.customWarningUv?.min ?? null,
                        max: prev.customWarningUv?.max ?? null,
                        enabled: !prev.customWarningUv?.enabled ?? true
                      }
                    }
                  })
                }}
              />
            </div>
          </CardHeader>
          <CardContent className="flex flex-col gap-6">
            <div className="text-muted-foreground">
              {t('settings.warnings.uv-light-temperature-description')}
            </div>
            <div className="flex items-center gap-8 w-full">
              <div className="flex items-center gap-4">
                <Label htmlFor="min-uv" className="text-md">
                  {t('settings.warnings.uv-light-temperature-min-label')}
                </Label>
                <Input
                  id="min-uv"
                  type="number"
                  min={MIN_AIR_TEMP_C}
                  max={MAX_AIR_TEMP_C}
                  placeholder="0"
                  className="w-24"
                  value={settings.customWarningUv?.min ?? ''}
                  onChange={e => {
                    const parsedInputValue = parseFloat(trimCommas(e.target.value))
                    setSettingsPartially(prev => {
                      return {
                        customWarningUv: {
                          min: isNaN(parsedInputValue) ? null : parsedInputValue,
                          max: prev.customWarningUv?.max ?? null,
                          enabled: prev.customWarningUv?.enabled ?? false
                        }
                      }
                    })
                  }}
                />
              </div>

              <div className="flex items-center gap-4">
                <Label htmlFor="max-uv" className="text-md">
                  {t('settings.warnings.uv-light-temperature-max-label')}
                </Label>
                <Input
                  id="max-uv"
                  type="number"
                  min={MIN_AIR_TEMP_C}
                  max={MAX_AIR_TEMP_C}
                  placeholder="0"
                  className="w-24"
                  value={settings.customWarningUv?.max ?? ''}
                  onChange={e => {
                    const parsedInputValue = parseFloat(trimCommas(e.target.value))
                    setSettingsPartially(prev => {
                      return {
                        customWarningUv: {
                          min: prev.customWarningUv?.min ?? null,
                          max: isNaN(parsedInputValue) ? null : parsedInputValue,
                          enabled: prev.customWarningUv?.enabled ?? false
                        }
                      }
                    })
                  }}
                />
              </div>

              <div className="flex flex-col ml-auto">
                <Select
                  value={unitTemperature}
                  onValueChange={v =>
                    setSettingsPartially({
                      unitTemperature: v as UnitTemperature
                    })
                  }
                >
                  <SelectTrigger className="w-full px-6 py-8 mr-2 text-md rounded-2xl">
                    <SelectValue placeholder="Select" />
                  </SelectTrigger>
                  <SelectContent>
                    {temperatureUnits.map(({ id }) => {
                      return (
                        <SelectItem key={id} value={id} className="text-md">
                          {translateTemperatureUnitName(id)}
                        </SelectItem>
                      )
                    })}
                  </SelectContent>
                </Select>
              </div>
            </div>
          </CardContent>
        </Card>

        <Card className={cn('p-2 bg-zinc-900/70 rounded-2xl')}>
          <CardHeader className="flex items-center justify-between pb-0">
            <CardTitle className="sr-only">{t('settings.warnings.air-pressure-label')}</CardTitle>
            <div className="w-full flex justify-between items-center">
              <Label
                htmlFor="custom-warning-pressure"
                className="text-2xl font-semibold leading-none tracking-tight"
              >
                {t('settings.warnings.air-pressure-label')}
              </Label>

              <Switch
                id="custom-warning-pressure"
                checked={settings.customWarningPressure?.enabled ?? false}
                onCheckedChange={() => {
                  setSettingsPartially(prev => {
                    return {
                      customWarningPressure: {
                        min: prev.customWarningPressure?.min ?? null,
                        max: prev.customWarningPressure?.max ?? null,
                        enabled: !prev.customWarningPressure?.enabled ?? true
                      }
                    }
                  })
                }}
              />
            </div>
          </CardHeader>
          <CardContent className="flex flex-col gap-6">
            <div className="text-muted-foreground">
              {t('settings.warnings.air-pressure-description')}
            </div>
            <div className="flex items-center gap-8 w-full">
              <div className="flex items-center gap-4">
                <Label htmlFor="min-uv" className="text-md">
                  {t('settings.warnings.air-pressure-min-label')}
                </Label>
                <Input
                  id="min-pressure"
                  type="number"
                  min={0}
                  max={100}
                  placeholder="0"
                  className="w-24"
                  value={settings.customWarningPressure?.min ?? ''}
                  onChange={e => {
                    const parsedInputValue = parseFloat(trimCommas(e.target.value))
                    setSettingsPartially(prev => {
                      return {
                        customWarningPressure: {
                          min: isNaN(parsedInputValue) ? null : parsedInputValue,
                          max: prev.customWarningPressure?.max ?? null,
                          enabled: prev.customWarningPressure?.enabled ?? false
                        }
                      }
                    })
                  }}
                />
              </div>

              <div className="flex items-center gap-4">
                <Label htmlFor="max-uv" className="text-md">
                  {t('settings.warnings.uv-light-temperature-max-label')}
                </Label>
                <Input
                  id="max-pressure"
                  type="number"
                  min={0}
                  max={100}
                  placeholder="0"
                  className="w-24"
                  value={settings.customWarningPressure?.max ?? ''}
                  onChange={e => {
                    const parsedInputValue = parseFloat(trimCommas(e.target.value))
                    setSettingsPartially(prev => {
                      return {
                        customWarningPressure: {
                          min: prev.customWarningPressure?.min ?? null,
                          max: isNaN(parsedInputValue) ? null : parsedInputValue,
                          enabled: prev.customWarningPressure?.enabled ?? false
                        }
                      }
                    })
                  }}
                />
              </div>

              <div className="flex flex-col ml-auto">
                <Select
                  value={unitPressure}
                  onValueChange={v =>
                    setSettingsPartially({
                      unitPressure: v as UnitPressure
                    })
                  }
                >
                  <SelectTrigger className="w-full px-6 py-8 mr-2 text-md rounded-2xl">
                    <SelectValue placeholder="Select" />
                  </SelectTrigger>
                  <SelectContent>
                    {pressureUnits.map(({ id }) => {
                      return (
                        <SelectItem key={id} value={id} className="text-md">
                          {translatePressureUnitName(id)}
                        </SelectItem>
                      )
                    })}
                  </SelectContent>
                </Select>
              </div>
            </div>
          </CardContent>
        </Card>
      </div>
    </section>
  )
}

import { RefreshData, getDefaultRPMList, rpmListToRecipeParameters } from '@uv/machine'
import { MAX_AIR_PRESSURE_BAR, MAX_AIR_TEMP_C, MAX_SPEED_MH } from '../constants'
import { adjustValueRandomly } from '../utils'
import { useDataStore } from '../machine/use-data-store'

export const getDefaultMockedMachineRefreshData = () => {
  return {
    TS: new Date().toISOString(),
    WATCHDOG: 1,
    ROD_SERVICE: true,
    UV_SERVICE: true,
    UV_FACTORS: [0, 1, 100],
    LAMP: 100,
    ESTOP_OK: true,
    WATCHDOG_OK: true,
    ALARMS: [false, false, false, false, false, false, false, false],
    UV_CURRENTS: [0, 0],
    AIR_PRESSURE: MAX_AIR_PRESSURE_BAR / 2,
    AIR_TEMP: MAX_AIR_TEMP_C / 2,
    DISTANCE: 0,
    SLIP: 0,
    RPM: MAX_SPEED_MH / 2,
    RPM_FACTORS: [0, 0, 100],
    UV_TEMP: [60, 60, 60, 60, 60],
    PLC_VERSION: '0',
    SW_VERSION: '0',
    RECORDING: false,
    LIMP: false,
    NETIDENT: '',
    BATTERY: [true, 100],
    WIFI: 100
  } satisfies RefreshData
}

export const getMockUpdatedRefreshData = (
  maybeRefreshData: RefreshData | null,
  intervalMs: number
) => {
  const rpm_list = useDataStore.getState().machineConfiguration?.rpm_list ?? getDefaultRPMList()
  const refreshData = maybeRefreshData ?? {
    ...getDefaultMockedMachineRefreshData(),
    RPM: rpmListToRecipeParameters(rpm_list).maxSpeed
  }

  // Calculate new distance
  const speedPerHour = Math.abs(refreshData.RPM) / 3600
  const direction = Math.sign(refreshData.RPM_FACTORS[0])
  const speedFactor = Math.abs(Number(refreshData.RPM_FACTORS[0]) / 100)
  const adjustedSpeed = direction * speedPerHour * speedFactor
  const timeInSeconds = intervalMs / 1000
  const distancePerInterval = adjustedSpeed * timeInSeconds

  const newAirPressure =
    refreshData.AIR_PRESSURE < 0.51
      ? 0.6
      : refreshData.AIR_PRESSURE > 0.64
        ? 0.6
        : adjustValueRandomly({
            value: refreshData.AIR_PRESSURE,
            minVariation: -0.005,
            maxVariation: 0.005
          })

  const newAirTemp =
    refreshData.AIR_TEMP < 21
      ? 30
      : refreshData.AIR_TEMP > 49
        ? 30
        : adjustValueRandomly({
            value: refreshData.AIR_TEMP,
            minVariation: -0.1,
            maxVariation: 0.1
          })

  return {
    ...refreshData,
    TS: new Date().toISOString(),
    DISTANCE: refreshData.DISTANCE + distancePerInterval,
    AIR_PRESSURE: newAirPressure,
    AIR_TEMP: newAirTemp
  }
}

import {
  LinerTypeOption,
  ChemistryOption,
  Machine,
  ActiveInstallationRefreshMetrics,
  MachineSystemData,
  RecipeParameters
} from './types'
import { rpmListToRecipeParameters, uvCycleToRecipeParameters } from './utils'

export const getDefaultInstallationRefreshMetrics = (): ActiveInstallationRefreshMetrics => ({
  prevTime: 0,
  delta: 0,
  peak: 0,
  count: 0,
  slowTimes: []
})

export const getDefaultDelay = () => 10 satisfies RecipeParameters['delay']
export const getDefaultRPMList = () => [80, [[0, 100]]] satisfies MachineSystemData['RPM_LIST']
export const getDefaultUVCycle = () => [80, [[1, 100]]] satisfies MachineSystemData['UV_CYCLE']
export const getDefaultAlarmOverrides = () =>
  [false, false, false, false, false, false, false, false] satisfies MachineSystemData['OVERRIDES']

export const getDefaultRecipe = (): RecipeParameters => {
  const { maxSpeed, speeds } = rpmListToRecipeParameters(getDefaultRPMList())
  const { maxPower, powers } = uvCycleToRecipeParameters(getDefaultUVCycle())
  const delay = getDefaultDelay()

  return {
    maxSpeed,
    speeds,
    maxPower,
    powers,
    delay
  }
}

export const getDefaultMachineConfiguration = () => ({
  rpm_list: getDefaultRPMList(),
  uv_cycle: getDefaultUVCycle(),
  overrides: getDefaultAlarmOverrides()
})

export const getDefaultMachineData = (): Machine => ({
  machineId: null,
  connectionStatus: 'DISCONNECTED',
  refreshMetrics: getDefaultInstallationRefreshMetrics(),
  refreshData: null,
  alarmOverrides: getDefaultAlarmOverrides()
})

export const getDefaultLinerTypeOptions = () => {
  return [
    {
      id: 'uvhl',
      name: 'UVHL'
    },
    {
      id: 'uvhl-flex',
      name: 'UVHL Flex'
    }
  ] satisfies LinerTypeOption[]
}

export const getDefaultChemistryOptions = () => {
  return [
    {
      id: 'che_cly6sxfg8000608le8t621mcd',
      reference: 'uvlcr-standard',
      name: 'UVLCR Standard'
    },
    {
      id: 'che_cly6sxbq6000508le32ms05f3',
      reference: 'uvlcr-premium',
      name: 'UVLCR Premium'
    }
  ] satisfies ChemistryOption[]
}

import { useDataStore } from '@/lib/machine/use-data-store'
import { cn } from '@ui/utils'
import { useTranslation } from 'react-i18next'

const StatusPing = ({
  isOn,
  className,
  ...props
}: React.ComponentProps<'span'> & {
  isOn?: boolean
}) => {
  return (
    <span className={cn('relative flex h-8 w-8 justify-center items-center', className)} {...props}>
      {isOn && (
        <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-accent-background opacity-75"></span>
      )}
      <span
        className={cn(
          'relative inline-flex rounded-full h-4 w-4',
          isOn ? 'bg-accent' : 'bg-zinc-900'
        )}
      ></span>
    </span>
  )
}

export const UvStatusPentagon = () => {
  const { t } = useTranslation()
  const refreshData = useDataStore(s => s.refreshData)

  const uvTemp = refreshData?.UV_TEMP ?? [0, 0, 0, 0, 0]
  const UV_FACTOR = Boolean(refreshData?.UV_FACTORS[0] ?? false)

  return (
    <div className="relative w-44 h-44 mb-8 flex flex-col items-center mx-auto">
      <svg
        className="absolute top-0 left-0 fill-zinc-700/50"
        viewBox="0 0 90 88"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M36.1832 3.40576C41.4405 -0.413901 48.5595 -0.413896 53.8168 3.40576L83.7361 25.1434C88.9934 28.9631 91.1932 35.7336 89.1851 41.9139L77.757 77.0861C75.7489 83.2664 69.9895 87.4509 63.4911 87.4509H26.5089C20.0105 87.4509 14.2511 83.2664 12.243 77.0861L0.814881 41.9139C-1.19323 35.7335 1.00664 28.963 6.26395 25.1434L36.1832 3.40576Z" />
      </svg>

      <div className="absolute mt-[20%] w-full px-[20%] flex justify-between">
        <StatusPing isOn={UV_FACTOR && uvTemp[0] > 50} />
        <StatusPing isOn={UV_FACTOR && uvTemp[1] > 50} />
      </div>

      <div className="absolute mt-[55%] w-full px-[10%] flex justify-between">
        <StatusPing isOn={UV_FACTOR && uvTemp[2] > 50} />
        <StatusPing isOn={UV_FACTOR && uvTemp[0] > 50} />
      </div>

      <div className="absolute mt-[75%]">
        <StatusPing isOn={UV_FACTOR && uvTemp[0] > 50} />
      </div>

      <span className="absolute mt-[45%] w-full text-center text-lg leading">
        {t('installation.curing.uv-led')}
      </span>
    </div>
  )
}

import { Label } from '@ui/components/ui/label'
import {
  UnitDistance,
  UnitPressure,
  UnitSpeed,
  UnitTemperature,
  distanceUnits,
  pressureUnits,
  speedUnits,
  temperatureUnits
} from '@/lib/units'
import { useDataStoreLocalStorage } from '@/lib/machine/use-data-store-local-storage'
import { useTranslation } from 'react-i18next'
import {
  translateDistanceUnitName,
  translatePressureUnitName,
  translateSpeedUnitName,
  translateTemperatureUnitName
} from '../installation/utils/translate-unit-name'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from '../installation/stages/1-setup/setup-form-components'

export const SettingsUnits = () => {
  const { t } = useTranslation()
  const settings = useDataStoreLocalStorage(s => s.settings)
  const setSettingsPartially = useDataStoreLocalStorage(s => s.setSettingsPartially)
  const { unitDistance, unitSpeed, unitPressure, unitTemperature } = settings

  return (
    <section>
      {/* <h3 className="mb-4 text-2xl font-medium">{t('settings.units-title')}</h3> */}
      <section className="grid grid-cols-2 gap-12 mb-12">
        <div className="flex flex-col gap-3">
          <Label htmlFor="unit-distance" className="text-lg font-medium">
            {t('settings.distance-label')}
          </Label>
          <Select
            value={unitDistance}
            onValueChange={v =>
              setSettingsPartially({
                unitDistance: v as UnitDistance
              })
            }
          >
            <SelectTrigger className="w-full px-6 py-8 text-md rounded-2xl">
              <SelectValue placeholder="Select" />
            </SelectTrigger>
            <SelectContent>
              {distanceUnits.map(({ id }) => {
                return (
                  <SelectItem key={id} value={id} className="text-md">
                    {translateDistanceUnitName(id)}
                  </SelectItem>
                )
              })}
            </SelectContent>
          </Select>
        </div>

        <div className="flex flex-col gap-3">
          <Label htmlFor="unit-speed" className="text-lg font-medium">
            {t('settings.speed-label')}
          </Label>
          <Select
            value={unitSpeed}
            onValueChange={v =>
              setSettingsPartially({
                unitSpeed: v as UnitSpeed
              })
            }
          >
            <SelectTrigger className="w-full px-6 py-8 text-md rounded-2xl">
              <SelectValue placeholder="Select" />
            </SelectTrigger>
            <SelectContent>
              {speedUnits.map(({ id }) => {
                return (
                  <SelectItem key={id} value={id} className="text-md">
                    {translateSpeedUnitName(id)}
                  </SelectItem>
                )
              })}
            </SelectContent>
          </Select>
        </div>

        <div className="flex flex-col gap-3">
          <Label htmlFor="unit-temperature" className="text-lg font-medium">
            {t('settings.temperature-label')}
          </Label>
          <Select
            value={unitTemperature}
            onValueChange={v =>
              setSettingsPartially({
                unitTemperature: v as UnitTemperature
              })
            }
          >
            <SelectTrigger className="w-full px-6 py-8 text-md rounded-2xl">
              <SelectValue placeholder="Select" />
            </SelectTrigger>
            <SelectContent>
              {temperatureUnits.map(({ id }) => {
                return (
                  <SelectItem key={id} value={id} className="text-md">
                    {translateTemperatureUnitName(id)}
                  </SelectItem>
                )
              })}
            </SelectContent>
          </Select>
        </div>

        <div className="flex flex-col gap-3">
          <Label htmlFor="unit-pressure" className="text-lg font-medium">
            {t('settings.pressure-label')}
          </Label>
          <Select
            value={unitPressure}
            onValueChange={v =>
              setSettingsPartially({
                unitPressure: v as UnitPressure
              })
            }
          >
            <SelectTrigger className="w-full px-6 py-8 text-md rounded-2xl">
              <SelectValue placeholder="Select" />
            </SelectTrigger>
            <SelectContent>
              {pressureUnits.map(({ id }) => {
                return (
                  <SelectItem key={id} value={id} className="text-md">
                    {translatePressureUnitName(id)}
                  </SelectItem>
                )
              })}
            </SelectContent>
          </Select>
        </div>
      </section>
    </section>
  )
}

import { Input } from '@ui/components/ui/input'
import { Switch } from '@ui/components/ui/switch'
import { useState } from 'react'
import { MachineSystemData, RefreshData, machineAlarms } from '@uv/machine'
import { Label } from '@ui/components/ui/label'
import { useDataStore } from '@/lib/machine/use-data-store'
import { useDataStoreLocalStorage } from '@/lib/machine/use-data-store-local-storage'
import { getDefaultMockedMachineRefreshData } from '@/lib/mock/mock'

export const ShowDebugDataButton = () => {
  const devMode = useDataStore(s => s.devMode)
  const isConnected = useDataStore(s => s.isConnected)
  const refreshData = useDataStore(s => s.refreshData)
  const setRefreshData = useDataStore(s => s.setRefreshData)
  const refreshMetrics = useDataStore(s => s.refreshMetrics)
  const machineId = useDataStore(s => s.machineId)

  const activeInstallationStage = useDataStoreLocalStorage(s => s.activeInstallationStage)
  const [showInstallationRefreshData, setShowInstallationRefreshData] = useState(false)

  const refreshDataEntries = Object.entries(refreshData ?? {})

  const machineStatusEntries = Object.entries({
    machineId,
    isConnected,
    ...refreshMetrics,
    stage: activeInstallationStage
  })

  const handleSetRefreshData = (updateFunc: (prevData: RefreshData) => RefreshData) => {
    const defaultRefreshData = getDefaultMockedMachineRefreshData()
    const prevRefreshData = refreshData ?? defaultRefreshData

    const newRefreshData = updateFunc(prevRefreshData)
    setRefreshData(newRefreshData)
  }

  const renderValue = (item: (typeof refreshDataEntries)[0] | (typeof machineStatusEntries)[0]) => {
    const [key, value] = item
    if (typeof value === 'number') {
      return (
        <Input
          value={value}
          type="number"
          onChange={e => {
            const value = e.target.value
            handleSetRefreshData(prev => {
              if (prev === null) return prev // make typescript happy
              const parsedInputValue = Number(value)

              return {
                ...prev,
                [key]: parsedInputValue
              }
            })
          }}
          className="text-xs h-5"
        />
      )
    }

    if (typeof value === 'string') {
      return (
        <Input
          value={value}
          onChange={e => {
            const value = e.target.value
            handleSetRefreshData(prev => {
              if (prev === null) return prev // make typescript happy

              return {
                ...prev,
                [key]: value
              }
            })
          }}
          className="text-xs h-5"
        />
      )
    }

    if (typeof value === 'boolean') {
      return (
        <>
          <div className="relative w-6 h-4">
            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
              <Switch
                checked={value}
                onCheckedChange={() => {
                  handleSetRefreshData(prev => {
                    if (prev === null) return prev // make typescript happy

                    return {
                      ...prev,
                      [key]: !value
                    }
                  })
                }}
                className="transform scale-50 origin-center"
              />
            </div>
          </div>
        </>
      )
    }

    if (key === 'ALARMS') {
      const toggleAlarm = (index: number) => {
        handleSetRefreshData(prev => {
          if (prev === null) return prev // make typescript happy
          return {
            ...prev,
            ALARMS: prev.ALARMS.map((alarm, i) => {
              if (i === index) return !alarm
              return alarm
            }) as MachineSystemData['ALARMS']
          }
        })
      }

      return (
        <>
          <div className="grid grid-cols-4 gap-1 gap-x-6 justify-between ml-2">
            {(value as MachineSystemData['ALARMS']).map((alarmValue, index) => {
              const name = machineAlarms[index]
              return (
                <div className="flex items-center gap-2 justify-between" key={`override-${name}`}>
                  <Label htmlFor={name} className="text-[10px]">
                    {name}
                  </Label>

                  <div className="relative w-6 h-4">
                    <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                      <Switch
                        id={name}
                        checked={alarmValue}
                        onCheckedChange={() => toggleAlarm(index)}
                        className="scale-50"
                      />
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </>
      )
    }

    return <span>{JSON.stringify(value)}</span>
  }
  if (!devMode) return null

  return (
    <>
      <div>
        <button
          onClick={() => setShowInstallationRefreshData(v => !v)}
          className="font-semibold uppercase text-purple-500"
        >
          Show Debug Data
        </button>
        {showInstallationRefreshData && (
          <div className="fixed bottom-10 left-2 flex gap-4 bg-zinc-950/95 p-4 rounded-xl">
            <div>
              <p className="uppercase text-xs mb-2">Refresh Data</p>
              <div className="flex flex-col gap-1">
                {refreshDataEntries.map(([key, value]) => {
                  return (
                    <div key={key} className="flex items-center gap-2 text-xs">
                      <span>{key}:</span>
                      {renderValue([key, value])}
                    </div>
                  )
                })}
              </div>
            </div>

            <div>
              <p className="uppercase text-xs mb-2">Machine Status</p>
              <div className="flex flex-col gap-1">
                {machineStatusEntries.map(([key, value]) => {
                  return (
                    <div key={key} className="flex items-center gap-2 text-xs">
                      <span>{key}:</span>
                      {renderValue([key, value])}
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

import { MachineOutMessage, MachineOutMessageSchema } from '@uv/machine'
import { mightFailSync } from 'might-fail'

export const parseMachineOutMessage = (eventData: unknown): MachineOutMessage | null => {
  const { error: convertToJSONError, result: json } = mightFailSync(() =>
    JSON.parse(eventData as string)
  )
  if (convertToJSONError) {
    console.error(`Error converting evendData to JSON: ${eventData}
                   Error Message: ${convertToJSONError.message}`)
    console.dir(convertToJSONError, { depth: null })
    console.dir(eventData, { depth: null })

    return null
  }

  const parsedResult = MachineOutMessageSchema.safeParse(json)
  if (!parsedResult.success) {
    console.error(`Store Websocket error while parsing out message: ${json}
                   Error Message: ${parsedResult.error.message}`)
    console.dir(parsedResult.error, { depth: null })
    console.dir(json, { depth: null })
    return null
  }

  return parsedResult.data
}

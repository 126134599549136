import { mightFail } from 'might-fail'
import { useDataStore } from '../machine/use-data-store'

// navigator.online is not reliable:
// it returns true if connected to a local area network (LAN), even without internet access.
// We need to check if we are online by making a request to a server

export const isOnline = async () => {
  const setIsOnline = useDataStore.getState().setIsOnline
  const controller = new AbortController()
  const timeoutId = setTimeout(() => controller.abort(), 2000)

  const { error: networkError } = await mightFail(
    fetch('https://1.1.1.1', {
      method: 'HEAD',
      mode: 'no-cors',
      cache: 'no-store',
      signal: controller.signal
    })
  )
  clearTimeout(timeoutId)

  if (networkError) {
    setIsOnline(false)
    return false
  }

  setIsOnline(true)
  return true
}

import { formatDistanceToNow } from 'date-fns'
import { es } from 'date-fns/locale'
import { Locale } from '@/lib/machine/use-data-store-local-storage'

const getImportedLocale = (locale: Locale) => {
  switch (locale) {
    case 'es':
      return es
    case 'en':
    default:
      return undefined
  }
}

export const timeAgoLocale = ({ pastDate, locale }: { pastDate: Date; locale: Locale }) => {
  return formatDistanceToNow(pastDate, { addSuffix: true, locale: getImportedLocale(locale) })
}

import { useDataStore } from '@/lib/machine/use-data-store'
import { useDataStoreLocalStorage } from '@/lib/machine/use-data-store-local-storage'
import {
  convertPressure,
  convertTemperature,
  getPressureSymbol,
  getTemperatureSymbol
} from '@/lib/units'
import { formatFloat } from '@/lib/utils'
import { ExclamationTriangleIcon } from '@heroicons/react/24/solid'
import { toast } from '@ui/components/ui/sonner'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

export const Warnings = () => {
  const { t } = useTranslation()
  const refreshData = useDataStore(s => s.refreshData)
  const settings = useDataStoreLocalStorage(s => s.settings)

  /////////////////////////////////
  // UV
  /////////////////////////////////

  const lowestUvTemp = refreshData ? Math.min(...refreshData.UV_TEMP) : undefined
  const highestUvTemp = refreshData ? Math.max(...refreshData.UV_TEMP) : undefined

  const convertedLowestUVTemp = lowestUvTemp
    ? convertTemperature({
        value: lowestUvTemp,
        from: 'c',
        to: settings.unitTemperature
      })
    : undefined

  const convertedHighestUVTemp = highestUvTemp
    ? convertTemperature({
        value: highestUvTemp,
        from: 'c',
        to: settings.unitTemperature
      })
    : undefined

  const warningMinUvTempCelsius = settings.customWarningUv?.min
    ? convertTemperature({
        value: settings.customWarningUv?.min,
        from: settings.unitTemperature,
        to: 'c'
      })
    : null

  const warningMaxUvTempCelsius = settings.customWarningUv?.max
    ? convertTemperature({
        value: settings.customWarningUv?.max,
        from: settings.unitTemperature,
        to: 'c'
      })
    : null

  const isUvWarningEnabled = settings.customWarningUv?.enabled ?? false

  const isUvTooLow =
    warningMinUvTempCelsius !== null &&
    warningMinUvTempCelsius !== undefined &&
    lowestUvTemp !== null &&
    lowestUvTemp !== undefined &&
    lowestUvTemp < warningMinUvTempCelsius

  const isUvTooHigh =
    warningMaxUvTempCelsius !== null &&
    warningMinUvTempCelsius !== undefined &&
    highestUvTemp !== null &&
    highestUvTemp !== undefined &&
    highestUvTemp > warningMaxUvTempCelsius

  const formattedLowestUVTemp = convertedLowestUVTemp
    ? `${formatFloat(convertedLowestUVTemp)} ${getTemperatureSymbol(settings.unitTemperature)}`
    : ''

  const formattedHighestUVTemp = convertedHighestUVTemp
    ? `${formatFloat(convertedHighestUVTemp)} ${getTemperatureSymbol(settings.unitTemperature)}`
    : ''

  useEffect(() => {
    if (!isUvWarningEnabled) return

    if (isUvTooLow || isUvTooHigh) {
      const message = isUvTooLow
        ? t('notification.warning-uv-low', {
            temperature: formattedLowestUVTemp
          })
        : t('notification.warning-uv-high', {
            temperature: formattedHighestUVTemp
          })

      toast(message, {
        position: 'top-center',
        icon: <ExclamationTriangleIcon className="w-5 text-yellow-500" />
      })
    }
  }, [isUvWarningEnabled, isUvTooLow, isUvTooHigh])

  /////////////////////////////////
  // PRESSURE
  /////////////////////////////////

  const pressure = refreshData?.AIR_PRESSURE
  const convertedPressure = pressure
    ? convertPressure({
        value: pressure,
        from: 'bar',
        to: settings.unitPressure
      })
    : null

  const warningMinPressureBar = settings.customWarningPressure?.min
    ? convertPressure({
        value: settings.customWarningPressure?.min,
        from: settings.unitPressure,
        to: 'bar'
      })
    : null

  const warningMaxPressureBar = settings.customWarningPressure?.max
    ? convertPressure({
        value: settings.customWarningPressure?.max,
        from: settings.unitPressure,
        to: 'bar'
      })
    : null

  const isPressureWarningEnabled = settings.customWarningPressure?.enabled ?? false
  const isPressureTooLow =
    warningMinPressureBar !== null &&
    pressure !== null &&
    pressure !== undefined &&
    pressure < warningMinPressureBar
  const isPressureTooHigh =
    warningMaxPressureBar !== null &&
    pressure !== null &&
    pressure !== undefined &&
    pressure > warningMaxPressureBar

  const formattedPressure = convertedPressure
    ? `${formatFloat(convertedPressure)} ${getPressureSymbol(settings.unitPressure)}`
    : ''

  useEffect(() => {
    if (!isPressureWarningEnabled) return

    if (isPressureTooLow || isPressureTooHigh) {
      const message = isPressureTooLow
        ? t('notification.warning-presure-low', {
            pressure: formattedPressure
          })
        : t('notification.warning-pressure-high', {
            pressure: formattedPressure
          })

      toast(message, {
        position: 'top-center',
        icon: <ExclamationTriangleIcon className="w-5 text-yellow-500" />
      })
    }
  }, [isPressureWarningEnabled, isPressureTooLow, isPressureTooHigh])

  return null
}

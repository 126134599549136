import { useEffect, useRef } from 'react'

type Props = {
  playbackRate: number
}

export const VideoMock = ({ playbackRate = 1 }: Props) => {
  const videoRef = useRef<HTMLVideoElement | null>(null)

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.playbackRate = playbackRate
    }
  }, [playbackRate])

  return (
    <video
      ref={videoRef}
      className="flex-grow rounded-2xl w-auto max-h-full"
      muted
      autoPlay
      loop
      playsInline
    >
      <source src="/video-curing.mp4" type="video/mp4" />
    </video>
  )
}

import { useNavigate } from 'react-router-dom'
import { DashboardCard, DashboardCardTitle } from '@/components/ui/card-dashboard'
import { Button } from '@ui/components/ui/button'
import Confetti from 'react-confetti-boom'
import { ChartCard } from './4-summary/chart-card'
import { MachineConnectionStatus } from '@/components/status-bar/machine-connection-status'
import { BatteryStatus } from '@/components/status-bar/battery-status'
import ClockStatus from '@/components/status-bar/clock-status'
import { convertDistance, getDistanceSymbol } from '@/lib/units'
import { useDataStore } from '@/lib/machine/use-data-store'
import { useDataStoreLocalStorage } from '@/lib/machine/use-data-store-local-storage'
import { useTranslation } from 'react-i18next'
import { formatInstallationDuration } from '../utils/format-installation-duration'

export const InstallationStage4Summary = () => {
  const { t } = useTranslation()
  const sendMachineAction = useDataStore(s => s.sendMachineAction)
  const navigate = useNavigate()

  const activeInstallationFileMeta = useDataStoreLocalStorage(s => s.activeInstallationFileMeta)
  const activeInstallationFinishedAt = useDataStoreLocalStorage(s => s.activeInstallationFinishedAt)
  const activeInstallationSamples = useDataStoreLocalStorage(s => s.activeInstallationSamples)

  const settings = useDataStoreLocalStorage(s => s.settings)
  const distance = activeInstallationFileMeta?.installationInfo.distance || 0
  const startedAt = activeInstallationFileMeta?.installationInfo.startedAt || ''
  const finishedAt = activeInstallationFinishedAt || ''
  const duration = formatInstallationDuration(startedAt, finishedAt)

  const speedChartData = activeInstallationSamples.map(s => ({
    timestamp: new Date(s.timestamp),
    value: s.speed
  }))

  const pressureChartData = activeInstallationSamples.map(s => ({
    timestamp: new Date(s.timestamp),
    value: s.pressure
  }))

  const temperatureChartData = activeInstallationSamples.map(s => ({
    timestamp: new Date(s.timestamp),
    value: s.temperature
  }))

  const distanceInPreferredUnit = convertDistance({
    value: distance,
    from: 'm',
    to: settings.unitDistance
  })
  const valueFormatted = isNaN(distanceInPreferredUnit)
    ? '-'
    : distanceInPreferredUnit === 0
      ? '0'
      : distanceInPreferredUnit.toFixed(2)

  return (
    <>
      <section className="h-screen w-screen">
        <div className="flex justify-end mt-6 mx-8 mb-6">
          <div className="flex items-center gap-4 text-xl">
            <MachineConnectionStatus />
            <BatteryStatus />
            <ClockStatus />
          </div>
        </div>

        <div className="grid grid-cols-[686px,1fr] gap-x-8 items-start w-full p-8 pt-4 select-none">
          <DashboardCard>
            <DashboardCardTitle className="text-4xl mb-12">
              {t('installation.summary.title')}
            </DashboardCardTitle>

            <div className="col-start-1 col-span-1 flex flex-col gap-8">
              <div>
                <ChartCard label={t('installation.summary.speed')} chartData={speedChartData} />
              </div>

              <div className="flex gap-8">
                <ChartCard
                  width="half"
                  label={t('installation.summary.pressure')}
                  chartData={pressureChartData}
                />
                <ChartCard
                  width="half"
                  label={t('installation.summary.temperature')}
                  chartData={temperatureChartData}
                />
              </div>
            </div>
          </DashboardCard>

          <div className="col-start-2 col-span-1 flex flex-col gap-8">
            <div className="flex flex-col gap-8">
              <DashboardCard className="w-full bg-zinc-800/70">
                <DashboardCardTitle className="mb-4">
                  {t('installation.summary.time')}
                </DashboardCardTitle>
                <span className="text-4xl font-bold text-accent">{duration}</span>
              </DashboardCard>

              <DashboardCard className="w-full bg-zinc-800/70">
                <DashboardCardTitle className="mb-4">
                  {t('installation.summary.distance')}
                </DashboardCardTitle>
                <span className="text-4xl font-bold text-accent">{valueFormatted} </span>
                <span className="text-accent text-2xl font-bold leading-none">
                  {getDistanceSymbol(settings.unitDistance)}
                </span>
              </DashboardCard>
            </div>

            <Button
              className="w-full h-auto text-xl py-10 flex justify-center items-center gap-2 text-foreground bg-zinc-700 rounded-3xl hover:bg-zinc-700 select-none"
              onClick={() => {
                sendMachineAction({ in: 'finish_installation' })
                navigate('/')
              }}
            >
              <span>{t('installation.summary.exit-button')}</span>
            </Button>
          </div>
        </div>

        <Confetti
          mode="boom"
          particleCount={150}
          shapeSize={15}
          deg={270}
          effectCount={Infinity}
          effectInterval={5000}
          spreadDeg={57}
          x={0.5}
          y={0.6}
          launchSpeed={3}
          colors={['#ff577f', '#ff884b', '#ffd384', '#ce0aff']}
        />
      </section>
    </>
  )
}

import { DashboardCard } from '@/components/ui/card-dashboard'
import { cn } from '@ui/utils'
import { useState } from 'react'

type Props = Omit<React.ComponentProps<typeof DashboardCard>, 'children'> & {
  children: ({ toggled }: { toggled: boolean }) => React.ReactNode
}

export const CardToggable = ({ className, children, ...props }: Props) => {
  const [toggled, setToggled] = useState(false)

  return (
    <DashboardCard
      className={cn('w-1/2 rounded-3xl select-none', className)}
      onClick={() => setToggled(prev => !prev)}
      {...props}
    >
      {children({ toggled })}
    </DashboardCard>
  )
}

import {
  Form,
  FormLabel as FormLabelBase,
  FormControl,
  FormField,
  FormItem,
  FormMessage as FormMessageBase
} from '@ui/components/ui/form'
import { Input as InputBase } from '@ui/components/ui/input'
import { Switch as SwitchBase } from '@ui/components/ui/switch'
import {
  Select,
  SelectTrigger as SelectTriggerBase,
  SelectValue,
  SelectContent as SelectContentBase,
  SelectItem as SelectItemBase
} from '@ui/components/ui/select'

import {
  RadioGroup,
  RadioGroupCardItem as RadioGroupCardItemBase
} from '@uv/ui/components/ui/radio-group-card'

import { cn } from '@ui/utils'
import { ComponentPropsWithoutRef, ElementRef, forwardRef } from 'react'
import { Label } from '@ui/components/ui/label'
import { useTranslation } from 'react-i18next'

//////////////////////
// FORM
//////////////////////

const FormLabel = forwardRef<
  ElementRef<typeof FormLabelBase>,
  ComponentPropsWithoutRef<typeof FormLabelBase>
>(({ className, ...props }, ref) => (
  <FormLabelBase ref={ref} className={cn('text-lg select-none', className)} {...props} />
))
FormLabel.displayName = 'FormLabel'

// const FormLabel = ({ className, ...props }: ComponentPropsWithoutRef<typeof FormLabelBase>) => {
//   return <FormLabelBase className={cn('text-lg', className)} {...props} />
// }

const FormMessage = forwardRef<
  ElementRef<typeof FormMessageBase>,
  ComponentPropsWithoutRef<typeof FormMessageBase>
>(({ className, ...props }, ref) => (
  <FormMessageBase
    ref={ref}
    className={cn('text-accent ml-2 text-md select-none', className)}
    {...props}
  />
))
FormMessage.displayName = 'FormMessage'

//////////////////////
// LABEL
//////////////////////

type LabelWithValidationProps = React.ComponentProps<'div'> & {
  showValidationError?: boolean
}
const LabelWithValidation = ({
  showValidationError,
  children,
  className,
  ...props
}: LabelWithValidationProps) => {
  const { t } = useTranslation()
  return (
    <div className={cn('flex gap-2 items-baseline', className)} {...props}>
      <Label className="text-lg select-none">{children}</Label>
      {showValidationError && (
        <span className="text-accent ml-2 text-md font-medium select-none">
          {t('installation.setup.required-fied')}
        </span>
      )}
    </div>
  )
}

//////////////////////
// SWITCH
//////////////////////

const Switch = forwardRef<
  ElementRef<typeof SwitchBase>,
  ComponentPropsWithoutRef<typeof SwitchBase>
>(({ className, ...props }, ref) => (
  <SwitchBase ref={ref} className={cn('data-[state=checked]:bg-accent', className)} {...props} />
))
Switch.displayName = 'Switch'

//////////////////////
// INPUT
//////////////////////

const Input = forwardRef<ElementRef<typeof InputBase>, ComponentPropsWithoutRef<typeof InputBase>>(
  ({ className, ...props }, ref) => (
    <InputBase
      ref={ref}
      className={cn('text-lg px-6 py-8 rounded-2xl bg-zinc-800/90', className)}
      autoComplete="off"
      {...props}
    />
  )
)
Input.displayName = 'Input'

//////////////////////
// SELECT
//////////////////////

const SelectTrigger = forwardRef<
  ElementRef<typeof SelectTriggerBase>,
  ComponentPropsWithoutRef<typeof SelectTriggerBase>
>(({ className, ...props }, ref) => (
  <SelectTriggerBase
    ref={ref}
    className={cn('text-lg px-6 py-8 rounded-2xl text-left bg-zinc-800/90 select-none', className)}
    {...props}
  />
))
SelectTrigger.displayName = 'SelectTrigger'

const SelectContent = forwardRef<
  ElementRef<typeof SelectContentBase>,
  ComponentPropsWithoutRef<typeof SelectContentBase>
>(({ className, ...props }, ref) => (
  <SelectContentBase
    ref={ref}
    className={cn('bg-zinc-900 rounded-2xl select-none', className)}
    {...props}
  />
))
SelectContent.displayName = 'SelectContent'

const SelectItem = forwardRef<
  ElementRef<typeof SelectItemBase>,
  ComponentPropsWithoutRef<typeof SelectItemBase>
>(({ className, ...props }, ref) => (
  <SelectItemBase
    ref={ref}
    className={cn('text-xl rounded-lg select-none', className)}
    {...props}
  />
))
SelectItem.displayName = 'SelectItem'

// RADIO GROUP

const RadioGroupCardItem = forwardRef<
  React.ElementRef<typeof RadioGroupCardItemBase>,
  React.ComponentPropsWithoutRef<typeof RadioGroupCardItemBase>
>(({ className, ...props }, ref) => {
  return (
    <RadioGroupCardItemBase
      ref={ref}
      className={cn(
        'flex flex-col justify-center items-center gap-2 w-1/2 h-20 p-4 text-xs font-semibold select-none',
        className
      )}
      {...props}
    ></RadioGroupCardItemBase>
  )
})
RadioGroupCardItem.displayName = 'RadioGroupCardItem'

export {
  // Form
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  // Label
  LabelWithValidation,
  // Switch
  Switch,
  // Input
  Input,
  // Select
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem,
  // Radio Group
  RadioGroup,
  RadioGroupCardItem
}

import { DismissableMachineAlarm } from '@uv/machine'
import i18n from '@/lib/i18n/config'

export const formatAlarmName = (alarm: DismissableMachineAlarm) => {
  switch (alarm) {
    case 'UV_HOT': {
      return i18n.t('installation.alert-alarms.uv-hot')
    }
    case 'AIR_HOT': {
      return i18n.t('installation.alert-alarms.air-hot')
    }
    case 'MOTOR': {
      return i18n.t('installation.alert-alarms.motor')
    }
    case 'AIRSUPPLY': {
      return i18n.t('translation:installation.alert-alarms.airsupply')
    }
    case 'HEAD': {
      return i18n.t('translation:installation.alert-alarms.head')
    }
    case 'PRESSURE': {
      return i18n.t('translation:installation.alert-alarms.pressure')
    }
    case 'SLIP': {
      return i18n.t('translation:installation.alert-alarms.slip')
    }
    case 'SENSOR': {
      return i18n.t('translation:installation.alert-alarms.sensor')
    }
    case 'WIFI': {
      return i18n.t('translation:installation.alert-alarms.wifi')
    }
  }
}

export const formatAlarmTranslationBaseKey = (alarm: DismissableMachineAlarm) => {
  switch (alarm) {
    case 'UV_HOT': {
      return 'uv-hot'
    }
    case 'AIR_HOT': {
      return 'air-hot'
    }
    case 'MOTOR': {
      return 'motor'
    }
    case 'AIRSUPPLY': {
      return 'airsupply'
    }
    case 'HEAD': {
      return 'head'
    }
    case 'PRESSURE': {
      return 'pressure'
    }
    case 'SLIP': {
      return 'slip'
    }
    case 'SENSOR': {
      return 'sensor'
    }
    case 'WIFI': {
      return 'wifi'
    }
  }
}

import { useDataStoreLocalStorage } from '@/lib/machine/use-data-store-local-storage'
import { Button } from '@ui/components/ui/button'
import { cn } from '@ui/utils'
import { ArrowLeftFromLineIcon, ArrowRightFromLineIcon } from 'lucide-react'

export const InvertInstallationUiButton = () => {
  const settings = useDataStoreLocalStorage(s => s.settings)
  const setSettingsPartially = useDataStoreLocalStorage(s => s.setSettingsPartially)

  return (
    <Button
      className={cn(
        'absolute bottom-0',
        settings.flipUI ? 'right-0' : 'left-0',
        'h-12 w-12 bg-zinc-700 text-foreground rounded-2xl p-4'
      )}
      onClick={() => setSettingsPartially({ flipUI: !settings.flipUI })}
    >
      {settings.flipUI ? <ArrowLeftFromLineIcon /> : <ArrowRightFromLineIcon />}
    </Button>
  )
}

import { useState, useEffect } from 'react'
import { useLongPress } from '@uidotdev/usehooks'
import { useDataStore } from '@/lib/machine/use-data-store'

// TODO: add support for timezone in settings
export const ClockStatus = () => {
  const devMode = useDataStore(s => s.devMode)
  const setDevMode = useDataStore(s => s.setDevMode)

  const onLongPress = () => setDevMode(!devMode)
  const longPressProps = useLongPress(onLongPress, {
    threshold: 2000
  })

  const getTimeInCET = () => {
    const date = new Date()
    const dateInCET = new Date(date.toLocaleString('en-US', { timeZone: 'CET' }))
    return `${dateInCET.getHours().toString().padStart(2, '0')}:${dateInCET.getMinutes().toString().padStart(2, '0')}`
  }

  const [time, setTime] = useState(getTimeInCET())

  // Function to update time
  const updateTime = () => {
    setTime(getTimeInCET())
  }

  useEffect(() => {
    const timer = setInterval(updateTime, 60000)
    return function cleanup() {
      clearInterval(timer)
    }
  }, [])

  return (
    <section className="select-none" {...longPressProps}>
      <time dateTime={time} className="text-lg">
        {time}
      </time>
    </section>
  )
}

export default ClockStatus

import { MachineOutMessage } from '@uv/machine'
import { handleFileMetaInstallation } from '@/lib/machine/sync/installations/handle-file-meta-installation'
import { handleFileReadInstallation } from '@/lib/machine/sync/installations/handle-file-read-installation'
import { handleFileRemoveInstallation } from '@/lib/machine/sync/installations/handle-file-remove-installation'
import { handleFileMetaVideo } from '@/lib/machine/sync/videos/handle-file-meta-video'
import { handleFileReadVideo } from '@/lib/machine/sync/videos/handle-file-read-video'
import { handleFileRemoveVideo } from '@/lib/machine/sync/videos/handle-file-remove-video'
import { handleFileListInstallations } from '@/lib/machine/sync/installations/handle-file-list-installations'
import { handleFileListVideos } from '@/lib/machine/sync/videos/handle-file-list-videos'

export const handleIncomingMachineSyncFilesMessage = async (message: MachineOutMessage) => {
  // const sendMachineSyncFilesAction = useDataStore.getState().sendMachineSyncFilesAction

  switch (message.out) {
    case 'file_list': {
      // // DEV MODE: Remove all files from machine
      // message.files.forEach(f => {
      //   return sendMachineSyncFilesAction({
      //     in: 'file_remove',
      //     file: f
      //   })
      // })
      // return

      await handleFileListInstallations({
        machineId: message.id,
        files: message.files
      })

      await handleFileListVideos({
        machineId: message.id,
        files: message.files
      })

      return
    }

    case 'file_meta': {
      await handleFileMetaInstallation({
        file: message.file,
        meta: message.payload
      })

      await handleFileMetaVideo({
        machineId: message.id,
        file: message.file,
        meta: message.payload
      })

      return
    }

    case 'file_read': {
      await handleFileReadInstallation({
        file: message.file,
        fileContents: message.payload,
        offset: message.offset
      })

      await handleFileReadVideo({
        file: message.file,
        fileContents: message.payload,
        offset: message.offset
      })

      return
    }

    case 'file_remove': {
      await handleFileRemoveInstallation(message.file)
      await handleFileRemoveVideo(message.file)

      return
    }

    default: {
      return
    }
  }
}

import { InstallationQrCode, generateId } from '@uv/machine'
import { useEffect, useState } from 'react'
import { InstallationSetupInput } from './1-setup'
import { t } from 'i18next'
import { ScanIcon, QrCodeIcon, SlidersHorizontalIcon } from 'lucide-react'
import { QrScanner } from './qr-scanner'
import { DashboardCard } from '@/components/ui/card-dashboard'
import {
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem
} from './setup-form-components'
import { useDataStoreLocalStorage } from '@/lib/machine/use-data-store-local-storage'
import { InstallationButton } from '@/components/ui/installation-button'
import { useCloudSyncState } from '@/lib/cloud/use-cloud-sync-state'

type Props = {
  completePreSetup: (input: Partial<InstallationSetupInput> | null) => void
}

export const PreSetup = ({ completePreSetup }: Props) => {
  const installationOptions = useDataStoreLocalStorage(s => s.installationOptions)
  const [showQrScanner, setShowQrScanner] = useState(false)
  const closeQrScanner = () => setShowQrScanner(false)
  const cloudSyncState = useCloudSyncState()

  useEffect(() => {
    cloudSyncState()
  }, [])

  const onQrScan = (data: InstallationQrCode) => {
    setShowQrScanner(false)

    completePreSetup({
      ...data,
      id: generateId('installation'),
      name: data.installationName,
      // TODO: id?
      // preparedInstallationId: data.id,
      distance: data.distance ? data.distance.toString() : ''
    })
  }

  const onPreparedInstallationSelect = (preparedInstallationId: string) => {
    const preparedInstallation = installationOptions.preparedInstallations.find(
      i => i.id === preparedInstallationId
    )
    if (!preparedInstallation) return

    completePreSetup({
      ...preparedInstallation.installationInfo,
      id: generateId('installation'),
      name: preparedInstallation.name,
      preparedInstallationId: preparedInstallation.id,
      distance: preparedInstallation.installationInfo.distance
        ? preparedInstallation.installationInfo.distance.toString()
        : ''
    })
  }

  return (
    <section className="m-8 flex flex-col justify-center">
      {showQrScanner && <QrScanner onScan={onQrScan} closeDialog={closeQrScanner} />}
      <DashboardCard className="grid grid-cols-3 gap-8 items-start w-full p-8">
        <div className="col-span-3 text-center text-3xl font-bold">
          {t('installation.pre-setup.pick-label')}
        </div>

        <DashboardCard className="flex flex-col justify-center gap-6 bg-zinc-800/30 h-full">
          <div className="flex flex-col gap-4">
            {/* <Label className="text-xl font-semibold select-none text-center"> */}
            {/*   Pre-configured Installation */}
            {/* </Label> */}
            <Select onValueChange={onPreparedInstallationSelect}>
              <SelectTrigger>
                <SelectValue
                  placeholder={t('installation.pre-setup.select-installation-placeholder')}
                />
              </SelectTrigger>
              <SelectContent>
                {installationOptions.preparedInstallations.length > 0 ? (
                  installationOptions.preparedInstallations.map(({ id, name }) => {
                    return (
                      <SelectItem key={id} value={id}>
                        {name}
                      </SelectItem>
                    )
                  })
                ) : (
                  <SelectItem value="no-value" disabled>
                    {t('installation.pre-setup.select-installation-empty')}
                  </SelectItem>
                )}
              </SelectContent>
            </Select>

            {/* <span className="text-[16px] font-semibold text-center">Prepared Installation</span> */}
          </div>
        </DashboardCard>

        <InstallationButton
          onClick={() => setShowQrScanner(true)}
          className="flex flex-col items-center justify-center gap-4 h-auto aspect-square bg-zinc-800 hover:bg-zins-800 text-foreground hover:text-foreground rounded-3xl"
        >
          <div className="relative flex flex-col justify-center items-center">
            <ScanIcon className="absolute w-[4.5rem] h-[4.5rem]" strokeWidth="1" />
            <QrCodeIcon className="w-12 h-12 p-1" />
          </div>

          <span className="text-[16px] font-semibold">
            {t('installation.pre-setup.scan-qr-label')}
          </span>
        </InstallationButton>

        <InstallationButton
          onClick={() => completePreSetup(null)}
          className="flex flex-col items-center justify-center gap-4 h-auto aspect-square bg-zinc-800 hover:bg-zins-800 text-foreground hover:text-foreground rounded-3xl"
        >
          <SlidersHorizontalIcon className="w-10 h-12" />
          <span className="text-[16px] font-semibold">
            {t('installation.pre-setup.manual-setup-label')}
          </span>
        </InstallationButton>
      </DashboardCard>
    </section>
  )
}

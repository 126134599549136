import { CloudSyncFilesOutput } from '@uv/machine'
import { useDataStore } from '../use-data-store'
import { handleFileRemoveInstallation } from './installations/handle-file-remove-installation'
import { handleUploadAndDeleteInstallationFile } from './installations/handle-upload-and-delete-installation-file'
import { FILE_READ_CHUNK_SIZE } from './process-file-chunk'
import { handleFileRemoveVideo } from './videos/handle-file-remove-video'
import { handleUploadAndDeleteVideoFile } from './videos/handle-upload-and-delete-video-file'

export const handleFileSyncActions = async ({
  filesWithSyncActions,
  type
}: {
  filesWithSyncActions: CloudSyncFilesOutput
  type: 'installation' | 'video'
}) => {
  const sendMachineSyncFilesAction = useDataStore.getState().sendMachineSyncFilesAction

  for (const file of filesWithSyncActions) {
    switch (file.action) {
      case 'FILE_META': {
        sendMachineSyncFilesAction({
          in: 'file_meta',
          file: file.fileName
        })
        break
      }
      case 'FILE_READ': {
        sendMachineSyncFilesAction({
          in: 'file_read',
          file: file.fileName,
          offset: 0,
          count: FILE_READ_CHUNK_SIZE
        })
        break
      }
      case 'FILE_REMOVE': {
        type === 'video'
          ? await handleFileRemoveVideo(file.fileName)
          : await handleFileRemoveInstallation(file.fileName)

        sendMachineSyncFilesAction({
          in: 'file_remove',
          file: file.fileName
        })
        break
      }
      case 'UPLOAD': {
        type === 'video'
          ? await handleUploadAndDeleteVideoFile({
              fileToUpload: file
            })
          : await handleUploadAndDeleteInstallationFile({
              installationToUpload: file
            })

        // TODO: delete when we verify we don't need this
        // if (isUploadCloudFileOutput(file)) {
        //   await handleUploadAndDeleteVideoFile({
        //     fileToUpload: file
        //   })
        // }
        break
      }
    }
  }
}

import { cn } from '@ui/utils'
import { useRef, useState } from 'react'
import { CountdownCircleTimer } from 'react-countdown-circle-timer'

// https://github.com/vydimitrov/react-countdown-circle-timer
// https://codesandbox.io/s/silly-night-d3s70?fontsize=14&hidenavigation=1&theme=dark
const renderTime = ({ remainingTime }: { remainingTime: number }) => {
  const currentTime = useRef(remainingTime)
  const prevTime = useRef<number | null>(null)
  const isNewTimeFirstTick = useRef(false)
  const [, setOneLastRerender] = useState(0)

  if (currentTime.current !== remainingTime) {
    isNewTimeFirstTick.current = true
    prevTime.current = currentTime.current
    currentTime.current = remainingTime
  } else {
    isNewTimeFirstTick.current = false
  }

  // force one last re-render when the time is over to trigger the last animation
  if (remainingTime === 0) {
    setTimeout(() => {
      setOneLastRerender(val => val + 1)
    }, 20)
  }

  const isTimeUp = isNewTimeFirstTick.current

  return (
    <div className="relative w-4 h-4 text-xs">
      <div
        key={remainingTime}
        className={cn(
          'absolute inset-0 flex justify-center items-center transform transition duration-200',
          isTimeUp ? 'opacity-0 translate-y-full' : 'opacity-100 translate-y-0'
        )}
      >
        {remainingTime}
      </div>
      {prevTime.current !== null && (
        <div
          key={prevTime.current}
          className={cn(
            'absolute inset-0 flex justify-center items-center transform transition duration-200',
            !isTimeUp ? 'opacity-0 -translate-y-full' : 'opacity-100 translate-y-0'
          )}
        >
          {prevTime.current}
        </div>
      )}
    </div>
  )
}

type Props = {
  duration: number
}

export const PullerCountdown = ({ duration }: Props) => {
  return (
    <div className="flex justify-center">
      <CountdownCircleTimer
        isPlaying
        duration={duration}
        size={32}
        strokeWidth={3}
        colors="#fafafa"
        trailColor="#000"
      >
        {renderTime}
      </CountdownCircleTimer>
    </div>
  )
}

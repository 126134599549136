import { useDataStore } from '@/lib/machine/use-data-store'
import { useDataStoreLocalStorage } from '@/lib/machine/use-data-store-local-storage'
import { Button } from '@ui/components/ui/button'
import { cn } from '@ui/utils'
import { ComponentPropsWithoutRef } from 'react'
import { useTranslation } from 'react-i18next'

type TabButtonProps = React.ComponentProps<typeof Button> & {
  active: boolean
}

const TabButton = ({ active, className, ...props }: TabButtonProps) => {
  return (
    <Button
      className={cn(
        'w-1/2 text-base py-6 px-4 flex justify-center items-center gap-2 text-foreground rounded-lg active:bg-transparent',
        active ? 'bg-zinc-700' : 'bg-transparent',
        className
      )}
      {...props}
    />
  )
}

type Props = ComponentPropsWithoutRef<'div'>

export const StageSwitcher = ({ className, ...props }: Props) => {
  const { t } = useTranslation()
  const activeInstallationStage = useDataStoreLocalStorage(s => s.activeInstallationStage)
  const sendMachineAction = useDataStore(s => s.sendMachineAction)
  const activeInstallationFileMeta = useDataStoreLocalStorage(s => s.activeInstallationFileMeta)
  const isLinerInstallation =
    activeInstallationFileMeta?.installationInfo.installationType === 'LINER'

  return (
    <div
      className={cn(
        'flex text-2xl gap-2 items-baseline bg-zinc-900/70 p-2 rounded-xl select-none',
        className
      )}
      {...props}
    >
      <TabButton
        active={activeInstallationStage === 'inversion'}
        onClick={() => sendMachineAction({ in: 'inversion_stage' })}
      >
        {isLinerInstallation ? t('installation.tab-inversion') : t('installation.tab-installation')}
      </TabButton>

      <TabButton
        active={activeInstallationStage === 'curing'}
        onClick={() => sendMachineAction({ in: 'curing_stage' })}
      >
        {t('installation.tab-curing')}
      </TabButton>
    </div>
  )
}

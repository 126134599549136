import { UnitDistance, UnitPressure, UnitSpeed, UnitTemperature } from '@/lib/units'
import i18n from '@/lib/i18n/config'

export const translateDistanceUnitName = (unit: UnitDistance) => {
  switch (unit) {
    case 'm': {
      return i18n.t('units.distance.m')
    }
    case 'ft': {
      return i18n.t('units.distance.ft')
    }
  }
}

export const translateSpeedUnitName = (unit: UnitSpeed) => {
  switch (unit) {
    case 'm/h': {
      return i18n.t('units.speed.m/h')
    }
    case 'm/m': {
      return i18n.t('units.speed.m/m')
    }
    case 'f/h': {
      return i18n.t('units.speed.f/h')
    }
    case 'f/m': {
      return i18n.t('units.speed.f/m')
    }
  }
}

export const translateTemperatureUnitName = (unit: UnitTemperature) => {
  switch (unit) {
    case 'c': {
      return i18n.t('units.temperature.c')
    }
    case 'f': {
      return i18n.t('units.temperature.f')
    }
  }
}

export const translatePressureUnitName = (unit: UnitPressure) => {
  switch (unit) {
    case 'bar': {
      return i18n.t('units.pressure.bar')
    }
    case 'psi': {
      return i18n.t('units.pressure.psi')
    }
  }
}

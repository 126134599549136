import { ActiveInstallationRefreshMetrics, getDefaultInstallationRefreshMetrics } from '@uv/machine'

const SLOW_DELTA_THRESHOLD_MS = 300
const SLOW_DELTA_TRACKING_PERIOD_MS = 5000

export const calculateRefreshMetrics = (
  prev?: ActiveInstallationRefreshMetrics
): ActiveInstallationRefreshMetrics => {
  if (!prev) return getDefaultInstallationRefreshMetrics()

  const time = Date.now()
  const delta = prev.prevTime === 0 ? 0 : time - prev.prevTime
  const slowTimes = prev.slowTimes.filter(t => time - t < SLOW_DELTA_TRACKING_PERIOD_MS)
  if (delta > SLOW_DELTA_THRESHOLD_MS) slowTimes.push(time)

  return {
    prevTime: time,
    delta,
    peak: Math.max(prev.peak, delta),
    count: prev.count + 1,
    slowTimes
  }
}

import { DashboardCard, DashboardCardTitle } from '@/components/ui/card-dashboard'
import LineChart from '@ui/components/chart'
import { cn } from '@ui/utils'

type DataPoint = {
  timestamp: Date
  value: number
}

type Props = React.ComponentProps<typeof DashboardCard> & {
  label: string
  chartData: DataPoint[]
  width?: 'full' | 'half' | 'third'
  height?: number
}

export const ChartCard = ({
  label,
  chartData,
  width = 'full',
  height = 100,
  className,
  ...props
}: Props) => {
  return (
    <DashboardCard className={cn('p-0 bg-zinc-800/70', className)} {...props}>
      <div
        className={cn('p-8', width === 'full' ? 'w-full' : width === 'half' ? 'w-1/2' : 'w-1/3')}
      >
        <DashboardCardTitle className="mb-2 text-3xl">{label}</DashboardCardTitle>
        {/* <span className="text-4xl font-bold text-accent">{value}</span> */}
      </div>
      <LineChart
        data={chartData}
        width={width === 'full' ? 622 : width === 'half' ? 295 : 130}
        height={height}
      />
    </DashboardCard>
  )
}

import { useDataStore } from '@/lib/machine/use-data-store'
import { machineAlarms } from '@uv/machine'

export const SystemOverrideLayout = () => {
  const alarmOverrides = useDataStore(s => s.machineConfiguration?.overrides)
  if (!alarmOverrides || !alarmOverrides.includes(true)) return null

  const overrides = alarmOverrides
    .map((override, i) => (override ? machineAlarms[i] : undefined))
    .filter(Boolean)

  return (
    <div className="fixed top-0 left-0 h-full w-full border-8 border-red-500 pointer-events-none z-10">
      <div className="mt-2 ml-2">
        <span className="text-2xl text-red-500 font-bold">SERVICE MODE: </span>
        <span className="text-2xl text-red-500 font-bold">{overrides.join(', ')}</span>
      </div>
    </div>
  )
}

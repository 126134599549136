import { clamp } from '@/lib/utils'
import { cn } from '@ui/utils'

type PolarCoordinates = {
  x: number
  y: number
}

const polarToCartesian = (
  centerX: number,
  centerY: number,
  radius: number,
  angleInDegrees: number
): PolarCoordinates => {
  const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0

  return {
    x: centerX + radius * Math.cos(angleInRadians),
    y: centerY + radius * Math.sin(angleInRadians)
  }
}

const describeArc = (
  x: number,
  y: number,
  radius: number,
  startAngle: number,
  endAngle: number
): string => {
  if (endAngle - startAngle === 360) {
    const halfway = (startAngle + endAngle) / 2
    const firstHalf = describeArc(x, y, radius, startAngle, halfway)
    const secondHalf = describeArc(x, y, radius, halfway, endAngle)
    // Since we used the moveto command M for both halves, there is an
    // unnecessary "line" between them, so we replace the second M with
    // a lineto command L to make it a continuous path.
    return firstHalf + ' ' + secondHalf.replace(/^M/, 'L')
  }

  const start = polarToCartesian(x, y, radius, endAngle)
  const end = polarToCartesian(x, y, radius, startAngle)

  const largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1'

  const d = ['M', start.x, start.y, 'A', radius, radius, 0, largeArcFlag, 0, end.x, end.y].join(' ')

  return d
}

type Props = React.ComponentProps<'svg'> & {
  percentage: number
  arcRange: number
  strokeWidth?: number
  gradientStops?: { offset: string; stopColor: string }[]
}

export const Gauge = ({
  percentage,
  arcRange = 250,
  strokeWidth = 4,
  gradientStops,
  className,
  ...props
}: Props) => {
  const degreeOffset = 0 //offset to put gauge in correct orientation
  const angleCenter = 0 - degreeOffset
  const angleFrom = angleCenter - arcRange / 2

  const radius = 50 - strokeWidth / 2 // We adjust radius to fit strokeWidth into viewBox
  const percentageClamped = clamp(percentage, 0, 100)

  // Calculate paths
  const activePath = describeArc(
    50,
    50,
    radius,
    angleFrom,
    angleFrom + arcRange * Math.abs(percentageClamped / 100)
  )
  // The inactive path always goes from 0 to 100%
  const inactivePath = describeArc(50, 50, radius, angleFrom, angleFrom + arcRange)

  // Calculate the viewboxHeight.
  // This is not precise, but good enough for now
  const extraHeight = 10 // Extra height to make sure the gauge is always visible
  const viewBoxHeight = clamp((arcRange / 360) * 100 + extraHeight, 0, 100)

  const gradientId = gradientStops
    ? gradientStops.map(g => g.offset + g.stopColor).join('')
    : 'gauge-gradient'

  return (
    <svg
      viewBox={`0 0 100 ${viewBoxHeight}`}
      className={cn('w-20', className)}
      strokeLinecap="round"
      {...props}
    >
      <defs>
        <linearGradient
          id={gradientId}
          gradientUnits="userSpaceOnUse"
          x1="0"
          y1="50"
          x2="100"
          y2="50"
        >
          {gradientStops && gradientStops.length > 0 ? (
            <>
              {gradientStops.map(({ offset, stopColor }, index) => {
                return <stop key={index} offset={offset} stopColor={stopColor} />
              })}
            </>
          ) : (
            <>
              <stop offset="0%" stopColor="#540aff" />
              <stop offset="30%" stopColor="#ce0aff" />
            </>
          )}
        </linearGradient>
      </defs>

      {/* Inactive path is always full */}
      <path d={inactivePath} stroke="black" strokeWidth={strokeWidth} fill="none" />

      {/* Active path is calculated based on percentage. 0 value still shows a dot, so we only show it when there is value */}
      {percentageClamped > 0 && (
        <path d={activePath} stroke={`url(#${gradientId})`} strokeWidth={strokeWidth} fill="none" />
      )}
    </svg>
  )
}

import { ComponentPropsWithoutRef } from 'react'

type Props = ComponentPropsWithoutRef<'svg'>

export const Pentagon = ({ children, ...props }: Props) => {
  return (
    <svg viewBox="0 0 90 88" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M36.1832 3.40576C41.4405 -0.413901 48.5595 -0.413896 53.8168 3.40576L83.7361 25.1434C88.9934 28.9631 91.1932 35.7336 89.1851 41.9139L77.757 77.0861C75.7489 83.2664 69.9895 87.4509 63.4911 87.4509H26.5089C20.0105 87.4509 14.2511 83.2664 12.243 77.0861L0.814881 41.9139C-1.19323 35.7335 1.00664 28.963 6.26395 25.1434L36.1832 3.40576Z" />
    </svg>
  )
}

import { Locale } from '@/lib/machine/use-data-store-local-storage'
import i18n from '@/lib/i18n/config'

export const translateLanguageName = (language: Locale) => {
  switch (language) {
    case 'en': {
      return i18n.t('languages.en')
    }
    case 'es': {
      return i18n.t('languages.es')
    }
  }
}

import { InstallationInDb, db, VideoInDb } from './db'
import {
  InstallationFile,
  getInstallationIdFromFileName,
  getInstallationTimestampFromFileName
} from '@uv/machine'
import { IndexableTypeArray } from 'dexie'
import { mightFail } from 'might-fail'

///////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////
// INSTALLATION
///////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////

// -------------------------------------------------
// QUERIES
// -------------------------------------------------

export const dbGetInstallation = async (id: InstallationInDb['id']) => {
  const { error, result } = await mightFail(db.installation.get({ id }))
  if (error) return null
  return result ?? null
}

export const dbGetInstallationByFileName = async (fileName: InstallationInDb['fileName']) => {
  const { error, result } = await mightFail(db.installation.get({ fileName }))
  if (error) return null
  return result ?? null
}

export const dbGetAllInstallations = async () => {
  const { error, result } = await mightFail(db.installation.toArray())
  if (error) {
    // TODO: log error somewhere
    return []
  }
  return result
}

export const dbGetAllInstallationFileNames = async (machineId: InstallationInDb['machineId']) => {
  const { error, result } = await mightFail(
    db.installation
      .orderBy('fileName')
      .and(installation => installation.machineId === machineId)
      .keys()
  )

  if (error) {
    // TODO: log error somewhere
    return [] as IndexableTypeArray
  }

  return result
}

export const dbGetAllInstallationsByMachineId = async (
  machineId: InstallationInDb['machineId']
) => {
  const { error, result } = await mightFail(db.installation.where({ machineId }).toArray())
  if (error) {
    // TODO: log error somewhere
    return []
  }
  return result
}

export const dbGetAllInstallationsFileNamesWithoutData = async (
  machineId: InstallationInDb['machineId']
) => {
  const { error, result } = await mightFail(
    db.installation
      .where({ machineId, hasFile: 0 })
      .toArray(installations => installations.map(installation => installation.fileName))
  )
  if (error) {
    // TODO: log error somewhere
    return []
  }
  return result
}

export const dbGetAllInstallationsFileNamesToDeleteFromMachine = async (
  machineId: InstallationInDb['machineId']
) => {
  const { error, result } = await mightFail(
    db.installation
      .where({ machineId, shouldDelete: 1 })
      .toArray(installations => installations.map(installation => installation.fileName))
  )
  if (error) {
    // TODO: log error somewhere
    return []
  }
  return result
}

// -------------------------------------------------
// MUTATIONS
// -------------------------------------------------

export const dbCreateInstallationFile = async ({
  fileName,
  fileMeta,
  file
}: {
  fileName: InstallationInDb['fileName']
  fileMeta: InstallationInDb['meta']
  file?: InstallationInDb['file']
}) => {
  const installationId = getInstallationIdFromFileName(fileName)
  const installationTimestamp = getInstallationTimestampFromFileName(fileName)
  if (!installationId) {
    console.error('Error while dbCreateFile: no installationId')
    return
  }

  if (!installationTimestamp) {
    console.error('Error while dbCreateFile: no installationTimestamp')
    return
  }

  const { error } = await mightFail(
    db.installation.add({
      id: installationId,
      machineId: fileMeta.machineId,
      installationTimestamp,
      fileName,
      meta: fileMeta,
      hasFile: file === undefined ? 0 : 1,
      file,
      shouldDelete: 0
    })
  )

  if (error) {
    // TODO: log error somewhere
    console.error('Error while dbCreateFile', error)
    return false
  }

  return true
}

export const dbGetOrCreateInstallation = async ({
  fileName,
  fileMeta
}: {
  fileName: InstallationInDb['fileName']
  fileMeta: InstallationInDb['meta']
}) => {
  const installationId = getInstallationIdFromFileName(fileName)
  const installationTimestamp = getInstallationTimestampFromFileName(fileName)
  if (!installationId) {
    console.error('Error while dbCreateFile: no installationId')
    return
  }

  if (!installationTimestamp) {
    console.error('Error while dbCreateFile: no installationTimestamp')
    return
  }

  const existingInstallation = await dbGetInstallationByFileName(fileName)
  if (existingInstallation) return existingInstallation

  await dbCreateInstallationFile({
    fileName,
    fileMeta
  })

  // Dexie doesn't return the updated object, so we need to get it again
  const updatedInstallation = await dbGetInstallationByFileName(fileName)
  if (!updatedInstallation) {
    console.error('Error while getting updated installation')
  }

  return updatedInstallation
}

export const dbGetCreateOrUpdateInstallation = async ({
  fileName,
  fileContents
}: {
  fileName: InstallationInDb['fileName']
  fileContents: InstallationFile
}) => {
  const installationId = getInstallationIdFromFileName(fileName)
  const installationTimestamp = getInstallationTimestampFromFileName(fileName)
  if (!installationId) {
    console.error('Error while dbCreateFile: no installationId')
    return
  }

  if (!installationTimestamp) {
    console.error('Error while dbCreateFile: no installationTimestamp')
    return
  }

  const jsonString = JSON.stringify(fileContents)
  const encoder = new TextEncoder()
  const arrayBuffer = encoder.encode(jsonString).buffer

  const existingInstallation = await dbGetInstallationByFileName(fileName)

  if (!existingInstallation) {
    await dbCreateInstallationFile({
      fileName,
      fileMeta: fileContents.fileMeta,
      file: arrayBuffer
    })
  } else if (existingInstallation.hasFile === 0) {
    await dbUpdateInstallationFile({
      id: existingInstallation.id,
      file: arrayBuffer
    })
  } else {
    return existingInstallation
  }

  // Dexie doesn't return the updated object, so we need to get it again
  const updatedInstallation = await dbGetInstallationByFileName(fileName)
  if (!updatedInstallation) {
    console.error('Error while getting updated installation')
  }

  return updatedInstallation
}

export const dbUpdateInstallationFile = async ({
  id,
  file
}: {
  id: InstallationInDb['id']
  file: InstallationInDb['file']
}) => {
  const installation = await dbGetInstallation(id)
  if (!installation) return false

  const { error } = await mightFail(
    db.installation.update(id, {
      hasFile: 1,
      file
    })
  )

  if (error) {
    // TODO: log error somewhere
    return false
  }

  return true
}

export const dbDeleteInstallation = async ({ id }: { id: InstallationInDb['id'] }) => {
  const { error } = await mightFail(db.installation.delete(id))

  if (error) {
    // TODO: log error somewhere
    console.error('Error while dbDeleteInstallation', error)
    return false
  }

  return true
}

export const dbMarkInstallationForDeletion = async (id: string) => {
  return await db.installation.update(id, {
    shouldDelete: 1
  })
}

///////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////
// VIDEO
///////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////

// -------------------------------------------------
// QUERIES
// -------------------------------------------------

export const dbGetVideo = async (fileName: VideoInDb['fileName']) => {
  const { error, result } = await mightFail(db.video.get(fileName))
  if (error) return null
  return result ?? null
}

export const dbGetAllVideosByMachineId = async (machineId: VideoInDb['machineId']) => {
  const { error, result } = await mightFail(db.video.where({ machineId }).toArray())
  if (error) {
    // TODO: log error somewhere
    return []
  }
  return result
}

export const dbGetAllVideos = async () => {
  const { error, result } = await mightFail(db.video.toArray())
  if (error) {
    // TODO: log error somewhere
    return []
  }
  return result
}

// -------------------------------------------------
// MUTATIONS
// -------------------------------------------------
export const dbCreateVideoFile = async ({
  machineId,
  fileName,
  duration,
  file
}: {
  machineId: VideoInDb['machineId']
  fileName: VideoInDb['fileName']
  duration: VideoInDb['duration']
  file?: VideoInDb['file']
}) => {
  const installationId = getInstallationIdFromFileName(fileName)
  const timestamp = getInstallationTimestampFromFileName(fileName)
  if (!installationId) {
    console.error('Error while dbCreateVideoFile: no installationId')
    return
  }

  if (!timestamp) {
    console.error('Error while dbCreateVideoFile: no installationTimestamp')
    return
  }

  const { error } = await mightFail(
    db.video.add({
      installationId,
      machineId,
      timestamp,
      fileName,
      duration,
      hasFile: file === undefined ? 0 : 1,
      file,
      shouldDelete: 0
    })
  )

  if (error) {
    // TODO: log error somewhere
    console.error('Error while dbCreateVideoFile', error)
    return false
  }

  return true
}

export const dbGetOrCreateVideo = async ({
  machineId,
  fileName,
  duration,
  file
}: {
  machineId: VideoInDb['machineId']
  fileName: VideoInDb['fileName']
  duration: VideoInDb['duration']
  file?: VideoInDb['file']
}) => {
  const existingVideo = await dbGetVideo(fileName)
  if (existingVideo) return existingVideo

  await dbCreateVideoFile({
    machineId,
    fileName,
    duration,
    file
  })

  // Dexie doesn't return the updated object, so we need to get it again
  const createdVideo = await dbGetVideo(fileName)
  if (!createdVideo) {
    console.error('Error while getting created video')
  }

  return createdVideo
}

export const dbUpdateVideo = async ({
  fileName,
  file,
  hasFile
}: {
  fileName: VideoInDb['fileName']
  file: VideoInDb['file']
  hasFile?: VideoInDb['hasFile']
}) => {
  const video = await dbGetVideo(fileName)
  if (!video) return false

  const { error } = await mightFail(
    db.video.update(fileName, {
      file,
      hasFile
    })
  )

  if (error) {
    // TODO: log error somewhere
    return false
  }

  return true
}

export const dbMarkVideoForDeletion = async (fileName: VideoInDb['fileName']) => {
  return await db.video.update(fileName, {
    shouldDelete: 1
  })
}

export const dbDeleteVideo = async (fileName: VideoInDb['fileName']) => {
  const { error } = await mightFail(db.video.delete(fileName))

  if (error) {
    // TODO: log error somewhere
    console.error('Error while dbDeleteVideo', error)
    return false
  }

  return true
}

///////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////
// WIPE AND RESET DATABASE
///////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////

export const wipeAndResetDatabase = async () => {
  const { error } = await mightFail(
    db.transaction('rw', db.installation, db.video, async () => {
      await db.installation.clear()
      await db.video.clear()
    })
  )

  if (error) {
    console.error('Error while wiping and resetting database', error)
    return false
  }

  console.log('Database contents have been cleared successfully.')
  return true
}

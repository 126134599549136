import { cloudSyncInstallations } from '@/lib/cloud/cloud-sync-installations'
import { MachineOutMessage, isInstallationFileMeta } from '@uv/machine'
import { dbGetOrCreateInstallation } from '@/db/actions'
import { useDataStore } from '../../use-data-store'
import { isOnline } from '@/lib/utils/is-online'
import { FILE_READ_CHUNK_SIZE } from '../process-file-chunk'
import { handleFileSyncActions } from '../handle-file-sync-actions'

export const handleFileMetaInstallation = async ({
  file,
  meta
}: {
  file: string
  meta: Extract<MachineOutMessage, { out: 'file_meta' }>['payload']
}) => {
  const sendMachineSyncFilesAction = useDataStore.getState().sendMachineSyncFilesAction

  if (!file.endsWith('.json') || file === 'active.json') return

  if (!isInstallationFileMeta(meta)) {
    // TODO: log it somewhere
    console.error(`handleFileMetaInstallation error. Invalid meta format: ${meta}`)
    return
  }

  const installationInLocalDb = await dbGetOrCreateInstallation({ fileName: file, fileMeta: meta })
  if (!installationInLocalDb) return

  // -------------------------------------------------
  // 1. Schedule `file_remove` for already synced files
  // -------------------------------------------------
  if (installationInLocalDb?.shouldDelete === 1) {
    return sendMachineSyncFilesAction({
      in: 'file_remove',
      file
    })
  }

  // -------------------------------------------------
  // 2. Sync with Cloud
  // -------------------------------------------------
  const hasInternetConnection = await isOnline()
  if (hasInternetConnection) {
    const installationsWithActions = await cloudSyncInstallations([
      {
        fileName: installationInLocalDb.fileName,
        fileMeta: installationInLocalDb.meta,
        hasFile: Boolean(installationInLocalDb.hasFile)
      }
    ])
    if (installationsWithActions) {
      await handleFileSyncActions({
        type: 'installation',
        filesWithSyncActions: installationsWithActions
      })
      return
    }
  }

  // -------------------------------------------------
  // 3. If no internet connection, send `file_read`
  // -------------------------------------------------
  return sendMachineSyncFilesAction({
    in: 'file_read',
    file,
    offset: 0,
    count: FILE_READ_CHUNK_SIZE
  })
}

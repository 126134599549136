import { base64ToUint8Array } from '@/lib/utils/base64-to-array-buffer'

type FileName = string
type FileInProgress = {
  bytes: Uint8Array
  lastUpdate: number
}

const filesInProgress = new Map<FileName, FileInProgress>()

export const FILE_READ_CHUNK_SIZE = 1024 * 64 // 64 KB // 65 536 bytes
const STALE_DATA_MS = 3000

const mergeUint8Arrays = (originalArray: Uint8Array, newData: Uint8Array) => {
  const newArray = new Uint8Array(originalArray.length + newData.length)
  newArray.set(originalArray)
  newArray.set(newData, originalArray.length)
  return newArray
}

type HandleFileChunkResult =
  | {
      action: 'FILE_COMPLETED'
      file: Uint8Array
    }
  | {
      action: 'REQUEST_NEXT_CHUNK'
      offset: number
      count: number
    }
  | {
      action: 'INVALID_CHUNK'
    }

export const processFileChunk = (
  fileName: FileName,
  chunkData: string,
  offset: number
): HandleFileChunkResult => {
  if (offset % FILE_READ_CHUNK_SIZE !== 0) {
    console.error(
      `Offset must be a multiple of ${FILE_READ_CHUNK_SIZE} for file ${fileName}. Received: ${offset}`
    )
    return { action: 'INVALID_CHUNK' }
  }

  const bytes = base64ToUint8Array(chunkData)
  if (!bytes) {
    console.error(`Failed to decode chunk data for file ${fileName}`)
    return { action: 'INVALID_CHUNK' }
  }

  if (bytes.byteLength > FILE_READ_CHUNK_SIZE) {
    return { action: 'INVALID_CHUNK' }
  }

  const file = filesInProgress.get(fileName)

  if (file && Date.now() - file.lastUpdate > STALE_DATA_MS) {
    filesInProgress.delete(fileName)
    console.warn(`Stale data for file ${fileName}`)
    return { action: 'INVALID_CHUNK' }
  }

  if (file && offset !== file.bytes.byteLength) {
    console.warn(
      `Unexpected offset for file ${fileName}. Expected: ${file.bytes.byteLength}, Received: ${offset}`
    )
    return { action: 'INVALID_CHUNK' }
  }

  if (!file) {
    if (bytes.byteLength < FILE_READ_CHUNK_SIZE) {
      return {
        action: 'FILE_COMPLETED',
        file: bytes
      }
    }

    filesInProgress.set(fileName, {
      bytes,
      lastUpdate: Date.now()
    })

    return {
      action: 'REQUEST_NEXT_CHUNK',
      offset: bytes.byteLength,
      count: FILE_READ_CHUNK_SIZE
    }
  }

  const updatedFile = {
    bytes: mergeUint8Arrays(file.bytes, bytes),
    lastUpdate: Date.now()
  }

  if (bytes.byteLength < FILE_READ_CHUNK_SIZE) {
    filesInProgress.delete(fileName)
    return {
      action: 'FILE_COMPLETED',
      file: updatedFile.bytes
    }
  }

  filesInProgress.set(fileName, updatedFile)
  return {
    action: 'REQUEST_NEXT_CHUNK',
    offset: updatedFile.bytes.byteLength,
    count: FILE_READ_CHUNK_SIZE
  }
}

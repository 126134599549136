import { Either, mightFail } from 'might-fail'
import { logout } from './auth'

interface AuthFetchOptions extends RequestInit {
  timeout?: number // timeout in milliseconds
}

export const fetchWithAuth = async (
  resource: string,
  options: AuthFetchOptions = {}
): Promise<Either<Response>> => {
  const { timeout = 5000, ...fetchOptions } = options

  const { error: networkError, result: response } = await mightFail(
    fetch(resource, {
      ...fetchOptions,
      headers: {
        ...fetchOptions.headers,
        'Content-Type': 'application/json'
      },
      credentials: 'include',
      signal: AbortSignal.timeout(timeout)
    })
  )

  if (networkError) {
    console.log('hey')
    return { error: new Error('Network error: Unable to reach the server.'), result: undefined }
  }

  if (response.status === 401) {
    logout()
    throw new Error('Unauthorized: Please log in again.')
  }

  if (!response.ok) {
    throw new Error(`Server responded with status: ${response.status}`)
  }

  return { result: response, error: undefined }
}

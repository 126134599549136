import { VideoInDb } from '@/db/db'
import { mightFail } from 'might-fail'

export const s3UploadVideoFile = async ({
  presignedUrl,
  file
}: {
  presignedUrl: string
  file: NonNullable<VideoInDb['file']>
}) => {
  // Convert ArrayBuffer to Blob with the correct MIME type for .ts files
  const blob = new Blob([file], { type: 'video/MP2T' })

  const { error: networkError, result: response } = await mightFail(
    fetch(presignedUrl, {
      method: 'PUT',
      body: blob,
      headers: {
        'Content-Type': 'video/MP2T'
      }
    })
  )

  if (networkError) {
    console.error('s3UploadInstallationFile network error', networkError.message)
    return null
  }

  if (!response.ok) {
    console.log('s3UploadInstallationFile Error response from server', response)
    return null
  }

  // S3 doesn't return JSON for a successful upload, so we don't need to parse the response
  // Instead, we can construct the URL from the presigned URL
  const src = presignedUrl.split('?')[0]

  if (!src) {
    console.log('Could not extract result URL for s3UploadInstallationFile', presignedUrl)
    return null
  }

  return src
}

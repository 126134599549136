import {
  Locale,
  locales,
  useDataStoreLocalStorage
} from '@/lib/machine/use-data-store-local-storage'
import { translateLanguageName } from '../installation/utils/translate-language-name'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from '../installation/stages/1-setup/setup-form-components'

export const SettingsLanguage = () => {
  const locale = useDataStoreLocalStorage(s => s.locale)
  const setLocale = useDataStoreLocalStorage(s => s.setLocale)

  return (
    <section>
      <section className="flex gap-12 mb-12">
        <div className="w-1/2 flex flex-col gap-3">
          <Select value={locale} onValueChange={v => setLocale(v as Locale)}>
            <SelectTrigger className="w-full px-6 py-8 text-md rounded-2xl">
              <SelectValue placeholder="Select" />
            </SelectTrigger>
            <SelectContent>
              {locales.map(id => {
                return (
                  <SelectItem key={id} value={id} className="text-md">
                    {translateLanguageName(id)}
                  </SelectItem>
                )
              })}
            </SelectContent>
          </Select>
        </div>
      </section>
    </section>
  )
}

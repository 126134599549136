import { Toggle } from '@ui/components/ui/toggle'
import { cn } from '@ui/utils'

type Props = React.ComponentProps<typeof Toggle>

export const InstallationToggle = ({ className, ...props }: Props) => {
  return (
    <Toggle
      className={cn(
        'p-6 rounded-xl h-auto w-auto text-muted-foreground bg-zinc-700 data-[state=on]:bg-accent-background data-[state=on]:text-accent-foreground hover:bg-zinc-700/30 hover:text-muted-foreground select-none',
        className
      )}
      {...props}
    />
  )
}

import { mightFail } from 'might-fail'
import {
  CloudSyncInstallationsInput,
  CloudSyncFilesOutput,
  CloudSyncFilesOutputSchema
} from '@uv/machine'
import { fetchWithAuth } from '../auth/fetch-with-auth'

const CLOUD_SYNC_API_URL = import.meta.env.VITE_CLOUD_SYNC_API_URL
if (!CLOUD_SYNC_API_URL) throw new Error('CLOUD_SYNC_API_URL is not defined')

export const cloudSyncInstallations = async (
  installations: CloudSyncInstallationsInput
): Promise<CloudSyncFilesOutput | false> => {
  if (installations.length < 1) return []

  const { error, result: response } = await fetchWithAuth(`${CLOUD_SYNC_API_URL}/installations`, {
    method: 'POST',
    body: JSON.stringify(installations),
    timeout: 7000
  })

  if (error || !response || !response.ok) {
    console.error('Cloud sync installations error:', error?.message)
    return false
  }

  const { error: convertToJSONError, result } = await mightFail(response.json())

  if (convertToJSONError) {
    console.log('invalid json', convertToJSONError.message)
    return false
  }

  const parsedResult = CloudSyncFilesOutputSchema.safeParse(result)
  if (!parsedResult.success) {
    console.error(
      `Unexpected API response from 'sync/installations' endpoint. Error Message: ${parsedResult.error.message}`
    )
    return false
  }

  return parsedResult.data
}

import { useDataStore } from '@/lib/machine/use-data-store'
import { WifiIcon, WifiOffIcon } from 'lucide-react'

export const MachineConnectionStatus = () => {
  const isConnected = useDataStore(s => s.isConnected)
  const refreshMetrics = useDataStore(s => s.refreshMetrics)
  const refreshData = useDataStore(s => s.refreshData)
  const devMode = useDataStore(s => s.devMode)

  return (
    <div className="flex items-center gap-1 text-md select-none">
      {isConnected ? (
        <>
          {devMode && (
            <div className="flex gap-2">
              <span className="text-lg">wifi {refreshData?.WIFI}</span>
              <span className="text-lg">delta {refreshMetrics.delta}</span>
            </div>
          )}
          <WifiIcon className="h-5" />
        </>
      ) : (
        <WifiOffIcon className="w-5" />
      )}
    </div>
  )
}

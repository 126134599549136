import { PowerButton } from './power-button'
import { useTranslation } from 'react-i18next'
import { resetLocalData } from '@/lib/reset/reset-local-data'
import { DatabaseBackupIcon, ShieldQuestionIcon } from 'lucide-react'

const MACHINE_CERTIFICATE_URL = import.meta.env.VITE_MACHINE_CERTIFICATE_URL
if (!MACHINE_CERTIFICATE_URL) throw new Error(' MACHINE_CERTIFICATE_URL is not defined')

export const SettingsData = () => {
  const { t } = useTranslation()

  return (
    <section>
      <section className="flex gap-12 mb-12">
        <div className="grid grid-cols-3 gap-4 justify-between">
          <PowerButton
            label={t('settings.data.reset-app-data-button')}
            aria-label="Reset App State"
            onClick={async () => {
              await resetLocalData()
            }}
            className="w-full"
          >
            <DatabaseBackupIcon className="w-8" />
          </PowerButton>

          <PowerButton
            label={t('settings.data.check-certificate-button')}
            aria-label="Check Certificate"
            onClick={() => {
              window.location.href = MACHINE_CERTIFICATE_URL
            }}
            className="w-full"
          >
            <ShieldQuestionIcon className="w-8" />
          </PowerButton>
        </div>
      </section>
    </section>
  )
}

import { useState, useCallback, useEffect } from 'react'
import { useDataStore } from '../machine/use-data-store'

const ONLINE_INTERVAL_MS = 3000
const OFFLINE_INTERVAL_MS = 1000
const TIMEOUT_MS = 2000
const MAX_RETRIES = 3

export const useIsOnlineInterval = () => {
  const isOnline = useDataStore.getState().isOnline
  const setIsOnline = useDataStore.getState().setIsOnline
  const [responseTime, setResponseTime] = useState<number | null>(null)

  const pingCloudflare = useCallback(async (retryCount = 0): Promise<boolean> => {
    const controller = new AbortController()
    const timeoutId = setTimeout(() => controller.abort(), TIMEOUT_MS)

    try {
      const startTime = performance.now()
      await fetch('https://1.1.1.1', {
        method: 'HEAD',
        mode: 'no-cors',
        cache: 'no-store',
        signal: controller.signal
      })
      const endTime = performance.now()
      const latency = Math.round(endTime - startTime)

      clearTimeout(timeoutId)
      setResponseTime(latency)
      return true
    } catch (error) {
      clearTimeout(timeoutId)
      console.error('Error pinging Cloudflare:', error)

      if (retryCount < MAX_RETRIES - 1) {
        console.log(`Retrying... (${retryCount + 1}/${MAX_RETRIES})`)
        return pingCloudflare(retryCount + 1)
      }

      return false
    }
  }, [])

  useEffect(() => {
    let intervalId: NodeJS.Timeout

    const checkStatus = async () => {
      const online = await pingCloudflare()
      setIsOnline(online)

      // Schedule next check
      intervalId = setTimeout(checkStatus, online ? ONLINE_INTERVAL_MS : OFFLINE_INTERVAL_MS)
    }

    checkStatus()

    return () => {
      if (intervalId) clearTimeout(intervalId)
    }
  }, [pingCloudflare])

  return { isOnline, responseTime }
}

import { dbGetAllInstallationsByMachineId } from '@/db/actions'
import { cloudSyncInstallations } from '@/lib/cloud/cloud-sync-installations'
import { useDataStore } from '../../use-data-store'
import { isOnline } from '@/lib/utils/is-online'
import { FILE_READ_CHUNK_SIZE } from '../process-file-chunk'
import { handleFileSyncActions } from '../handle-file-sync-actions'

export const handleFileListInstallations = async ({
  machineId,
  files
}: {
  machineId: string
  files: string[]
}) => {
  const sendMachineSyncFilesAction = useDataStore.getState().sendMachineSyncFilesAction

  const fileNamesInMachine = files.filter(f => f.endsWith('.json') && f !== 'active.json')

  const localDbInstallations = await dbGetAllInstallationsByMachineId(machineId)
  const filesInMachineNotInLocalDb = fileNamesInMachine.filter(
    f => !localDbInstallations.find(i => i.fileName === f)
  )
  const filesInMachineInLocalDb = localDbInstallations.filter(i =>
    fileNamesInMachine.includes(i.fileName)
  )

  // -------------------------------------------------
  // 1. Schedule file_remove for already synced files
  // -------------------------------------------------
  filesInMachineInLocalDb
    .filter(i => i.shouldDelete === 1)
    .forEach(i => {
      return sendMachineSyncFilesAction({
        in: 'file_remove',
        file: i.fileName
      })
    })

  const filesInMachineInLocalDbNotUploaded = filesInMachineInLocalDb.filter(
    i => i.shouldDelete === 0
  )

  // -------------------------------------------------
  // 2. Sync with Cloud
  // -------------------------------------------------
  const hasInternetConnection = await isOnline()
  if (hasInternetConnection) {
    const installationsToSync = [
      ...filesInMachineNotInLocalDb.map(f => ({
        fileName: f,
        hasFile: false
      })),
      ...filesInMachineInLocalDbNotUploaded.map(i => ({
        fileName: i.fileName,
        fileMeta: i.meta,
        hasFile: Boolean(i.hasFile)
      }))
    ]

    const installationsWithActions = await cloudSyncInstallations(installationsToSync)
    if (installationsWithActions) {
      await handleFileSyncActions({
        type: 'installation',
        filesWithSyncActions: installationsWithActions
      })
      return
    }
  }

  // -------------------------------------------------
  // 3. If no internet connection, or the sync with cloud didn't work
  // send `file_meta` for installations we don't have in local db
  // send `file_read` for installations we don't have the files
  // -------------------------------------------------

  filesInMachineNotInLocalDb.forEach(f => {
    return sendMachineSyncFilesAction({
      in: 'file_meta',
      file: f
    })
  })

  filesInMachineInLocalDbNotUploaded.forEach(f => {
    if (f.hasFile === 1) return
    return sendMachineSyncFilesAction({
      in: 'file_read',
      file: f.fileName,
      offset: 0,
      count: FILE_READ_CHUNK_SIZE
    })
  })
}

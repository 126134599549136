export const base64ToArrayBuffer = (base64: string): ArrayBuffer | null => {
  try {
    // Decode Base64 to binary string
    const binaryString = atob(base64)

    // Convert binary string to Uint8Array
    const len = binaryString.length
    const bytes = new Uint8Array(len)
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i)
    }

    return bytes.buffer
  } catch (error) {
    console.error('Error decoding Base64 string:', error)
    return null
  }
}

export const base64ToUint8Array = (base64: string) => {
  try {
    // Decode Base64 to binary string
    const binaryString = atob(base64)

    // Convert binary string to Uint8Array
    const len = binaryString.length
    const bytes = new Uint8Array(len)
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i)
    }

    return bytes
  } catch (error) {
    console.error('Error decoding Base64 string:', error)
    return null
  }
}

import { InstallationStage1Setup } from '@/components/installation/stages/1-setup/1-setup'
import { InstallationStage2Inversion } from '@/components/installation/stages/2-inversion'
import { InstallationStage3Curing } from '@/components/installation/stages/3-curing'
import { InstallationStage4Summary } from '@/components/installation/stages/4-summary'
import { useDataStoreLocalStorage } from '@/lib/machine/use-data-store-local-storage'
// import { useNavigate } from 'react-router'

const RenderInstallationStage = () => {
  // const navigate = useNavigate()
  const activeInstallationStage = useDataStoreLocalStorage(s => s.activeInstallationStage)

  switch (activeInstallationStage) {
    case 'inversion': {
      return <InstallationStage2Inversion />
    }
    case 'curing': {
      return <InstallationStage3Curing />
    }
    case 'summary': {
      // // TODO: decide if we want to redirect them in case of direct navigation
      // if (isDirectNavigation) {
      //   // setStage('1-new-installation')
      //   // navigate('/')
      //   // return null
      //   return <InstallationStage4Summary sendInstallationAction={sendInstallationAction} />
      // }
      return <InstallationStage4Summary />
    }
    default: {
      return <InstallationStage1Setup />
    }
  }
}

// const MockedInstallation = () => {
//   const { sendInstallationAction } = useMockMachineData()
//   const isDirectNavigation = useRef<boolean>(true)
//
//   useEffect(() => {
//     isDirectNavigation.current = false
//   }, [])
//
//   return (
//     <RenderInstallationStage
//       sendInstallationAction={sendInstallationAction}
//       // isDirectNavigation={isDirectNavigation.current}
//     />
//   )
// }
//
// const Installation = () => {
//   const { sendInstallationAction } = useMachineWebsocketData()
//   const isDirectNavigation = useRef<boolean>(true)
//
//   useEffect(() => {
//     isDirectNavigation.current = false
//   }, [])
//
//   return (
//     <RenderInstallationStage
//       sendInstallationAction={sendInstallationAction}
//       // isDirectNavigation={isDirectNavigation.current}
//     />
//   )
// }

export const InstallationPage = () => {
  return <RenderInstallationStage />
}

import { Pentagon } from '@uv/ui/components/pentagon'
import { HomeIcon, BookOpenIcon } from '@heroicons/react/24/solid'
import { LogoSimple } from '@uv/ui/components/logo'
import { Link, useLocation } from 'react-router-dom'
import { PlusIcon } from 'lucide-react'
import { cn } from '@ui/utils'
import { ComponentPropsWithoutRef } from 'react'

type NavIconProps = {
  active?: boolean
  children: React.ReactNode
}

const NavIcon = ({ active = false, children }: NavIconProps) => {
  return (
    <div className="relative w-16 h-16">
      <Pentagon
        className={cn(
          'absolute w-full h-full',
          active ? 'fill-accent-background' : 'fill-zinc-800'
        )}
      />
      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
        {children}
      </div>
    </div>
  )
}

type Props = ComponentPropsWithoutRef<'aside'>

export const Sidebar = ({ className, ...props }: Props) => {
  const { pathname } = useLocation()

  return (
    <aside className={cn('h-full min-w-32', className)} {...props}>
      <div className="fixed flex justify-center items-start w-32 pt-8">
        <LogoSimple className="w-12" />
      </div>

      <div className="fixed flex flex-col justify-around items-center h-full px-8 w-32">
        <nav className="flex flex-col gap-4">
          <Link to="/installation">
            <NavIcon>
              <PlusIcon strokeWidth={3} className="w-5 stroke" />
            </NavIcon>
          </Link>

          <Link to="/">
            <NavIcon active={pathname === '/'}>
              <HomeIcon className="w-6" />
            </NavIcon>
          </Link>

          <Link to="/docs">
            <NavIcon active={pathname === '/docs'}>
              <BookOpenIcon className="w-6" />
            </NavIcon>
          </Link>
        </nav>
      </div>
    </aside>
  )
}

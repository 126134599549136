import {
  ActiveInstallationRefreshMetrics,
  DismissableMachineAlarm,
  MachineSystemData,
  RefreshData,
  getDefaultInstallationRefreshMetrics,
  getDefaultMachineConfiguration,
  machineAlarms
} from '@uv/machine'
import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { calculateRefreshMetrics } from '../installation/refresh-metrics'
import { getMockUpdatedRefreshData } from '../mock/mock'
import { useDataStoreLocalStorage } from './use-data-store-local-storage'
import { initSyncFilesWebSocket } from '@/lib/websocket/sync-files/sync-files-websocket'
import {
  MachineSyncFilesAction,
  handleSendMachineSyncFilesAction
} from '@/lib/websocket/sync-files/handle-send-machine-sync-files-action'
import {
  machineWebSocket,
  initMachineWebSocket
} from '@/lib/websocket/machine-websocket/machine-websocket'
import {
  MachineAction,
  handleSendMachineAction
} from '@/lib/websocket/machine-websocket/handle-send-machine-action'

const WEBSOCKET_URL = import.meta.env.VITE_WEBSOCKET_URL
if (!WEBSOCKET_URL) throw new Error('WEBSOCKET_URL is not defined')

const MOCK_REFRESH_DATA_INTERVAL_MS = 50
let mockRefreshDataIntervalId: NodeJS.Timeout | null = null
const clearMockRefreshDataInterval = () => {
  if (mockRefreshDataIntervalId) {
    clearInterval(mockRefreshDataIntervalId)
    mockRefreshDataIntervalId = null
  }
}

useDataStoreLocalStorage.getState().setActiveInstallationFileMeta
const addActiveInstallationSample = useDataStoreLocalStorage.getState().addActiveInstallationSample

type DataStore = {
  isOnline: boolean
  setIsOnline: (isOnline: boolean) => void

  // Machine
  machineId: string | null
  refreshMetrics: ActiveInstallationRefreshMetrics
  refreshData: RefreshData | null
  setRefreshData: (refreshData: RefreshData) => void
  dismissedAlarms: DismissableMachineAlarm[]
  setDismissedAlarms: (alarms: DismissableMachineAlarm[]) => void
  machineConfiguration: {
    rpm_list: MachineSystemData['RPM_LIST']
    uv_cycle: MachineSystemData['UV_CYCLE']
    overrides: MachineSystemData['OVERRIDES']
  }
  setMachineConfiguration: (machineConfiguration: DataStore['machineConfiguration']) => void

  // Modes
  mockMode: boolean
  setMockMode: (mockMode: boolean) => void
  devMode: boolean
  setDevMode: (devMode: boolean) => void

  // Websocket
  connect: () => void
  disconnect: () => void
  isConnected: boolean
  shouldReconnect: boolean
  setShouldReconnect: (shouldReconnect: boolean) => void
  sendMachineAction: (params: MachineAction) => void

  // Sync Files Websocket
  syncFilesWsConnect: () => void
  sendMachineSyncFilesAction: (params: MachineSyncFilesAction) => void
}

export const useDataStore = create<DataStore>()(
  devtools((set, get) => {
    const store = {
      isOnline: true,
      setIsOnline: isOnline => set({ isOnline }),

      // Machine
      machineId: null,
      refreshMetrics: getDefaultInstallationRefreshMetrics(),
      refreshData: null,
      setRefreshData: refreshData => {
        set({ refreshData })

        // Dismissed Alarms
        const { ALARMS } = refreshData
        const alarms = [
          ...ALARMS.map((alarm, i) => (alarm ? machineAlarms[i] : undefined)).filter(Boolean),
          // WIFI
          // Anything below 75% should be considered a warning
          // Around 50-60% watchdog functionality will be compromised
          ...[refreshData?.WIFI < 75 ? 'WIFI' : undefined]
        ]
        const dismissedAlarms = get().dismissedAlarms
        const newDismissedAlarms = dismissedAlarms.filter(alarm => alarms.includes(alarm))
        set({ dismissedAlarms: newDismissedAlarms })
      },
      dismissedAlarms: [],
      setDismissedAlarms: alarms => set({ dismissedAlarms: alarms }),
      machineConfiguration: getDefaultMachineConfiguration(),
      setMachineConfiguration: machineConfiguration => set({ machineConfiguration }),

      // Modes
      mockMode: false,
      setMockMode: mockMode => {
        if (mockMode) {
          get().setShouldReconnect(false)
          get().disconnect()
          set({ mockMode })
          set({ machineId: 'mock-machine-id' })
          set({ refreshMetrics: getDefaultInstallationRefreshMetrics() })

          // Simulate Refresh Data
          mockRefreshDataIntervalId = setInterval(() => {
            // Sice ws.onclose sets isConnected to false whenever the connection is closed,
            // it overwrites mockMode isConnectetd.
            // Setting it up here ensure we are always connected when in mock mode
            set({ isConnected: true })

            const updatedRefreshData = getMockUpdatedRefreshData(
              get().refreshData,
              MOCK_REFRESH_DATA_INTERVAL_MS
            )
            set({ refreshData: updatedRefreshData })

            // Refresh Metrics
            set(({ refreshMetrics }) => ({
              refreshMetrics: calculateRefreshMetrics(refreshMetrics)
            }))

            // Update Active Installation Samples
            if (updatedRefreshData.RECORDING === true) {
              addActiveInstallationSample({
                timestamp: Date.now(),
                speed: updatedRefreshData.RPM ?? 0,
                pressure: updatedRefreshData.AIR_PRESSURE ?? 0,
                temperature: updatedRefreshData.AIR_TEMP ?? 0
              })
            }
          }, MOCK_REFRESH_DATA_INTERVAL_MS)
        } else {
          set({ isConnected: false })
          set({ mockMode })
          clearMockRefreshDataInterval()
          get().setShouldReconnect(true)
          get().connect()
        }
      },
      devMode: false,
      setDevMode: devMode => set({ devMode }),

      //////////////////////////
      // WEBSOCKET CONNECTION
      //////////////////////////
      isConnected: false,
      shouldReconnect: true,
      setShouldReconnect: shouldReconnect => set({ shouldReconnect }),

      connect: () => {
        if (get().mockMode) return
        initMachineWebSocket()
      },

      disconnect: () => {
        if (get().mockMode) return
        if (!machineWebSocket) return
        machineWebSocket.close()
      },

      sendMachineAction: params => {
        handleSendMachineAction(params)
      },

      //////////////////////////
      // SYNC FILES WEBSOCKET CONNECTION
      //////////////////////////
      syncFilesWsConnect: () => {
        if (get().mockMode) return
        initSyncFilesWebSocket()
      },
      sendMachineSyncFilesAction: params => {
        handleSendMachineSyncFilesAction(params)
      }
    } satisfies DataStore

    // Automatically connect when the store is created
    // `setTimeout` ensures that the connect function is called immediately after the store is initialized.
    // This approach avoids potential issues with calling connect synchronously during store initialization.
    setTimeout(store.connect, 0)
    setTimeout(store.syncFilesWsConnect, 0)

    return store
  })
)

import { PDFViewer } from '@/lib/pdf/pdf-viewer'
import { Button } from '@ui/components/ui/button'
import { ScrollArea } from '@ui/components/ui/scroll-area'
import { ArrowLeftIcon } from 'lucide-react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

const docs = [
  {
    thumbnail: '/pdf/uvxair_manual_en.jpg',
    path: '/pdf/uvxair_manual_en.pdf',
    title: 'UV XAIR Manual EN'
  }
  // { thumbnail: '/pdf/manual_en.jpg', path: '/pdf/manual_en.pdf', title: 'Manual SE' },
  // { thumbnail: '/pdf/manual_en.jpg', path: '/pdf/manual_en.pdf', title: 'Manual ES' },
  // { thumbnail: '/pdf/manual_en.jpg', path: '/pdf/manual_en.pdf', title: 'Manual FR' },
  // { thumbnail: '/pdf/manual_en.jpg', path: '/pdf/manual_en.pdf', title: 'Manual NO' },
  // { thumbnail: '/pdf/manual_en.jpg', path: '/pdf/manual_en.pdf', title: 'Manual DE' }
]
type Doc = (typeof docs)[number]

function DocsPage() {
  const { t } = useTranslation()
  const [activePdf, setActivePdf] = useState<Doc | null>(null)

  return (
    <ScrollArea className="h-full border text-card-foreground shadow-sm bg-zinc-900/90 rounded-2xl select-none">
      <div className="p-8">
        {activePdf ? (
          <>
            <Button
              onClick={() => setActivePdf(null)}
              className="fixed h-auto text-lg py-3 px-6 flex justify-center items-center gap-2 text-foreground bg-zinc-800/70 rounded-2xl hover:bg-zinc-800"
            >
              <ArrowLeftIcon className="w-4" />
              {t('docs.back-button')}
            </Button>

            <PDFViewer filePath={activePdf.path} />
          </>
        ) : (
          <div className="grid grid-cols-5 gap-y-8 gap-x-16 p-8">
            {docs.map((doc, index) => (
              <button
                key={index}
                className="flex flex-col gap-2 items-center text-base"
                onClick={() => setActivePdf(doc)}
              >
                <img src={doc.thumbnail} className="" />
                <span>{doc.title}</span>
              </button>
            ))}
          </div>
        )}
      </div>
    </ScrollArea>
  )
}

export default DocsPage

import { useDataStore } from '@/lib/machine/use-data-store'
import { parseMachineOutMessage } from '../parse-machine-out-message'
import { handleIncomingMessage } from './handle-incoming-message'
import { MachineInMessage } from '@uv/machine'

const WEBSOCKET_URL = import.meta.env.VITE_WEBSOCKET_URL
if (!WEBSOCKET_URL) throw new Error('WEBSOCKET_URL is not defined')

let retryCount = 0
const MAX_IMMEDIATE_RETRIES = 5
const RETRY_DELAY_MS = 1000
// Maybe consider an exponential backoff strategy
// https://en.wikipedia.org/wiki/Exponential_backoff

/////////////////////////////////////////////
// MACHINE WEBSOCKET
/////////////////////////////////////////////

// The tablet can be connected to the machine via wifi or ethernet.
// The tablet runs on Windows and the network changes are cumbersome to detect.

// We use a backup connection system to fast switch between connection sources, avoiding the extra time that will cause the watchdog to fail.

// We have two scenarios:
// - ethernet -> wifi
// Currently there is no good way to detect this change, and the watchdog will most likely fail.
// If the device was initially connected via ethernet when starting the app, the websocket we use the backup connection, avoiding the watchdog failure.

// - wifi -> ethernet
// This change is detected by the netIdent change on the refresh data message.

export let machineWebSocket: WebSocket | null = null
let backupWebSocket: WebSocket | null = null
let newWebSocket: WebSocket | null = null
let lastNetIdent: string | null = null

const onOpen = (e: Event) => {
  retryCount = 0

  ///////////////////////////////////////
  if (e.currentTarget === machineWebSocket) {
    // console.log('primary connected')
  } else if (e.currentTarget === newWebSocket) {
    // console.log('new connected')
    backupWebSocket = machineWebSocket
    machineWebSocket = newWebSocket
  }
  ///////////////////////////////////////
  useDataStore.setState({ isConnected: true })

  if (!machineWebSocket) return

  machineWebSocket.send(
    JSON.stringify({ in: 'hello' } satisfies Extract<MachineInMessage, { in: 'hello' }>)
  )
}

const onMessage = (e: MessageEvent) => {
  if (e.currentTarget !== machineWebSocket) return

  const message = parseMachineOutMessage(e.data)
  if (!message) return

  ///////////////////////////////////
  if (message.out === 'refresh') {
    if (lastNetIdent && message.NETIDENT !== lastNetIdent) {
      // console.log('New connecting...')
      if (newWebSocket) newWebSocket.close()
      newWebSocket = connect()
    }

    lastNetIdent = message.NETIDENT
  }
  ///////////////////////////////////

  handleIncomingMessage(machineWebSocket, message)
}

const onClose = (e: Event) => {
  ///////////////////////////////////////
  if (e.currentTarget === machineWebSocket) {
    if (backupWebSocket && backupWebSocket.readyState !== WebSocket.CLOSED) {
      // console.log('primary closed - switching to backup')
      machineWebSocket = backupWebSocket
    } else {
      // console.log('primary closed - no backup :(')

      // You have certain amount of immediate retries before we start delaying the retries
      if (retryCount < MAX_IMMEDIATE_RETRIES) {
        retryCount++
        initMachineWebSocket() // Immediate retry
      } else {
        setTimeout(() => {
          retryCount++
          initMachineWebSocket()
        }, RETRY_DELAY_MS)
      }
    }
  } else if (e.currentTarget === backupWebSocket) {
    // console.log('Backup closed')
  } else if (e.currentTarget === newWebSocket) {
    // console.log('New closed')
  }
  ///////////////////////////////////////

  useDataStore.setState({ isConnected: false })
}

const onError = () => {
  // if (e instanceof ErrorEvent && e.currentTarget instanceof WebSocket) {
  //   if (
  //     e.currentTarget.readyState === WebSocket.CLOSING ||
  //     e.currentTarget.readyState === WebSocket.CLOSED
  //   ) {
  //     if (e.error instanceof Error) {
  //       // Check for certificate-related error messages
  //
  //       console.log('here')
  //       if (
  //         e.error.message.includes('certificate') ||
  //         e.error.message.includes('SSL') ||
  //         e.error.message.includes('TLS')
  //       ) {
  //         console.error('WebSocket connection failed due to certificate issue:', e.error.message)
  //         // TODO: redirect to certificate page
  //         console.log('Implement redirect to certificate page')
  //       }
  //     }
  //   }
  // }
}

const connect = () => {
  const mockMode = useDataStore.getState().mockMode
  if (mockMode) return null

  const connection = new WebSocket(WEBSOCKET_URL)
  connection.addEventListener('open', onOpen)
  connection.addEventListener('message', onMessage)
  connection.addEventListener('close', onClose)
  connection.addEventListener('error', onError)
  return connection
}

export const initMachineWebSocket = () => {
  console.log('Primary connecting...')
  if (machineWebSocket) machineWebSocket.close()
  machineWebSocket = connect()
}

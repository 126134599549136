import { useInterval } from 'usehooks-ts'
import { useCloudSyncState } from './use-cloud-sync-state'

export const useCloudSyncStateInterval = (intervalMs = 10_000) => {
  const cloudSyncState = useCloudSyncState()

  useInterval(() => {
    cloudSyncState()

  }, intervalMs)
}

import { Button } from '@ui/components/ui/button'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useLongPress } from '@uidotdev/usehooks'
import { cn } from '@ui/utils'

type Props = React.ComponentProps<typeof Button> & {
  onAction: () => void
  holdActionRepeatInterval?: number
  holdActivationDelay?: number
}

export const ButtonPressAndHold = ({
  onAction,
  holdActionRepeatInterval = 250,
  holdActivationDelay = 0, // How much time has to pass so the long press is activated
  className,
  ...props
}: Props) => {
  const [actionTimer, setActionTimer] = useState<number | NodeJS.Timeout | null>(null)

  // Ref to hold the current version of onAction
  const actionRef = useRef(onAction)

  // Update actionRef on each render, so it always has the latest version of onAction
  useEffect(() => {
    actionRef.current = onAction
  }, [onAction])

  // Cleanup the interval when the component unmounts
  useEffect(() => {
    return () => {
      if (actionTimer !== null) {
        clearInterval(actionTimer)
      }
    }
  }, [actionTimer])

  // Stable callback that calls the latest onAction
  const handleAction = useCallback(() => {
    if (actionRef.current) {
      actionRef.current()
    }
  }, [])

  const handleLongPress = useCallback(() => {
    // Clear any existing timer
    if (actionTimer !== null) {
      clearInterval(actionTimer)
    }
    // Start repeating the action at the specified interval
    const timerId = setInterval(handleAction, holdActionRepeatInterval)
    setActionTimer(timerId)
  }, [handleAction, holdActionRepeatInterval, actionTimer])

  const handlePressEnd = useCallback(() => {
    // Stop the repeating action when the press ends
    if (actionTimer !== null) {
      clearInterval(actionTimer)
      setActionTimer(null)
    }
  }, [actionTimer])

  const longPressProps = useLongPress(handleLongPress, {
    onFinish: handlePressEnd,
    onCancel: handlePressEnd,
    threshold: holdActivationDelay
  })
  return <Button onClick={onAction} className={cn('', className)} {...props} {...longPressProps} />
}

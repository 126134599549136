import { dbGetInstallationByFileName, dbUpdateInstallationFile } from '@/db/actions'
import { useDataStore } from '../../use-data-store'
import { MachineOutMessage, getInstallationIdFromFileName } from '@uv/machine'
import { processFileChunk } from '../process-file-chunk'

export const handleFileReadInstallation = async ({
  file,
  fileContents,
  offset
}: {
  file: Extract<MachineOutMessage, { out: 'file_read' }>['file']
  fileContents: Extract<MachineOutMessage, { out: 'file_read' }>['payload']
  offset: Extract<MachineOutMessage, { out: 'file_read' }>['offset']
}) => {
  if (!file.endsWith('.json') || file === 'active.json') return
  const installationId = getInstallationIdFromFileName(file)
  if (!installationId) return
  const sendMachineSyncFilesAction = useDataStore.getState().sendMachineSyncFilesAction

  let installationInLocalDb = await dbGetInstallationByFileName(file)

  // -------------------------------------------------
  // 1. File should be deleted from machine send `file_remove`
  // -------------------------------------------------
  if (installationInLocalDb?.shouldDelete === 1) {
    return sendMachineSyncFilesAction({
      in: 'file_remove',
      file
    })
  }

  // -------------------------------------------------
  // 2. Process Chunk
  // -------------------------------------------------
  const chunkAction = processFileChunk(file, fileContents, offset)
  const isLastChunk = chunkAction.action === 'FILE_COMPLETED'

  // -------------------------------------------------
  // 3. If installation in local db, has no file, and is lastChunk save the file
  // -------------------------------------------------
  if (installationInLocalDb && installationInLocalDb.hasFile === 0 && isLastChunk) {
    await dbUpdateInstallationFile({ id: installationId, file: chunkAction.file })
    installationInLocalDb = await dbGetInstallationByFileName(file)
  }

  // -------------------------------------------------
  // 4. Sync with Cloud
  // We should not sync with cloud because since we are sending many chunks this will flood the server
  // -------------------------------------------------
  // const hasInternetConnection = await isOnline()
  //
  // if (hasInternetConnection) {
  //   const filesWithSyncActions = await cloudSyncInstallations([
  //     {
  //       fileName: file,
  //       fileMeta: installationInLocalDb?.meta,
  //       hasFile: Boolean(installationInLocalDb)
  //     }
  //   ])
  //
  //   if (filesWithSyncActions) {
  //     const syncAction = filesWithSyncActions.at(0)
  //
  //     // All the actions are the same, except for the file_read, which accounts for chunks
  //     if (syncAction?.action === 'FILE_READ') {
  //       return sendMachineSyncFilesAction({
  //         in: 'file_read',
  //         file,
  //         offset: hasMoreChunks ? chunkAction.offset : 0,
  //         count: FILE_READ_CHUNK_SIZE
  //       })
  //     }
  //
  //     await handleVideoSyncActions({
  //       filesWithSyncActions
  //     })
  //
  //     return
  //   }
  // }

  // -------------------------------------------------
  // 5. We don't have the installation stored (nor its meta)
  // Call `file_meta` to get the duration first
  // -------------------------------------------------
  if (!installationInLocalDb || !installationInLocalDb.meta) {
    return sendMachineSyncFilesAction({
      in: 'file_meta',
      file
    })
  }

  // -------------------------------------------------
  // 6. Don't do anything, since we might be requesting the chunk in parallel
  // Eventually the sync of this file will kick in again
  // -------------------------------------------------
  if (chunkAction.action === 'INVALID_CHUNK') {
    return
  }

  // -------------------------------------------------
  // 7. Process Next Chunk
  // -------------------------------------------------
  if (chunkAction.action === 'REQUEST_NEXT_CHUNK') {
    return sendMachineSyncFilesAction({
      in: 'file_read',
      file,
      offset: chunkAction.offset,
      count: chunkAction.count
    })
  }
}

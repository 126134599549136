import { dbGetInstallation, dbMarkInstallationForDeletion } from '@/db/actions'
import { cloudSyncUploadedInstallationFile } from '@/lib/cloud/cloud-sync-uploaded-installation-file'
import { s3UploadInstallationFile } from '@/lib/s3/upload-installation-file'
import { CloudSyncFilesOutput, getInstallationIdFromFileName } from '@uv/machine'
import { useDataStore } from '../../use-data-store'

type InstallationToUpload = Extract<CloudSyncFilesOutput[0], { action: 'UPLOAD' }>

export const handleUploadAndDeleteInstallationFile = async ({
  installationToUpload
}: {
  installationToUpload: InstallationToUpload
}) => {
  const sendMachineSyncFilesAction = useDataStore.getState().sendMachineSyncFilesAction

  const installationId = getInstallationIdFromFileName(installationToUpload.fileName)
  if (!installationId) return

  const installation = await dbGetInstallation(installationId)
  if (!installation) return

  if (!installation.file) {
    console.error(`Installation file for ${installationToUpload} not found in IndexedDB`)
    return
  }

  const url = await s3UploadInstallationFile({
    presignedUrl: installationToUpload.presignedUrl,
    file: installation.file
  })
  if (!url) return

  await dbMarkInstallationForDeletion(installationId)

  const hasUpdatedCloud = await cloudSyncUploadedInstallationFile({
    id: installationId,
    fileUrl: url
  })

  if (hasUpdatedCloud) {
    return sendMachineSyncFilesAction({
      in: 'file_remove',
      file: installation.fileName
    })
  }
}

import { RouterProvider, createBrowserRouter } from 'react-router-dom'
import { createRoot } from 'react-dom/client'
import '@uv/ui/globals.css'
import RootLayout from './layouts/root-layout'
import HomePage from './pages/Home'
import HomeLayout from './layouts/home-layout'
import { InstallationPage } from './pages/Installation'
import WebsocketsTestPage from './pages/WebsocketsTest'
import { initializeI18next } from './lib/i18n/config'
import { useDataStoreLocalStorage } from './lib/machine/use-data-store-local-storage'
import SyncPage from './pages/Sync'
import DocsPage from './pages/Docs'
import './lib/pdf/worker'

// Initialize the store to ensure it is ready before i18next initialization
useDataStoreLocalStorage.getState().locale
initializeI18next()

const App = () => {
  const router = createBrowserRouter([
    {
      element: <RootLayout />,
      children: [
        {
          path: '/installation',
          element: <InstallationPage />
        },
        {
          path: '/ws',
          element: <WebsocketsTestPage />
        },
        {
          path: '/sync',
          element: <SyncPage />
        },
        {
          element: <HomeLayout />,
          children: [
            { path: '/', element: <HomePage /> },
            { path: 'docs', element: <DocsPage /> }
          ]
        }
      ]
    }
  ])
  return <RouterProvider router={router} />
}

createRoot(document.getElementById('app')!).render(<App />)

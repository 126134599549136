import { HiddenSystemOverrideButton } from './settings/hidden-system-override-button'
import { useState } from 'react'
import { SystemOverrideDialog } from './settings/service-mode-dialog'
import { SettingsUnits } from '@/components/settings/settings-units'
import { SettingsCustomWarnings } from '@/components/settings/settings-custom-warnings'
import { useTranslation } from 'react-i18next'
import { SettingsLanguage } from '@/components/settings/settings-language'
import { SettingsPower } from '@/components/settings/settings-power'
import { cn } from '@ui/utils'
import { Button } from '@ui/components/ui/button'
import {
  DatabaseIcon,
  LanguagesIcon,
  PowerIcon,
  RulerIcon,
  ServerIcon,
  TriangleAlertIcon,
  UserCircleIcon
} from 'lucide-react'
import { SettingsAccount } from '@/components/settings/settings-account'
import { useLocation } from 'react-router'
import { useDataStore } from '@/lib/machine/use-data-store'
import { SettingsData } from '@/components/settings/settings-data'
import { SettingsMachine } from '@/components/settings/settings-machine'

type Props = {
  closeSettings: () => void
}

const sections = ['power', 'units', 'language', 'warnings', 'data', 'machine', 'account'] as const
type Section = (typeof sections)[number]

const RenderSection = ({ section }: { section: Section }) => {
  switch (section) {
    case 'power':
      return <SettingsPower />
    case 'units':
      return <SettingsUnits />
    case 'language':
      return <SettingsLanguage />
    case 'warnings':
      return <SettingsCustomWarnings />
    case 'data':
      return <SettingsData />
    case 'machine':
      return <SettingsMachine />
    case 'account':
      return <SettingsAccount />
  }
}

const RenderSectionIcon = ({ section }: { section: Section }) => {
  switch (section) {
    case 'power':
      return <PowerIcon className="w-5 h-5" />
    case 'units':
      return <RulerIcon className="w-5 h-5" />
    case 'language':
      return <LanguagesIcon className="w-5 h-5" />
    case 'warnings':
      return <TriangleAlertIcon className="w-5 h-5" />
    case 'data':
      return <DatabaseIcon className="w-5 h-5" />
    case 'machine':
      return <ServerIcon />
    case 'account':
      return <UserCircleIcon className="w-5 h-5" />
  }
}

export const Settings = ({ closeSettings }: Props) => {
  const { t } = useTranslation()
  const [showSystemOverrideConfirmation, setShowSystemOverrideConfirmation] = useState(false)
  const [activeSection, setActiveSection] = useState<Section>(sections[0])
  const { pathname } = useLocation()
  const isInstallation = pathname.includes('/installation')
  const refreshData = useDataStore(s => s.refreshData)
  const isServiceModeAllowed = isInstallation && refreshData?.RECORDING

  return (
    <section className="h-[80vh] w-[80%] mx-auto">
      <HiddenSystemOverrideButton
        onLongPress={() => {
          if (isServiceModeAllowed) setShowSystemOverrideConfirmation(true)
        }}
        className="mt-8 mb-8 text-4xl font-medium"
      >
        {t('settings.title')}
      </HiddenSystemOverrideButton>
      <SystemOverrideDialog
        showDialog={showSystemOverrideConfirmation}
        closeDialog={() => setShowSystemOverrideConfirmation(false)}
        closeSettings={closeSettings}
      />

      <div className="flex gap-16">
        <ul className="flex flex-col gap-2 w-48">
          {sections.map(section => (
            <li key={section}>
              <Button
                variant="ghost"
                size="lg"
                onClick={() => setActiveSection(section)}
                className={cn(
                  'flex justify-start items-center gap-2.5 w-full text-lg -ml-3 px-3 hover:bg-zinc-800 rounded-xl',
                  section === activeSection ? 'bg-zinc-800' : 'text-muted-foreground'
                )}
              >
                <RenderSectionIcon section={section} />
                <span className="overflow-hidden whitespace-nowrap overflow-ellipsis max-w-[calc(100%-2.5rem)]">
                  {t(`settings.${section}-title`)}
                </span>
              </Button>
            </li>
          ))}
        </ul>

        <section className="flex-1">
          <RenderSection section={activeSection} key={activeSection} />
        </section>
      </div>
    </section>
  )
}

import { Outlet } from 'react-router-dom'
import ReloadPrompt from '../components/reloadPrompt/ReloadPrompt'
import { Toaster } from '@ui/components/ui/sonner'
import { DevBar } from '@/components/dev/dev-bar'
import { useCloudSyncStateInterval } from '@/lib/cloud/use-cloud-sync-state-interval'
import { useMachineSyncFilesInterval } from '@/lib/machine/sync/use-machine-sync-files-interval'
import { BackgroundGradient } from '@/components/ui/background'
import { useCloudSyncInstallationsInterval } from '@/lib/cloud/use-cloud-sync-installations-interval'
import { useIsOnlineInterval } from '@/lib/utils/use-is-online-interval'
import { useCloudSyncVideosInterval } from '@/lib/cloud/use-cloud-sync-videos-interval'

function Layout() {
  useIsOnlineInterval()
  useCloudSyncStateInterval(10_000)
  useMachineSyncFilesInterval(60_000)
  useCloudSyncInstallationsInterval(30_000)
  useCloudSyncVideosInterval(30_000)

  return (
    <>
      <BackgroundGradient />
      <main className="min-h-screen h-screen min-w-screen flex">
        <Outlet />

        <DevBar />

        <ReloadPrompt />
      </main>
      <Toaster />
    </>
  )
}

export default Layout

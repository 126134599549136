import { useLiveQuery } from 'dexie-react-hooks'
import { db } from '../../db/db'
import { Card, CardContent } from '@ui/components/ui/card'
import { DownloadVideoFileButton } from './download-video-file'
import { DownloadInstallationFileButton } from './download-installation-file'
import { useDataStore } from '@/lib/machine/use-data-store'
import { Button } from '@ui/components/ui/button'

const WEBSOCKET_URL = import.meta.env.VITE_WEBSOCKET_URL
if (!WEBSOCKET_URL) throw new Error('WEBSOCKET_URL  is not defined')

export const Installations = () => {
  const sendMachineSyncFilesAction = useDataStore(s => s.sendMachineSyncFilesAction)

  const installationsInDb = useLiveQuery(async () => {
    return await db.installation.toArray()
  })

  const videosInDb = useLiveQuery(async () => {
    return await db.video.toArray()
  })

  const installationsWithoutDuplicates = installationsInDb
    ? Array.from(
        new Map(
          installationsInDb.map(item => [item.machineId + item.installationTimestamp, item])
        ).values()
      )
    : []

  const getInstallationVideos = (id: string) =>
    videosInDb?.filter(v => v.installationId === id) ?? []

  return (
    <section>
      <ul className="flex flex-col gap-6">
        {installationsWithoutDuplicates?.map(installation => {
          const { id, fileName, machineId, shouldDelete } = installation
          const videos = getInstallationVideos(id)
          return (
            <li key={machineId + fileName} className="mb-2">
              <Card key={machineId + fileName} className="bg-zinc-800/40">
                <CardContent className="flex justify-between items-center gap-8">
                  <div className="flex flex-col w-1/3 mr-auto">
                    <span className="text-sm text-muted-foreground">Machine ID</span>
                    <span className="text-lg">{machineId}</span>
                  </div>
                  <div className="flex flex-col">
                    <span className="text-sm text-muted-foreground">File Name</span>
                    <span className="text-lg">{fileName}</span>
                  </div>

                  <div className="flex flex-col">
                    <span className="text-sm text-muted-foreground">Videos</span>
                    <span className="text-lg">{videos.length}</span>
                  </div>

                  <div className="flex flex-col">
                    <span className="text-sm text-muted-foreground">Should Delete</span>
                    <span className="text-lg">{Boolean(shouldDelete)}</span>
                  </div>
                </CardContent>

                {installation.hasFile && <DownloadInstallationFileButton installationId={id} />}
                {installation.hasFile && (
                  <Button
                    onClick={() => {
                      sendMachineSyncFilesAction({
                        in: 'file_remove',
                        file: fileName
                      })
                    }}
                  >
                    Delete File
                  </Button>
                )}

                {videos &&
                  videos.length > 0 &&
                  videos.map(video => {
                    return (
                      <DownloadVideoFileButton fileName={video.fileName} key={video.fileName} />
                    )
                  })}

                {/* <Button */}
                {/*   size="xs" */}
                {/*   variant="outline" */}
                {/*   onClick={() => { */}
                {/*     if (!machineId) return */}
                {/*     sendMachineAction({ */}
                {/*       id: machineId, */}
                {/*       in: 'file_remove', */}
                {/*       file: fileName */}
                {/*     }) */}
                {/*   }} */}
                {/* > */}
                {/*   Delete */}
                {/* </Button> */}
              </Card>
            </li>
          )
        })}
      </ul>
    </section>
  )
}
